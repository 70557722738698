import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'

import RoomListItem from './RoomListLitem'
import { Tabs, Tab, Paper, Typography } from '@material-ui/core'

class RoomsList extends Component {
  state = {
    tab: 0,
  }
  toggleActiveRoom = (room, key = 'rooms') => {
    const confirm = window.confirm(
      !room.active
        ? 'Are you sure you want to activate this room? Clients will be able to schedule meetings.'
        : 'Are you sure you want to de-activate this room? Clients will no longer see and be able to book rooms. Disabling this room will NOT delete existing bookings.'
    )

    if (confirm) {
      this.props.firestore
        .collection('locations')
        .doc(this.props.match.params.id)
        .collection(key)
        .doc(room.id)
        .update({
          active: !room.active,
        })
        .then(() => {
          this.props.enqueueSnackbar(`${room.name} is now active`, {
            variant: 'success',
          })
        })
        .catch((err) => {
          this.props.enqueueSnackbar(
            `There was an issue enabling ${room.name}`,
            { variant: 'error' }
          )
        })
    }
  }

  render() {
    if (!isLoaded(this.props.rooms))
      return (
        <div className="splash-loader">
          <CircularProgress />
        </div>
      )
    return (
      <div className="rooms-list">
        <Paper style={{ marginBottom: 30, overflow: 'hidden' }}>
          <Tabs
            indicatorColor={'primary'}
            value={this.state.tab}
            onChange={(e, val) =>
              this.setState({
                tab: val,
              })
            }
          >
            <Tab label="Hourly Rooms" value={0} />
            <Tab label="Private Offices" value={1} />
          </Tabs>
        </Paper>
        {this.state.tab === 0 && (
          <Grid container spacing={16}>
            {this.props.rooms.map((room) => (
              <Grid item xs={12} sm={6} md={6} lg={6} key={room.id}>
                <RoomListItem
                  toggleActiveRoom={this.toggleActiveRoom}
                  toggleUpdateRoom={this.props.toggleUpdateRoom}
                  room={room}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {this.state.tab === 1 && (
          <Grid container spacing={16}>
            {this.props.privateOffices ? (
              this.props.privateOffices.map((room) => (
                <Grid item xs={12} sm={6} md={6} lg={6} key={room.id}>
                  <RoomListItem
                    toggleActiveRoom={(room) =>
                      this.toggleActiveRoom(room, 'privateOffices')
                    }
                    toggleUpdateRoom={this.props.toggleUpdateRoom}
                    room={room}
                    privateOffice={true}
                  />
                </Grid>
              ))
            ) : (
              <Typography>There are currently no day offices</Typography>
            )}
          </Grid>
        )}
      </div>
    )
  }
}

export default compose(
  withRouter,
  withSnackbar,
  firestoreConnect((props) => {
    return [
      {
        storeAs: 'rooms',
        collection: 'locations',
        doc: props.match.params.id,
        subcollections: [{ collection: 'rooms' }],
      },
      {
        storeAs: 'privateOffices',
        collection: 'locations',
        doc: props.match.params.id,
        subcollections: [{ collection: 'privateOffices' }],
      },
    ]
  }),
  connect((state) => ({
    rooms: state.firestore.ordered.rooms,
    privateOffices: state.firestore.ordered.privateOffices,
  }))
)(RoomsList)
