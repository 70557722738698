import React from 'react'
import debounce from 'lodash/debounce'
import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  TextField,
} from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'

class CategoryItem extends React.Component {
  state = { updating: false }
  onWeightChange = debounce(async (order) => {
    this.setState({ updating: true })
    try {
      await this.props.firestore
        .collection('honorMarket')
        .doc(this.props.category.id)
        .update({
          order: Number(order),
        })
    } catch (error) {
      console.log(error)
    }
    this.setState({
      updating: false,
    })
    let category = { ...this.props.category }
    category.order = Number(order)
  }, 1000)
  onNameChange = debounce(async (title) => {
    this.setState({ updating: true })
    try {
      await this.props.firestore
        .collection('honorMarket')
        .doc(this.props.category.id)
        .update({
          title,
        })
    } catch (error) {
      console.log(error)
    }
    this.setState({
      updating: false,
    })
    let category = { ...this.props.category }
    category.title = title
  }, 1000)
  onDelete = async () => {
    const confirm = window.confirm(
      'Are you sure you want to delete this category?'
    )
    if (confirm) {
      try {
        await this.props.firestore
          .collection('honorMarket')
          .doc(this.props.category.id)
          .delete()
      } catch (error) {
        console.log(error)
      }
    }
  }
  render() {
    return (
      <ListItem>
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <TextField
              disabled={this.state.updating}
              defaultValue={this.props.category.title}
              onChange={(e) => this.onNameChange(e.target.value)}
              variant="standard"
              margin={'none'}
            />
          }
        />
        <ListItemSecondaryAction>
          {this.state.updating && (
            <div
              style={{
                display: 'inline-block',
                position: 'relative',
                top: 4,
                right: 10,
              }}
            >
              <CircularProgress size={16} />
            </div>
          )}
          <TextField
            variant="standard"
            margin={'none'}
            defaultValue={this.props.category.order}
            onChange={(e) => this.onWeightChange(e.target.value)}
            type="number"
            disabled={this.state.updating}
            min={0}
            max={this.props.max - 1}
            style={{ marginRight: 10, width: 40 }}
          />
          <Button
            variant="outlined"
            disabled={this.state.updating}
            size="small"
            color="secondary"
            style={{ marginRight: 10 }}
            onClick={this.onDelete}
          >
            Delete
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}

export default CategoryItem
