import React, { Component } from 'react'
import algoliasearch from 'algoliasearch/lite'
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  ListItemAvatar,
  Avatar
} from '@material-ui/core'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
const searchClient = algoliasearch(
  'AA2IDOZJC5',
  'e7242b645e57c9f0cf094cb8e4e43311'
)
const index = searchClient.initIndex('Clients Page')

class UserPicker extends Component {
  state = {
    query: '',
    results: [],
    focused: false,
    querying: false,
    noResults: false
  }
  onBlur = () => {
    if (this.props.onBlur !== undefined) this.props.onBlur()
    this.setState({ focused: true })
  }
  onFocus = () => {
    if (this.props.onFocus !== undefined) this.props.onFocus()
    this.setState({ focused: false })
  }
  updateValue = e => {
    this.setState(
      {
        query: e.target.value,
        querying: e.target.value.length > 1 ? true : false,
        noResults: false
      },
      () => {
        if (this.state.querying) {
          this.getResults()
        } else if (this.state.query.length === 0) {
          this.setState({
            results: []
          })
        }
      }
    )
  }
  getResults = _.debounce(() => {
    console.log('querying values')
    index
      .search({
        query: this.state.query,
        filters: this.props.customFilter
          ? this.props.customFilter
          : this.props.dontExcludeByLocation
          ? 'type:user'
          : `default_location:${this.props.location} AND type:user`,
        hitsPerPage: this.props.hitsPerPage || 10
      })
      .then(res => {
        console.log(res)
        this.setState({
          noResults: res.hits.length ? false : true,
          results: res.hits
        })
      })
    this.setState({
      querying: false
    })
  }, 250)
  selectItem = item => {
    this.props.onSelect(item)
  }
  render() {
    const SearchBar = this.props.searchBarComponent
      ? this.props.searchBarComponent
      : TextField
    const SearchBarWrapper = this.props.searchBarWrapper
      ? this.props.searchBarWrapper
      : null
    const ResultsWrapper = this.props.resultsWrapper
      ? this.props.resultsWrapper
      : null
    return (
      <div style={{ position: 'relative' }}>
        {SearchBarWrapper ? (
          <SearchBarWrapper style={this.props.searchBarWrapperStyle}>
            <SearchBar
              autoComplete="off"
              autoFocus={this.props.autoFocus}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              fullWidth
              variant="outlined"
              label={this.props.label ? this.props.label : 'Search for User'}
              placeholder={
                this.props.placeholder
                  ? this.props.placeholder
                  : 'Search for User'
              }
              value={this.state.query}
              onChange={this.updateValue}
            />
          </SearchBarWrapper>
        ) : (
          <div style={this.props.searchBarWrapperStyle}>
            <SearchBar
              autoFocus
              autoComplete="off"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              fullWidth
              variant="outlined"
              label={this.props.label ? this.props.label : 'Search for User'}
              placeholder={
                this.props.placeholder
                  ? this.props.placeholder
                  : 'Search for User'
              }
              value={this.state.query}
              onChange={this.updateValue}
            />
          </div>
        )}
        {this.state.querying ? (
          <div
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              top: 18,
              right: 18,
              ...this.props.searchIndicatorStyle
            }}
          >
            <CircularProgress size={21} thickness={6} />
          </div>
        ) : null}
        {!this.props.hideResults ? (
          this.state.results.length || this.state.noResults ? (
            ResultsWrapper ? (
              <ResultsWrapper style={this.props.resultsWrapperStyle}>
                <List style={{ maxHeight: this.props.resultsMaxHeight || 600 }}>
                  {this.state.noResults ? (
                    <ListItem>
                      <ListItemText
                        primary="No Results"
                        secondary={`No results for '${this.state.query}'`}
                      />
                    </ListItem>
                  ) : (
                    this.state.results.map(res =>
                      this.props.resultItem ? (
                        this.props.resultItem(res)
                      ) : (
                        <ListItem
                          button
                          onClick={() => this.selectItem(res)}
                          key={res.objectID}
                        >
                          <ListItemAvatar>
                            <Avatar src={res.image} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={res.name}
                            secondary={res.email_address}
                          />
                        </ListItem>
                      )
                    )
                  )}
                </List>
              </ResultsWrapper>
            ) : (
              <List style={{ maxHeight: this.props.resultsMaxHeight || 600 }}>
                {this.state.noResults ? (
                  <ListItem>
                    <ListItemText
                      primary="No Results"
                      secondary={`No results for '${this.state.query}'`}
                    />
                  </ListItem>
                ) : (
                  this.state.results.map(res => (
                    <ListItem
                      button
                      onClick={() => this.selectItem(res)}
                      key={res.objectID}
                    >
                      <ListItemAvatar>
                        <Avatar src={res.image} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={res.name}
                        secondary={res.email_address}
                      />
                    </ListItem>
                  ))
                )}
              </List>
            )
          ) : null
        ) : null}
      </div>
    )
  }
}

export default compose(connect())(UserPicker)
