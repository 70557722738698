import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import {
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Avatar,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  withMobileDialog,
  Dialog,
  Menu,
  MenuItem,
  Button
} from '@material-ui/core'

import EditCompany from './EditCompany'

class CompanyDetail extends Component {
  state = { editCompany: false, anchorEl: null }
  constructor(props) {
    super(props)
    console.log(props)
  }
  toggleEditCompany = () => {
    this.setState({
      editCompany: !this.state.editCompany
    })
  }
  toggleUserEnabled = user => {
    console.log(user, this.props.selectedUser.id)
    const confirm = window.confirm(
      user.show_in_directory
        ? 'This company will be removed from the directory. Do you wish to continue?'
        : 'This company will be added to the directory. Do you wish to continue?'
    )

    if (confirm) {
      this.props.firestore
        .collection('profiles')
        .doc(this.props.selectedUser.id)
        .update({
          show_in_directory: !user.show_in_directory,
          profile_updated: new Date()
        })
        .then(() => {
          this.props.enqueueSnackbar(
            `The status of ${user.company_name} has been updated.`,
            {
              variant: 'info'
            }
          )
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  toggleUserDirectory = user => {
    const confirm = window.confirm(
      user.show_in_directory
        ? 'This will remove the user from the directory. Do you wish to continue?'
        : 'This will add the user to the directory. Do you wish to continue?'
    )

    if (confirm) {
      this.props.firestore
        .collection('profiles')
        .doc(this.props.selectedUser.id)
        .update({
          show_in_directory: !user.show_in_directory,
          profile_updated: new Date()
        })
        .then(() => {
          this.props.enqueueSnackbar(
            `${user.first_name} ${user.last_name} has been ${
              !user.show_in_directory ? 'added to' : 'removed from'
            } the directory.`,
            {
              variant: 'info'
            }
          )
        })
    }
  }
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  render() {
    const { fullScreen } = this.props
    if (this.props.selectedUser === null) {
      return <CircularProgress />
    }
    if (this.props.selectedUser === false) {
      return <div>there was an issue loading data</div>
    }
    const company = this.props.selectedUser
    if (company === undefined) {
      return <div>there was an issue loading this profile</div>
    }
    return (
      <div className="user-detail">
        <Grid container alignItems="center" direction="row-reverse">
          <Grid
            item
            xs={12}
            sm={5}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            {this.props.profile.role === 'admin' ||
            (this.props.profile.locations &&
              this.props.profile.locations[company.default_location.value] ===
                true) ? (
              <React.Fragment>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={company.show_in_directory}
                        onChange={() => this.toggleUserEnabled(company)}
                      />
                    }
                    label="Show in Directory"
                    labelPlacement="end"
                  />
                </FormGroup>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={this.toggleEditCompany}
                >
                  Edit Company
                </Button>
                {/* <IconButton
                  aria-owns={this.state.anchorEl ? 'edit-menu' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  <Icon>more_vert</Icon>
                </IconButton>
                <Menu
                  id="edit-menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                 
                </Menu> */}
              </React.Fragment>
            ) : null}
          </Grid>
          <Grid
            xs={12}
            sm={7}
            item
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Avatar
              src={company.image}
              style={{ width: 42, height: 42, marginRight: 16, marginLeft: 8 }}
            />
            <Typography variant="h5">{company.company_name}</Typography>
          </Grid>
          <Grid style={{ marginTop: 8 }} item xs={12}>
            <List>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <Icon>mail</Icon>
                </ListItemIcon>
                <ListItemText
                  secondary="Primary Contact"
                  primary={
                    company.primary_contact
                      ? company.primary_contact.email_address
                      : '--'
                  }
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <Icon>home</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={company.office_number ? company.office_number : '--'}
                  secondary="Primary Office"
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <Icon>domain</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={company.floor_number ? company.floor_number : '--'}
                  secondary="Floor Number"
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <Icon>account_circle</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={company.website ? company.website : '--'}
                  secondary="Website"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  primary={company.twitter ? company.twitter : '--'}
                  secondary="Twitter"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  primary={company.linkedin ? company.linkedin : '--'}
                  secondary="LinkedIn"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  primary={company.bio ? company.bio : '--'}
                  secondary="Bio"
                />
              </ListItem>
              <Divider />
              <ListItem style={{ marginTop: 16 }}>
                <ListItemIcon>
                  <Icon>security</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6">Additional Details</Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    company.metadata ? company.metadata.monthly_credits : '0'
                  }
                  secondary="Monthly Credits"
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Dialog
          maxWidth="sm"
          fullScreen={fullScreen}
          fullWidth
          className="add-user-modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.editCompany}
        >
          <EditCompany
            handleClose={this.toggleEditCompany}
            company={company}
            locationId={this.props.match.params.id}
            profileId={this.props.selectedUser.id}
            label="Edit Client"
          />
        </Dialog>
      </div>
    )
  }
}

export default compose(
  firestoreConnect(),
  connect((state, props) => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile
    }
  }),
  withRouter,
  withSnackbar,
  withMobileDialog()
)(CompanyDetail)
