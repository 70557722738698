import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Dialog, Hidden, AppBar, Toolbar, Icon, Button } from '@material-ui/core'
import BottomScrollListener from 'react-bottom-scroll-listener'
import _ from 'lodash'

const styles = theme => ({
  appBar: {
    marginLeft: 220,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${220}px)`
    }
  },
  panelHeight: {
    height: `calc(100vh - 56px)`,
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px)`
    }
  }
})
class PanelListDetail extends Component {
  hitBottom = () => {
    console.log('hit bottom')
    if (this.props.onBottom !== undefined) {
      this.props.onBottom()

    }
  }

  onClickBack = () => {
    if (this.props.onClickBack !== undefined) {
      this.props.onClickBack()
    }
  }

  debounce = _.debounce(() => this.hitBottom(), 250)

  render() {
    const { classes } = this.props
    return (
      <div className={`panel-list-detail ${classes.panelHeight}`}>
        <BottomScrollListener debounce={250} onBottom={this.hitBottom}>
          {scrollRef => (
            <div ref={scrollRef} id="panel-list-detail-list" className="list">
              {this.props.list}
              {this.props.loadMoreButton && (
                <div style={{ padding: 24 }}>
                  <Button fullWidth color="primary" size="large" onClick={this.debounce}>
                    Load More
                  </Button>
                </div>
              )}
            </div>
          )}
        </BottomScrollListener>
        <Hidden smDown>
          <div className="detail">{this.props.detail}</div>
        </Hidden>
        <Hidden mdUp>
          <Dialog fullWidth fullScreen open={this.props.open}>
            <AppBar color="primary" position="fixed">
              <Toolbar>
                <Button color="inherit" size="small" onClick={this.onClickBack}>
                  <Icon style={{ marginRight: 8 }}>close</Icon> Close
                </Button>
              </Toolbar>
            </AppBar>
            <div style={{ minHeight: 50 }} />
            {this.props.detail}
          </Dialog>
        </Hidden>
      </div>
    )
  }
}

PanelListDetail.defaultProps = {
  open: false,
  loadMoreButton: true
}

export default withStyles(styles)(PanelListDetail)
