import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import Detail from './components/Detail'
import Overview from './components/Overview'

class Directory extends Component {
  render() {
    return (
      <div className="companies">
        <Switch>
          <Route path="/companies/:id" component={Detail} />
          <Route exact path="/companies" component={Overview} />
        </Switch>
      </div>
    )
  }
}

export default Directory
