import React, { PureComponent } from 'react'
import {
  Typography,
  Dialog,
  Button,
  Tabs,
  Tab,
  LinearProgress,
  GridList,
  GridListTile,
  IconButton,
  Icon
} from '@material-ui/core'
import { firebaseConnect } from 'react-redux-firebase'
import ImageUploader from 'react-images-upload'
import { compose } from 'redux'
import { connect } from 'react-redux'
import LazyLoad from 'react-lazyload'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import moment from 'moment'

import ImageTools from './../../../helpers/resizeImage'

class AttachImage extends PureComponent {
  state = { open: false, value: 0, upload_progress: 0, uploading: false, photos: [] }
  handleImageUpload = pictureFiles => {
    if (this.state.uploading || !pictureFiles.length) return
    this.setState(
      {
        uploading: true
      },
      () => {
        ImageTools.resize(
          pictureFiles[0],
          {
            width: 1000,
            height: 800
          },
          (blob, success) => {
            let reader = new FileReader()
            reader.onloadend = () => {
              this.uploadToFirebase(blob, pictureFiles[0].name)
            }
            reader.readAsDataURL(blob)
          }
        )
      }
    )
  }
  uploadToFirebase = (blob, name) => {
    console.log(blob, name.split(' ').join('_'))
    const date = new Date()
    const storage = this.props.firebase
      .storage()
      .ref()
      .child(
        `community_posts/images/${moment(date).format('YYYY')}/${moment(date).format('MM')}/${
          this.props.auth.uid
        }-${name.split(' ').join('_')}`
      )
    const upload = storage.put(blob)
    upload.on(
      this.props.firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        this.setState({
          upload_progress: (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        })
      },
      err => {},
      () => {
        upload.snapshot.ref
          .getDownloadURL()
          .then(url => {
            this.props.updateImage(url)
            this.sendToDB(url)
            this.setState({
              open: false,
              value: 0,
              upload_progress: 0,
              uploading: false
            })
          })
          .catch(err => {
            // this.props.enqueueSnackbar(`There was an error uploading the image, ${err.message}`, {
            //   variant: 'error'
            // })
            console.log(err)
          })
      }
    )
  }
  handleAttachmentUpload = e => {
    e.preventDefault()
    const date = new Date()
    const file = e.target.files[0]
    const bits = file.name.split('.')
    const type = bits[bits.length - 1]
    const path = `community_posts/resources/${moment(date).format('YYYY')}/${moment(date).format('MM')}/${
      this.props.auth.uid
    }-${moment(date).unix()}.${type}`
    console.log(file)
    if (this.state.uploading || !e.target.files.length) return
    this.setState(
      {
        uploading: true
      },
      () => {
        const storage = this.props.firebase
          .storage()
          .ref()
          .child(path)
        const upload = storage.put(file)
        upload.on(
          this.props.firebase.storage.TaskEvent.STATE_CHANGED,
          snapshot => {
            this.setState({
              upload_progress: (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            })
          },
          err => {},
          () => {
            upload.snapshot.ref
              .getDownloadURL()
              .then(url => {
                this.props.updateAttachment({
                  url,
                  label: file.name,
                  type: file.type,
                  path: path
                })
                this.setState({
                  upload_progress: 0,
                  uploading: false
                })
              })
              .catch(err => {
                // this.props.enqueueSnackbar(`There was an error uploading the image, ${err.message}`, {
                //   variant: 'error'
                // })
                console.log(err)
              })
          }
        )
      }
    )
  }
  sendToDB = url => {
    this.props.firebase.push('resource_images', url)
  }
  toggleModal = () => {
    this.setState({
      open: !this.state.open
    })
  }
  handleChange = (event, value) => {
    this.setState({ value })
  }
  selectImage = img => {
    this.props.updateImage(img)
    this.toggleModal()
  }
  deleteAttachment = () => {
    this.props.firebase
      .storage()
      .ref()
      .child(this.props.attachment.path)
      .delete()
      .then(() => {
        this.props.updateAttachment(null)
      })
  }
  getGridListCols = () => {
    if (isWidthUp('md', this.props.width)) {
      return 3
    }
    if (isWidthUp('sm', this.props.width)) {
      return 2
    }
    return 1
  }
  getCellHeight = () => {
    if (isWidthUp('md', this.props.width)) {
      return 150
    }
    if (isWidthUp('sm', this.props.width)) {
      return 180
    }
    return 250
  }
  render() {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
          <div style={{ marginRight: 24, marginTop: 24 }}>
            <Typography variant="h6" gutterBottom>
              Featured Image
            </Typography>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <Button
                onClick={this.toggleModal}
                variant="contained"
                color="default"
                style={{
                  width: 250,
                  height: 150,
                  backgroundColor: '#f2f2f2',
                  backgroundImage: `url('${this.props.selectedImage}')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  marginBottom: 4,
                  color: 'transparent'
                }}
              >
                Featured Image
                {this.props.selectedImage && (
                  <div
                    onClick={e => {
                      e.stopPropagation()
                      this.props.updateImage(null)
                    }}
                    className="remove-icon"
                  >
                    <Icon>delete</Icon>
                  </div>
                )}
              </Button>
            </div>
            <Typography variant="caption">Select an Image</Typography>
          </div>
          <div style={{ marginRight: 24, marginTop: 24 }}>
            <Typography variant="h6" gutterBottom>
              Attachment
            </Typography>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 24,
                borderRadius: 4,
                height: 150,
                width: '100%',
                minWidth: 200,
                maxWidth: 300,
                backgroundColor: '#f2f2f2'
              }}
            >
              {this.props.attachment ? (
                <code>
                  <Typography className="file-attachment-label" variant="caption" gutterBottom>
                    {this.props.attachment.label}
                  </Typography>
                </code>
              ) : (
                <code>
                  <Typography className="file-attachment-label" variant="caption" gutterBottom>
                    No Attachment
                  </Typography>
                </code>
              )}
              <input
                accept="image/*, .pdf, application/pdf"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={e => this.handleAttachmentUpload(e)}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8 }}>
                <label htmlFor="raised-button-file">
                  <Button disabled={this.state.uploading} variant="contained" component="span">
                    {this.props.attachment ? 'Replace' : 'Upload'}
                  </Button>
                </label>
                {this.props.attachment ? (
                  <IconButton onClick={this.deleteAttachment} size="small" color="secondary">
                    <Icon>delete</Icon>
                  </IconButton>
                ) : null}
              </div>
              {this.state.uploading ? (
                <LinearProgress
                  variant="determinate"
                  value={this.state.upload_progress}
                  style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 2
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
        <Dialog maxWidth={'sm'} fullWidth open={this.state.open} onClose={this.toggleModal}>
          <div className="image-uploader">
            <LinearProgress variant="determinate" value={this.state.upload_progress} />
            <ImageUploader
              withIcon={true}
              buttonText="Choose image"
              onChange={this.handleImageUpload}
              accept="image/jpg, image/png, image/gif"
              label="Max file size: 6mb, accepted: jpg | png | gif"
              imgExtension={['.jpg', '.png', '.gif']}
              place
              maxFileSize={6242880}
            />
          </div>
        </Dialog>
      </div>
    )
  }
}

export default compose(
  withWidth(),
  firebaseConnect(),
  connect(state => ({
    auth: state.firebase.auth
  }))
)(AttachImage)
