import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import OfficeList from './components/OfficeList'
import OfficeDetail from './components/OfficeDetail'

class Office extends Component {
  componentDidMount() {
    console.log(this.props)
  }
  componentDidUpdate() {
    console.log(this.props)
    console.log('updated')
  }
  render() {
    return (
      <div className="office">
        <Switch>
          <Route exact path="/locations" component={OfficeList} />
          <Route exact path="/locations/:id" component={OfficeDetail} />
        </Switch>
      </div>
    )
  }
}

export default Office
