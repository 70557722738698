import React from 'react'
import { connectHits } from 'react-instantsearch-dom'
import {
  TableRow,
  TableCell,
  Avatar,
  Icon,
  Typography
} from '@material-ui/core'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Hit from './Hit'

const Hits = props => {
  return props.hits.map(hit => {
    let default_location = '--'
    if (props.locations) {
      if (props.locations[hit.default_location]) {
        default_location = props.locations[hit.default_location].name
      }
    }
    return (
      <Hit key={hit.objectID} hit={hit} default_location={default_location} />
    )
  })
}

export default compose(
  connectHits,
  withRouter,
  connect(state => ({
    locations: state.firestore.data.locations
  }))
)(Hits)
