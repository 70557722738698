import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import _ from 'lodash'
import {
  Fab,
  Icon,
  Dialog,
  Zoom,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Tooltip,
} from '@material-ui/core'

import ReportItem from './ReportItem'
import CreateReport from './CreateReport'

class ReportDetail extends Component {
  state = {
    rows: null,
    reportDetail: false,
    createReport: false,
    post_content: '',
    submitted: false,
    recentlyCreated: [],
    editPost: null,
    disabled: true,
    index: 0,
    lastDoc: null,
    data: [],
    loadMore: true,
    allDataLoaded: false,
  }
  componentDidMount() {
    this.mounted = true
    this.getData()
  }
  componentWillUnmount() {
    this.mounted = false
  }
  getData = (clear = false) => {
    if (this.state.allDataLoaded && !clear) {
      console.log('all tax items loaded')
      return
    }
    let query = this.props.firestore
      .collection('reports')
      .where('location', '==', this.props.match.params.location)
      .where('type', '==', 'bookings')
      .orderBy('created_on', 'desc')
    if (this.state.lastDoc !== null && !clear) {
      query = query.startAfter(this.state.lastDoc)
    }
    query = query.limit(10)
    query.get().then((docs) => {
      let data = clear ? [] : this.state.data
      docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      console.log(data)
      if (this.mounted) {
        this.setState({
          data,
          lastDoc: docs.docs[docs.docs.length - 1],
          allDataLoaded:
            docs.docs[docs.docs.length - 1] === undefined ||
            docs.docs.length < 10
              ? true
              : false,
          loadMore: false,
        })
      }
    })
  }
  hitBottom = _.debounce(() => {
    if (this.state.loadMore) return

    this.setState({ loadMore: true }, () => {
      this.getData()
    })
  }, 350)
  onChange = (e) => {
    this.setState({
      post_content: e.target.value,
    })
  }
  createReport = (obj) => {
    this.setState({
      recentlyCreated: obj
        ? [obj, ...this.state.recentlyCreated]
        : this.state.recentlyCreated,
      createReport: !this.state.createReport,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Zoom in>
          <Tooltip title="Generate Report">
            <Fab
              onClick={() => this.createReport()}
              className="main-action-fab"
              size="large"
              color="secondary"
            >
              <Icon>add_to_photos</Icon>
            </Fab>
          </Tooltip>
        </Zoom>
        <Dialog
          maxWidth="md"
          className="add-user-modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.createReport}
          onClose={() => this.createReport()}
        >
          <CreateReport
            type={'bookings'}
            location={this.props.match.params.location}
            user={{
              uid: this.props.auth.uid,
              display_name: `${this.props.profile.first_name} ${this.props.profile.last_name}`,
              email: this.props.auth.email,
            }}
            createReport={this.createReport}
          />
        </Dialog>
        <div id="resources" className="resources">
          <div className="resources-inner">
            <Paper>
              <Table padding="dense">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Total Clients</TableCell>
                    <TableCell>Total Hours</TableCell>
                    <TableCell>Total Bookings</TableCell>
                    <TableCell>Hourly Rate</TableCell>
                    <TableCell style={{ width: 80 }} align="right">
                      Download
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.recentlyCreated.length
                    ? this.state.recentlyCreated.map((res) => (
                        <ReportItem
                          processing={true}
                          key={res.id}
                          report={res}
                        />
                      ))
                    : null}
                  {this.state.data.length
                    ? this.state.data.map((res) => (
                        <ReportItem key={res.id} report={res} />
                      ))
                    : null}
                </TableBody>
              </Table>
            </Paper>
            {this.state.allDataLoaded ? null : (
              <Button
                variant="contained"
                disabled={this.state.loadMore}
                onClick={this.hitBottom}
                style={{ marginTop: 16 }}
              >
                Load More
              </Button>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default compose(
  firestoreConnect(),
  connect((state) => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
    }
  }),
  withSnackbar
)(ReportDetail)
