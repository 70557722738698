import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import LoginForm from './components/LoginForm'
import ResetPassword from './components/ResetPassword'

import logo from '../../media/logo.png'
import loginSplash from '../../media/login-splash.jpg'

class Login extends Component {
  render() {
    return (
      <div className="login">
        <div className="image" style={{ backgroundImage: `url(${loginSplash})` }} />
        <div className="content-area">
          <img src={logo} alt="Expansive Workspace" />
          <Switch>
            <Route exact path="/login" component={LoginForm} />
            <Route exact path="/login/forgot-password" component={ResetPassword} />
          </Switch>
        </div>
      </div>
    )
  }
}

export default Login
