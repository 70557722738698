import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import axios from 'axios'

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Avatar,
  Icon,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Grid,
  Chip
} from '@material-ui/core'

import ModeCommentOutlined from '@material-ui/icons/ModeCommentOutlined'

import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import Favorite from '@material-ui/icons/Favorite'

import Comments from './Comments'

import { FunctionsDir } from '../../../config/firebase'

class PostFeedItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      comments: [],
      loadMore: false,
      allLoaded: false,
      showComments: false,
      disabled: this.props.post.disabled,
      liked: this.props.post.users_liked.filter(u => u === this.props.auth.uid).length > 0,
      local_like_count: this.props.post.like_count
    }
  }
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  showComments = () => {
    this.setState({
      showComments: true
    })
  }
  likePost = _.debounce(() => {
    let body = {
      community: this.props.community,
      post: this.props.post.id,
      user: {
        name: `${this.props.profile.first_name} ${this.props.profile.last_name}`,
        uid: this.props.auth.uid
      }
    }
    this.props.firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(token => {
        console.log(token)
        return axios.post(
          `${FunctionsDir}/community/likePost`,
          {
            ...body
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'content-type': 'application/octet-stream'
            }
          }
        )
      })
      .then(res => {
        console.log(res)
        this.setState({
          liked: !this.state.liked,
          local_like_count: !this.state.liked ? this.state.local_like_count + 1 : this.state.local_like_count - 1
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({
          submitted: false
        })
      })
  }, 500)
  render() {
    const post = this.props.post
    const { anchorEl } = this.state
    return (
      <Card style={{ marginBottom: 16 }}>
        {this.state.disabled ? (
          <div style={{ backgroundColor: '#ff867c', padding: '8px 16px', fontSize: 14 }}>
            This post has been disabled
          </div>
        ) : null}
        <CardHeader
          avatar={<Avatar src={post.author.image} />}
          title={`${post.author.name}`}
          subheader={`${moment.unix(post.created_on.seconds).fromNow()}`}
          action={
            <React.Fragment>
              <IconButton
                onClick={this.handleClick}
                aria-owns={anchorEl ? 'edit-comment' : undefined}
                aria-haspopup="true"
              >
                <Icon>more_vert</Icon>
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose} id="edit-comment">
                <MenuItem
                  onClick={() => {
                    this.props.editPost(post)
                    this.handleClose()
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    let p = post
                    p.disabled = this.state.disabled
                    this.props.deletePost(p).then(res => {
                      console.log(res, post.disabled)
                      this.setState({ disabled: res })
                      this.handleClose()
                    })
                  }}
                >
                  {this.state.disabled ? 'Enable' : 'Disable'}
                </MenuItem>
              </Menu>
            </React.Fragment>
          }
        />
        <CardContent>
          <Typography variant="body1" component="p" style={{ whiteSpace: 'pre-wrap' }}>
            {post.post_content}
          </Typography>
          {post.featured_image && (
            <div
              style={{
                margin: '20px 0 0',
                borderRadius: 4,
                overflow: 'hidden'
              }}
            >
              <img src={post.featured_image} style={{ width: '100%', display: 'block', margin: '0 auto' }} alt="" />
            </div>
          )}
          {post.attachment && (
            <div style={{ margin: '20px 0 0' }}>
              <Chip
                component={'a'}
                href={post.attachment.url}
                target="_blank"
                clickable
                icon={<Icon>attachment</Icon>}
                label={post.attachment.label}
              />
            </div>
          )}
        </CardContent>
        <CardActions>
          <Grid container>
            <Grid xs={12} item style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1 }}>
                <Button
                  color={this.state.liked ? 'secondary' : 'default'}
                  onClick={this.likePost}
                  style={{ minWidth: 0 }}
                >
                  {this.state.liked ? (
                    <Favorite
                      style={{
                        marginRight: 8,
                        fontSize: 20,
                        color: '#f44336'
                      }}
                    />
                  ) : (
                    <FavoriteBorder
                      style={{
                        marginRight: 8,
                        fontSize: 20
                      }}
                    />
                  )}{' '}
                  <Typography variant="body1">{this.state.local_like_count}</Typography>
                </Button>
                <Button onClick={this.showComments} style={{ minWidth: 0 }}>
                  <ModeCommentOutlined style={{ color: '#777', marginRight: 8, fontSize: 20 }} />{' '}
                  <Typography variant="body1">{post.comment_count}</Typography>
                </Button>
              </div>
              {this.state.showComments ? null : (
                <Button onClick={this.showComments} color="primary">
                  View Comments
                </Button>
              )}
            </Grid>
            <Grid xs={12} item>
              <Collapse in={this.state.showComments}>
                {this.state.showComments ? <Comments community={this.props.community} post={post} /> : null}
              </Collapse>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    )
  }
}

export default compose(
  firestoreConnect(),
  connect(state => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile
    }
  }),
  withSnackbar
)(PostFeedItem)
