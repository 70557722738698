import React, { Component } from 'react'
import { firestoreConnect } from 'react-redux-firebase'
import BottomScrollListener from 'react-bottom-scroll-listener'
import { withSnackbar } from 'notistack'
import _ from 'lodash'
import { compose } from 'redux'
import { withMobileDialog, Dialog } from '@material-ui/core'

import PostFeedItem from './PostFeedItem'
import CreatePost from './CreatePost'

class PostFeed extends Component {
  state = {
    disabled: true,
    index: 0,
    lastDoc: null,
    data: [],
    loadMore: true,
    allDataLoaded: false,
    editPost: false,
    submitted: false,
    post_content: ''
  }
  componentDidMount() {
    this.mounted = true
    this.getData()
  }
  componentWillUnmount() {
    this.mounted = false
  }
  getData = (clear = false) => {
    if (this.state.allDataLoaded && !clear) {
      console.log('all tax items loaded')
      return
    }
    let query = this.props.firestore
      .collection('community')
      .where('location', '==', this.props.community)
      .orderBy('created_on', 'desc')
    if (this.state.lastDoc !== null && !clear) {
      query = query.startAfter(this.state.lastDoc)
    }
    query = query.limit(10)
    query.get().then(docs => {
      let data = clear ? [] : this.state.data
      docs.forEach(doc => {
        data.push({ ...doc.data(), id: doc.id })
      })
      console.log(data)
      if (this.mounted) {
        this.setState({
          data,
          lastDoc: docs.docs[docs.docs.length - 1],
          allDataLoaded: docs.docs[docs.docs.length - 1] === undefined ? true : false,
          loadMore: false
        })
      }
    })
  }
  hitBottom = _.debounce(() => {
    if (this.state.loadMore) return

    this.setState({ loadMore: true }, () => {
      this.getData()
    })
  }, 350)
  editPost = post => {
    this.props.editPost(post, this.getData)
  }

  deletePost = async post =>
    new Promise((resolve, reject) => {
      console.log(post)
      const confirm = window.confirm(
        post.disabled ? 'Are you sure you want to enable this post?' : 'Are you sure you want to disable this post?'
      )

      if (confirm) {
        this.props.firestore
          .collection('community')
          .doc(post.id)
          .update({ disabled: !post.disabled })
          .then(() => {
            this.props.enqueueSnackbar(`Post ${post.disabled ? 'Enabled' : 'Disabled'}`, {
              variant: 'success'
            })
            this.setState(
              {
                submitted: false,
                editPost: false,
                post_content: '',
                post_id: null
              },
              () => {
                resolve(!post.disabled)
              }
            )
          })
          .catch(err => {
            reject(err)
          })
      } else {
        resolve(post.disabled)
      }
    })
  render() {
    const { fullScreen } = this.props
    return (
      <React.Fragment>
        <BottomScrollListener debounce={250} onBottom={this.hitBottom}>
          {scrollRef => (
            <div
              ref={scrollRef}
              id="post-feed"
              style={{ margin: '0 auto', padding: '48px 16px', overflow: 'auto', height: 'calc(100vh - 64px)' }}
              className="post-feed"
            >
              <div style={{ margin: '0 auto', maxWidth: 600 }}>
                {this.props.recentlyCreated.map(post => {
                  return (
                    <PostFeedItem
                      key={post.id}
                      post={post}
                      community={this.props.community}
                      editPost={this.editPost}
                      deletePost={this.deletePost}
                    />
                  )
                })}
                {this.state.data.map(post => {
                  return (
                    <PostFeedItem
                      key={post.id}
                      post={post}
                      community={this.props.community}
                      editPost={this.editPost}
                      deletePost={this.deletePost}
                    />
                  )
                })}
              </div>
            </div>
          )}
        </BottomScrollListener>
        <Dialog
          maxWidth="sm"
          fullWidth
          fullScreen={fullScreen}
          className="add-user-modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.editPost}
        >
          <CreatePost
            title="Update Post"
            button="Update Post"
            createPost={this.editPost}
            onSubmit={this.onSubmit}
            post_content={this.state.post_content}
            onChange={this.onChange}
            submitted={this.state.submitted}
          />
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(
  firestoreConnect(),
  withMobileDialog(),
  withSnackbar
)(PostFeed)
