import React from 'react'

import StyleButton from './StyleButton'

class BlockType extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      blockTypes: this.getBlockTypes(props.translations)
    }
  }
  getBlockTypes = translations => [
    { label: 'Normal', displayName: 'P' },
    { label: 'H1', displayName: translations['components.controls.blocktype.h1'] },
    { label: 'H2', displayName: translations['components.controls.blocktype.h2'] },
    { label: 'H3', displayName: translations['components.controls.blocktype.h3'] },
    { label: 'H4', displayName: translations['components.controls.blocktype.h4'] },
    { label: 'H5', displayName: translations['components.controls.blocktype.h5'] },
    { label: 'H6', displayName: translations['components.controls.blocktype.h6'] },
    { label: 'Blockquote', displayName: translations['components.controls.blocktype.blockquote'] },
    { label: 'Code', displayName: translations['components.controls.blocktype.code'] }
  ]
  renderFlat = blocks => {
    const {
      config: { className },
      onChange,
      currentState: { blockType }
    } = this.props
    return (
      <div className={'rdw-inline-wrapper'}>
        {blocks.map((block, index) => (
          <StyleButton
            key={index}
            value={block.label}
            active={blockType === block.label}
            onClick={onChange}
            label={block.displayName}
          />
        ))}
      </div>
    )
  }
  render() {
    const { config } = this.props
    const { inDropdown } = config
    const { blockTypes } = this.state
    const blocks = blockTypes.filter(({ label }) => config.options.indexOf(label) > -1)
    return this.renderFlat(blocks)
  }
}

export default BlockType
