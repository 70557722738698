import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import {
  Fab,
  Icon,
  Zoom,
  Dialog,
  withMobileDialog,
  Tooltip,
} from '@material-ui/core'

import BookingModal from './BookingModal'

class BookingControls extends Component {
  state = {
    createBooking: false,
  }
  componentDidMount() {
    this.mounted = true
  }
  componentWillUnmount() {
    this.mounted = false
  }
  toggleCreateBooking = () => {
    this.setState({
      createBooking: !this.state.createBooking,
    })
  }
  render() {
    const { fullScreen } = this.props
    return (
      <React.Fragment>
        <Dialog
          maxWidth="md"
          fullWidth
          fullScreen={fullScreen}
          className="add-user-modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.createBooking}
        >
          <BookingModal
            auth={this.props.auth}
            profile={this.props.profile}
            acitveLocationID={this.props.acitveLocationID}
            activeLocation={this.props.activeLocation}
            handleClose={this.toggleCreateBooking}
            rooms={this.props.rooms}
            isDaily={this.props.isDaily || false}
          />
        </Dialog>
        <Zoom in>
          <Tooltip title="Book a Room">
            <Fab
              onClick={this.toggleCreateBooking}
              className="main-action-fab"
              size="large"
              color="secondary"
            >
              <Icon>book</Icon>
            </Fab>
          </Tooltip>
        </Zoom>
      </React.Fragment>
    )
  }
}

export default compose(
  withMobileDialog(),
  connect((state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }))
)(BookingControls)
