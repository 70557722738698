import React, { Component } from 'react'
import { Paper, Button, TextField, Typography } from '@material-ui/core'
import { DateRangePicker } from 'react-dates'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import moment from 'moment'
import _ from 'lodash'
import { withSnackbar } from 'notistack'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'

class Notifications extends Component {
  state = {
    start_date: null,
    end_date: null,
    message: '',
    submitted: false,
    send_email: false
  }
  componentDidMount() {}
  sendNotification = e => {
    e.preventDefault()
    this.setState({
      submitted: true
    })
    this.props.firestore
      .collection('locations')
      .doc(this.props.location)
      .collection('alerts')
      .add({
        disabled: false,
        start_date: Number(this.state.start_date.format('YYYYMMDDHHmm')),
        end_date: Number(this.state.end_date.format('YYYYMMDDHHmm')),
        message: this.state.message,
        send_email: this.state.send_email,
        created_by: {
          name: this.props.auth.displayName,
          uid: this.props.auth.uid,
          email_address: this.props.auth.email
        }
      })
      .then(() => {
        this.props.enqueueSnackbar(`Alert Created.`, {
          variant: 'success'
        })
        this.setState({
          start_date: null,
          end_date: null,
          message: '',
          submitted: false,
          send_email: false
        })
      })
  }
  setNewFocus = focusedInput => {
    this.setState({
      focusedInput
    })
  }
  cancelAlert = alert => {
    const confirm = window.confirm('Are you sure you want to delete this alert?')
    if (confirm) {
      this.props.firestore
        .collection('locations')
        .doc(this.props.location)
        .collection('alerts')
        .doc(alert.id)
        .update({
          disabled: true
        })
        .then(() => {
          this.props.enqueueSnackbar(`Alert Removed.`, {
            variant: 'success'
          })
        })
    }
  }
  sendEmail = () => {
    if (this.state.send_email) {
      this.setState({
        send_email: false
      })
    } else {
      const confirm = window.confirm(
        'Are you sure you want to send all Expansive clients an email with this community alert?'
      )
      if (confirm) {
        const date = moment(new Date())
        this.setState({
          send_email: true,
          start_date: date,
          end_date: date
        })
      }
    }
  }
  render() {
    const location = this.props.locations && this.props.locations.filter(loc => loc.id === this.props.location)
    return (
      <Paper style={{ padding: 16 }}>
        <form onSubmit={this.sendNotification} style={{ marginTop: 16 }}>
          <TextField
            onChange={e => {
              this.setState({ message: e.target.value.length > 120 ? this.state.message : e.target.value })
            }}
            fullWidth
            multiline
            required
            variant="outlined"
            label="Message"
            value={this.state.message}
          />
          <Typography variant="caption" color="textSecondary" style={{ paddingTop: 4 }}>
            {this.state.message.length}/120
          </Typography>
          {!this.state.send_email ? (
            <React.Fragment>
              <div style={{ marginTop: 16, marginBottom: 16 }} />
              <DateRangePicker
                startDate={this.state.start_date}
                startDateId="start_date"
                endDate={this.state.end_date}
                endDateId="end_date"
                onDatesChange={({ startDate, endDate }) => this.setState({ start_date: startDate, end_date: endDate })}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => this.setNewFocus(focusedInput)}
                orientation="vertical"
                verticalHeight={600}
                hideKeyboardShortcutsPanel
              />
              <Typography variant="caption" color="textSecondary" style={{ marginTop: 8 }}>
                This is how long the alert will appear for a user.
              </Typography>
            </React.Fragment>
          ) : (
            <Typography variant="body1" color="textPrimary" gutterBottom style={{ marginTop: 8, fontWeight: 500 }}>
              The alert will be visible in-app until the end of today.
            </Typography>
          )}
          <div style={{ marginTop: 16, marginBottom: 16 }} />
          <FormControl component="fieldset">
            <FormLabel component="legend">Immediately Alert With Email</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={this.state.send_email} onChange={this.sendEmail} />}
                label={`Send ${location.length ? ' ' + location[0].name + ' ' : ' '}clients an email with this alert`}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 16, marginBottom: 16 }} />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              this.state.start_date === null ||
              this.state.end_date === null ||
              this.state.message.length < 3 ||
              this.state.submitted
            }
          >
            Send Alert
          </Button>
        </form>
        <Typography variant="h6" style={{ marginTop: 24 }}>
          Upcoming Alerts
        </Typography>
        <List style={{ marginRight: -10 }}>
          {this.props.alerts &&
            _.orderBy(this.props.alerts, ['end_date', 'start_date'], ['asc', 'asc']).map((alert, i) => {
              return (
                <ListItem
                  key={alert.id}
                  style={{
                    borderLeft:
                      i === 0 &&
                      moment(alert.start_date, 'YYYYMMDDHHmm').startOf('day') <=
                        Number(moment(new Date()).startOf('day'))
                        ? '4px solid green'
                        : '4px solid transparent'
                  }}
                >
                  <ListItemText
                    primary={`${moment(alert.start_date, 'YYYYMMDDHHmm').format('L')} — ${moment(
                      alert.end_date,
                      'YYYYMMDDHHmm'
                    ).format('L')}`}
                    secondary={alert.message}
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => this.cancelAlert(alert)} aria-label="Delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
        </List>
      </Paper>
    )
  }
}

export default compose(
  withSnackbar,
  firestoreConnect(props => [
    {
      collection: 'locations',
      doc: props.location,
      subcollections: [
        {
          collection: 'alerts',
          where: [
            ['disabled', '==', false],
            [
              'end_date',
              '>=',
              Number(
                moment()
                  .startOf('day')
                  .format('YYYYMMDDHHmm')
              )
            ]
          ]
        }
      ],
      storeAs: 'alerts'
    }
  ]),
  connect(state => {
    return {
      alerts: state.firestore.ordered.alerts,
      locations: state.firestore.ordered.locations,
      auth: state.firebase.auth
    }
  })
)(Notifications)
