import React, { Component } from 'react'

import Card from '@material-ui/core/Card'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'

class RoomsListItem extends Component {
  state = {
    anchorEl: null,
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  openEdit = () => {
    this.props.toggleUpdateRoom(this.props.room)
    this.handleClose()
  }
  render() {
    const { anchorEl } = this.state
    const room = this.props.room
    console.log(room)
    return (
      <Card style={{ height: '100%' }}>
        <CardHeader
          style={{ display: 'flex', justifyContent: 'center' }}
          disableTypography
          title={
            <Typography
              component="h6"
              variant="body1"
              style={{ fontWeight: '500' }}
            >
              {room.name}
            </Typography>
          }
          subheader={
            this.props.privateOffice ? null : (
              <Typography component="h6" variant="body2" color="textSecondary">
                {`Floor ${room.floor_number} • Room ${room.room_number} • Seats ${room.seating}`}
              </Typography>
            )
          }
          action={
            <React.Fragment>
              <IconButton
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <Icon>more_vert</Icon>
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem
                  onClick={() => this.props.toggleActiveRoom(room)}
                  style={{ paddingRight: 0 }}
                >
                  <span style={{ marginRight: 16 }}>Enabled</span>{' '}
                  <Switch checked={room.active} />
                </MenuItem>
                <MenuItem onClick={this.openEdit}>Edit</MenuItem>
              </Menu>
            </React.Fragment>
          }
        />
        <CardMedia image={room.image} style={{ height: 200 }} />
        {this.props.privateOffice ? null : (
          <CardContent>
            <Typography component="p">{room.description}</Typography>
            {room.amenities.length ? (
              <div style={{ marginTop: 16 }}>
                {room.amenities.map((amenity, i) => (
                  <Typography
                    style={{ paddingRight: 16, display: 'inline-block' }}
                    key={`${amenity}-${i}`}
                    component="span"
                    color="textSecondary"
                  >
                    • {amenity}
                  </Typography>
                ))}
              </div>
            ) : null}
          </CardContent>
        )}
      </Card>
    )
  }
}

export default RoomsListItem
