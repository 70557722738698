import React from 'react'
import Fade from '@material-ui/core/Fade'
import {
  Typography,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Grid,
  TextField,
} from '@material-ui/core'
import { DatePicker } from 'material-ui-pickers'
import moment from 'moment-timezone'

import Room from './Room'

export default (props) => {
  if (props.active) {
    return (
      <Fade in={props.active}>
        <div className="step-3">
          <Grid spacing={24} container>
            <Grid item xs={12}>
              <Typography
                component="h6"
                variant="body1"
                style={{ fontWeight: '500', marginBottom: 16 }}
              >
                Client
              </Typography>
              <List
                style={{
                  marginRight: 16,
                  borderRight: '1px solid rgba(0,0,0,0.15)',
                }}
              >
                <ListItem>
                  <ListItemAvatar src={props.booking.client.image}>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText
                    primary={props.booking.client.display_name}
                    secondary={props.booking.client.email_address}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                component="h6"
                variant="body1"
                style={{ fontWeight: '500', marginBottom: 16 }}
              >
                Reservation
              </Typography>
              <Grid
                spacing={16}
                container
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                  marginBottom: 24,
                }}
              >
                <Grid xs={12} md={3} item>
                  <DatePicker
                    disabled
                    fullWidth
                    format={'MM/DD/YYYY'}
                    variant="outlined"
                    label="Date"
                    onChange={(e) => {}}
                    value={moment(props.booking.date, 'YYYYMMDD').format('L')}
                  />
                </Grid>
                {props.isDaily ? null : (
                  <React.Fragment>
                    <Grid xs={12} md={3} item>
                      <TextField
                        disabled
                        fullWidth
                        variant="outlined"
                        label="Start Time"
                        onChange={(e) => {}}
                        value={`${moment
                          .unix(props.booking.start_time.seconds)
                          .tz(props.activeLocation)
                          .format('h:mma z')}`}
                      />
                    </Grid>
                    <Grid xs={12} md={'auto'} item>
                      <div style={{ margin: '18px 0' }}>to</div>
                    </Grid>
                    <Grid xs={12} md={3} item>
                      <TextField
                        disabled
                        fullWidth
                        variant="outlined"
                        label="End Time"
                        onChange={(e) => {}}
                        value={`${moment
                          .unix(props.booking.end_time.seconds)
                          .tz(props.activeLocation)
                          .format('h:mma z')}`}
                      />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                gutterBottom
                component="h6"
                variant="body1"
                style={{ fontWeight: '500', marginBottom: 16 }}
              >
                {props.isDaily ? 'Day Office' : 'Conference Room'}
              </Typography>
              <Room
                disabled
                room={props.rooms.find(
                  (room) => room.id === props.booking.room.uid
                )}
                isDaily={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                gutterBottom
                component="h6"
                variant="body1"
                style={{ fontWeight: '500', marginBottom: 16 }}
              >
                Notes
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                disabled
                type="text"
                name="notes"
                multiline
                onChange={(e) => {}}
                value={props.booking.notes}
              />
            </Grid>
          </Grid>
        </div>
      </Fade>
    )
  }
  return null
}
