import React, { Component } from 'react'

import { IconButton, Typography, Icon, DialogContent, Button, DialogActions, TextField } from '@material-ui/core'

import MuiDialogTitle from '@material-ui/core/DialogTitle'

import LoadingSplash from '../../../components/LoadingSplash'

class CreatePost extends Component {
  render() {
    return (
      <React.Fragment>
        <MuiDialogTitle disableTypography className="booking-modal-title">
          <Typography variant="h5">{this.props.title}</Typography>
          <IconButton onClick={this.props.createPost} className="close-button" aria-label="Close">
            <Icon>close</Icon>
          </IconButton>
        </MuiDialogTitle>
        <DialogContent style={{ paddingTop: 5, height: 'calc(100% - 95px)' }}>
          <form onSubmit={this.props.onSubmit} id="create-post-form" autoComplete="off">
            <TextField
              required
              fullWidth
              variant="outlined"
              name="post_content"
              placeholder="Add Your Comment"
              label="Comment"
              multiline
              value={this.props.post_content}
              onChange={this.props.onChange}
              rows={3}
              rowsMax={20}
              style={{ minHeight: 120, maxHeight: 500 }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button disabled={this.props.submitted} type="submit" size="large" form="create-post-form" color="primary">
            {this.props.button}
          </Button>
        </DialogActions>
        {this.props.submitted && (
          <LoadingSplash label={this.props.button === 'Create Post' ? 'Creating Post...' : 'Updating Post...'} />
        )}
      </React.Fragment>
    )
  }
}

CreatePost.defaultProps = {
  title: 'New Post',
  button: 'Create Post'
}

export default CreatePost
