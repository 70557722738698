import React, { Component } from 'react'
import Fade from '@material-ui/core/Fade'
import { DatePicker } from 'material-ui-pickers'
import Moment from 'moment-timezone'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import {
  Typography,
  Grid,
  FormControl,
  Select,
  OutlinedInput,
  InputLabel,
  Divider,
  Paper,
  Button,
} from '@material-ui/core'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

class Step3 extends Component {
  getAvailableSlots = () => {
    const duration = this.props.duration
    let listOfOptions = []
    const minuteSlots = [0, 15, 30, 45]
    const start = moment
      .tz(this.props.booking.date, 'YYYYMMDD', this.props.activeLocation)
      .set({ hour: 0, minute: 0 })
    const end = moment
      .tz(this.props.booking.date, 'YYYYMMDD', this.props.activeLocation)
      .set({ hour: 24, minute: 0 })
    const workDay = moment.range(start, end)

    for (let i = 0; i < 24; i++) {
      minuteSlots.forEach((m) => {
        const start_time = moment
          .tz(this.props.booking.date, 'YYYYMMDD', this.props.activeLocation)
          .set({ hour: i, minute: m })
        const end_time = start_time.clone().add({
          hour: duration.hours,
          minute: duration.minutes,
        })
        if (start_time.within(workDay) && end_time.within(workDay)) {
          listOfOptions.push({
            start_time,
            end_time,
          })
        }
      })
    }
    return listOfOptions.map((opt, key) => {
      let skip = false
      this.props.bookings.forEach((booking) => {
        const optRange = moment.range(opt.start_time, opt.end_time)
        const bookingRange = moment.range(
          moment.unix(booking.start_time.seconds),
          moment.unix(booking.end_time.seconds)
        )

        if (optRange.overlaps(bookingRange)) {
          skip = true
        }
      })
      // if (skip) return null
      if (!skip) {
        return (
          <Grid key={key} xs={12} sm={6} md={3} item>
            <Paper>
              <Button
                disabled={skip}
                disableRipple
                onClick={() => this.selectObj(opt)}
                variant={
                  this.props.booking.start_time.seconds ===
                    opt.start_time.unix() &&
                  this.props.booking.end_time.seconds === opt.end_time.unix()
                    ? 'contained'
                    : 'text'
                }
                color={
                  this.props.booking.start_time.seconds ===
                    opt.start_time.unix() &&
                  this.props.booking.end_time.seconds === opt.end_time.unix()
                    ? 'primary'
                    : 'default'
                }
                size="large"
                fullWidth
                style={{ justifyContent: 'flex-start', padding: '16px 12px' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    color="inherit"
                    variant="body2"
                    style={{ fontWeight: '500' }}
                    component="h6"
                  >
                    {opt.start_time.format('LT')} - {opt.end_time.format('LT')}
                  </Typography>
                </div>
              </Button>
            </Paper>
          </Grid>
        )
      }
    })
  }
  selectObj = (opt) => {
    this.props.selectTimeSlot(opt)
  }
  render() {
    if (this.props.active) {
      return (
        <Fade in={this.props.active}>
          <div className="step-3">
            <Grid spacing={16} container>
              <Grid xs={12} item style={{ marginTop: 16 }}>
                <Typography
                  gutterBottom
                  component="h6"
                  variant="body1"
                  style={{ fontWeight: '500', marginBottom: 16 }}
                >
                  Select date and duration
                </Typography>
                <Grid
                  spacing={16}
                  container
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                  }}
                >
                  <Grid xs={12} sm={3} md={2} item>
                    <DatePicker
                      fullWidth
                      format={'MM/DD/YYYY'}
                      variant="outlined"
                      label="Date"
                      maxDate={moment(new Date()).add(3, 'months').format('L')}
                      onChange={(e) => this.props.changeTimeValue(e, 'date')}
                      value={moment(this.props.booking.date, 'YYYYMMDD').format(
                        'L'
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={'auto'} item>
                    <div style={{ margin: '18px 0' }}>duration</div>
                  </Grid>
                  <Grid xs={12} sm={4} md={3} item style={{ display: 'flex' }}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="duration-hours">Hours</InputLabel>
                      <Select
                        native
                        value={this.props.duration.hours}
                        onChange={(e) => this.props.changeTimeDuration(e)}
                        input={
                          <OutlinedInput
                            name="hours"
                            labelWidth={45}
                            id="duration-hours"
                          />
                        }
                      >
                        {[...Array(25)].map((_, i) => {
                          console.log(i)
                          return <option value={i}>{i}</option>
                        })}
                      </Select>
                    </FormControl>
                    <div style={{ margin: '18px 8px' }}>:</div>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="duration-minutes">
                        Minutes
                      </InputLabel>
                      <Select
                        native
                        value={this.props.duration.minutes}
                        onChange={(e) => this.props.changeTimeDuration(e)}
                        input={
                          <OutlinedInput
                            name="minutes"
                            labelWidth={60}
                            id="duration-minutes"
                          />
                        }
                      >
                        <option value={0}>00</option>
                        <option value={15}>15</option>
                        <option value={30}>30</option>
                        <option value={45}>45</option>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ margin: '16px 0' }} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" gutterBottom>
                  All time slots are based off timezone of the Expansive office (
                  {moment().tz(this.props.activeLocation).format('z')})
                </Typography>
              </Grid>
              {this.getAvailableSlots()}
            </Grid>
          </div>
        </Fade>
      )
    }
    return null
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: 'bookings',
      where: [
        ['location.id', '==', props.booking.location.id],
        ['room.uid', '==', props.booking.room.uid],
        ['date', '==', props.booking.date],
        ['canceled', '==', false],
      ],
    },
  ]),
  connect((state) => {
    return {
      bookings: state.firestore.ordered.bookings,
    }
  })
)(Step3)
