import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { withRouter, Redirect, NavLink } from 'react-router-dom'
import { withSnackbar } from 'notistack'

import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import Fab from '@material-ui/core/Fab'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import AddLocation from './AddLocation'
import AddRoom from './AddRoom'
import RoomList from './RoomsList'

const adminOrRM = (role) => ['admin', 'regional_manager'].includes(role)

class OfficeDetail extends PureComponent {
  state = { updateOffice: false, createRoom: false, editRoom: null }
  toggleUpdateOffice = () => {
    this.setState({
      updateOffice: !this.state.updateOffice,
    })
  }
  togglePublic = () => {
    const confirm = window.confirm(
      this.props.locations[this.props.match.params.id].active
        ? 'By disabling the public setting users within the Expansive app will no longer be able to view this location. Community managers will also no longer be able to assign clients to this location. Do you wish to continue?'
        : 'By enabling the public setting users within the Expansive app will be able to view this location. Community managers will also be able to assign clients to this location. Do you wish to continue?'
    )

    if (confirm) {
      this.props.firestore
        .collection('locations')
        .doc(this.props.match.params.id)
        .update({
          active: !this.props.locations[this.props.match.params.id].active,
        })
        .then(() => {
          this.props.enqueueSnackbar(
            `The public status of ${
              this.props.locations[this.props.match.params.id].name
            } has been updated.`,
            {
              variant: 'info',
            }
          )
        })
    }
  }
  toggleAccessPass = () => {
    const confirm = window.confirm(
      this.props.locations[this.props.match.params.id].access_pass_enabled
        ? 'By disabling Access Pass users within the Expansive app will no longer be able to book private offices. Do you wish to continue?'
        : 'By enabling Access Pass users within the Expansive app will be able to book private offices. Do you wish to continue?'
    )

    if (confirm) {
      let val = this.props.firestore
        .collection('locations')
        .doc(this.props.match.params.id)
        .update({
          access_pass_enabled:
            !this.props.locations[this.props.match.params.id]
              .access_pass_enabled || false,
        })
        .then(() => {
          this.props.enqueueSnackbar(
            `Access Pass for ${
              this.props.locations[this.props.match.params.id].name
            } has been updated.`,
            {
              variant: 'info',
            }
          )
        })
    }
  }
  toggleCreateRoom = () => {
    this.setState({
      createRoom: !this.state.createRoom,
      editRoom: null,
    })
  }
  toggleUpdateRoom = (room) => {
    this.setState({
      createRoom: !this.state.createRoom,
      editRoom: room,
    })
  }
  render() {
    if (
      !isLoaded(this.props.locations[this.props.match.params.id]) ||
      !isLoaded(this.props.profile)
    )
      return (
        <div className="splash-loader">
          <CircularProgress />
        </div>
      )
    const location = this.props.locations[this.props.match.params.id]
    console.log(location)
    if (location === undefined) {
      this.props.enqueueSnackbar(
        `The office you tried to access does not exist`
      )
      return <Redirect to="/locations" />
    }
    return (
      <div className="office-detail">
        <Zoom in>
          <Tooltip title="Add Room">
            <Fab
              onClick={this.toggleCreateRoom}
              className="main-action-fab"
              size="large"
              color="secondary"
            >
              <Icon>library_add</Icon>
            </Fab>
          </Tooltip>
        </Zoom>
        <div
          className="hero"
          style={{ backgroundImage: `url('${location.image}')` }}
        />
        <div className="details">
          <Grid container spacing={8}>
            <Grid xs={12} sm={'auto'} style={{ flex: 1 }} item>
              <Typography variant="h4" gutterBottom>
                {location.name}
              </Typography>
            </Grid>
            {adminOrRM(this.props.profile.role) && (
              <Grid
                xs={12}
                sm={'auto'}
                item
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Tooltip title="Toggle to make public">
                  <FormControlLabel
                    style={{ marginLeft: 0 }}
                    control={
                      <Switch
                        checked={location.active}
                        onChange={this.togglePublic}
                        value="location-enabled"
                      />
                    }
                    label="Public"
                    labelPlacement="start"
                  />
                </Tooltip>
                <Button
                  onClick={this.toggleUpdateOffice}
                  style={{ marginLeft: 32 }}
                >
                  Edit Location
                </Button>
              </Grid>
            )}
          </Grid>
          <Divider className="spacer" />
          <Grid
            alignContent="space-between"
            direction="row-reverse"
            container
            spacing={24}
          >
            <Grid xs={12} md={4} lg={3} item>
              <Typography variant="h6">Address</Typography>
              <Typography component="p" color="textSecondary">
                {location.address}
                <br />
                {location.city}, {location.state} {location.zip}
              </Typography>
              <Divider className="spacer" />
              <Typography variant="h6">Community Manager</Typography>
              {location.community_manager &&
              Object.keys(location.community_manager).length ? (
                <List>
                  {Object.keys(location.community_manager).map((key) => (
                    <ListItem
                      key={key}
                      button={adminOrRM(this.props.profile.role)}
                      component={
                        adminOrRM(this.props.profile.role) ? NavLink : null
                      }
                      to={`/admin/${location.community_manager[key].uid}`}
                    >
                      <ListItemAvatar>
                        <Avatar src={location.community_manager[key].image} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={location.community_manager[key].name}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography component="p" color="textSecondary">
                  There is no assigned Community Manager
                </Typography>
              )}
              <Divider className="spacer" />
              <Typography variant="h6">Area Sales Manager</Typography>
              {location.center_manager &&
              Object.keys(location.center_manager).length ? (
                <List>
                  {Object.keys(location.center_manager).map((key) => (
                    <ListItem
                      key={key}
                      button={adminOrRM(this.props.profile.role)}
                      component={
                        adminOrRM(this.props.profile.role) ? NavLink : null
                      }
                      to={`/admin/${location.center_manager[key].uid}`}
                    >
                      <ListItemAvatar>
                        <Avatar src={location.center_manager[key].image} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={location.center_manager[key].name}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography component="p" color="textSecondary">
                  There is no assigned Area Sales Manager
                </Typography>
              )}
              <Divider className="spacer" />
              <Typography variant="h6">Settings</Typography>
              <Tooltip title="Toggle to allow clients to start booking private offices">
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  control={
                    <Switch
                      checked={location.access_pass_enabled}
                      onChange={this.toggleAccessPass}
                      value="access-pass-enabled"
                    />
                  }
                  label="Enable Access Pass"
                  labelPlacement="start"
                />
              </Tooltip>
            </Grid>
            <Grid xs={12} md={8} lg={9} item>
              <RoomList toggleUpdateRoom={this.toggleUpdateRoom} />
            </Grid>
          </Grid>
        </div>
        {this.state.updateOffice ? (
          <AddLocation
            imagePreviewUrl={location.image}
            updateLocation={true}
            updateLocationID={this.props.match.params.id}
            modalTitle={`Update ${location.name}`}
            locationData={location}
            toggleOpen={this.toggleUpdateOffice}
            open={this.state.updateOffice}
          />
        ) : null}
        {this.state.createRoom ? (
          <AddRoom
            toggleOpen={this.toggleCreateRoom}
            open={this.state.createRoom}
            editRoom={this.state.editRoom}
          />
        ) : null}
      </div>
    )
  }
}

export default compose(
  firestoreConnect(),
  connect((state, props) => ({
    locations: state.firestore.data.locations,
    profile: state.firebase.profile,
  })),
  withRouter,
  withSnackbar
)(OfficeDetail)
