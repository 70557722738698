import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { NavLink, Route, withRouter } from 'react-router-dom'

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Hidden,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  CircularProgress,
  Avatar,
  Menu,
  MenuItem,
  withMobileDialog,
  withStyles,
  Dialog,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import EditProfile from '../views/home/components/EditProfile'

const routes = [
  {
    path: '/overview',
    exact: true,
    title: 'Overview',
    icon: 'home',
  },
  {
    path: '/locations',
    exact: true,
    title: 'Locations',
    icon: 'location_city',
  },
  {
    path: '/bookings',
    exact: true,
    title: 'Bookings',
    icon: 'import_contacts',
    routes: [
      {
        path: '/bookings-hour',
        exact: true,
        title: 'Conference Rooms',
        icon: 'schedule',
      },
      {
        path: '/bookings-private',
        exact: true,
        title: 'Private Offices',
        icon: 'calendar_today',
      },
    ],
  },
  {
    path: '/clients',
    exact: true,
    title: 'Clients',
    icon: 'people',
  },
  {
    path: '/community',
    exact: true,
    title: 'Community',
    icon: 'mood',
  },
  {
    path: '/resources',
    exact: true,
    title: 'Resources',
    icon: 'folder',
  },
  {
    path: '/reports',
    exact: true,
    title: 'Reports',
    icon: 'insert_chart',
    routes: [
      {
        path: '/reports-clients',
        exact: true,
        title: 'Clients',
        icon: 'assignment_ind',
      },
      {
        path: '/reports-bookings',
        exact: true,
        title: 'Conference Rooms',
        icon: 'schedule',
      },
      {
        path: '/reports-privateoffices',
        exact: true,
        title: 'Private Offices',
        icon: 'calendar_today',
      },
    ],
  },
  {
    path: '/admin',
    exact: true,
    title: 'Admin',
    icon: 'security',
  },
  {
    path: '/honor-market',
    exact: true,
    title: 'Honor Market',
    icon: 'storefront',
  },
  // {
  //   path: '/change-log',
  //   exact: true,
  //   title: 'Change Log',
  //   icon: 'notification_important',
  //   color: 'secondary'
  // }
]

const drawerWidth = 220

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 8,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  backButton: {
    marginRight: 8,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
})

class AppWrapper extends Component {
  state = {
    mobileOpen: false,
    anchorEl: null,
    editUser: false,
  }
  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }))
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  logout = () => {
    this.handleClose()
    this.props.firebase.logout()
  }
  toggleEditUser = () => {
    this.handleClose()
    this.setState({
      editUser: !this.state.editUser,
    })
  }
  componentDidUpdate() {
    if (this.props.profile) {
      const path = this.props.location.pathname.split('/')
      if (
        this.props.profile.role !== 'admin' &&
        path.length > 2 &&
        path[1] !== 'clients'
      ) {
        const location =
          this.props.profile.locations &&
          Object.keys(this.props.profile.locations).filter(
            (loc) => loc === path[2]
          )
        if (location && !location.length) {
          this.props.history.replace(`/${path[1]}`)
        }
      }
    }
  }
  render() {
    if (!isLoaded(this.props.locations)) return <CircularProgress />
    const { classes, theme } = this.props
    const { anchorEl } = this.state
    const { fullScreen } = this.props

    const drawer = (
      <div className="sidebar">
        {isLoaded(this.props.profile) ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '24px 0 16px',
            }}
          >
            <Avatar
              style={{ width: 80, height: 80 }}
              src={this.props.profile.image}
            />
            <Typography style={{ marginTop: 16 }} variant="h6" component="h6">
              {this.props.profile.first_name} {this.props.profile.last_name}
            </Typography>
            <Typography
              style={{ marginBottom: 16 }}
              variant="body2"
              component="p"
            >
              {this.props.profile.job_title}
            </Typography>
          </div>
        ) : (
          <CircularProgress />
        )}
        <Divider />
        <List>
          {routes.map((route, i) => {
            if (
              (route.path === '/admin' ||
                route.path === '/honor-market' ||
                route.path === '/locations') &&
              this.props.profile.role !== 'admin'
            )
             {
              return null
            }
            // Below allows for a list of roles to access /resources, edit freely
            if (
              (route.path === '/resources' &&
                this.props.profile.role !== 'admin' &&
                this.props.profile.role !== 'regional_manager' &&
                this.props.profile.role !== 'community_manager'
            ))
             {
              return null
            }
            else {
              const hasRoutes =
                route.routes && route.routes.length && route.routes[0].path
              return (
                <React.Fragment key={i}>
                  <ListItem
                    onClick={this.handleDrawerToggle}
                    component={hasRoutes ? null : NavLink}
                    to={hasRoutes ? route.routes[0].path : route.path}
                    button={hasRoutes ? false : true}
                  >
                    <ListItemIcon style={{ marginRight: 0 }}>
                      <Icon color={route.color ? route.color : 'inherit'}>
                        {route.icon}
                      </Icon>
                    </ListItemIcon>
                    <ListItemText primary={route.title} />
                    {hasRoutes ? (
                      <ListItemIcon style={{ marginRight: 0 }}>
                        <Icon color={route.color ? route.color : 'inherit'}>
                          expand_more
                        </Icon>
                      </ListItemIcon>
                    ) : null}
                  </ListItem>
                  {hasRoutes ? (
                    <React.Fragment key={`${i}-nested`}>
                      <List
                        disablePadding
                        style={{ backgroundColor: '#fafafa' }}
                      >
                        {route.routes.map((r) => (
                          <ListItem
                            style={{ paddingLeft: 40 }}
                            onClick={this.handleDrawerToggle}
                            component={NavLink}
                            to={r.path}
                            key={r.path}
                            button
                          >
                            <ListItemIcon style={{ marginRight: 0 }}>
                              <Icon color={r.color ? r.color : 'inherit'}>
                                {r.icon}
                              </Icon>
                            </ListItemIcon>
                            <ListItemText primary={r.title} />
                          </ListItem>
                        ))}
                      </List>
                      <Divider />
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )
            }
          })}
        </List>
      </div>
    )

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              style={{ flexGrow: 1 }}
            >
              {routes.map((route, index) => {
                if (route.routes) {
                  return route.routes.map((r, i) => {
                    return (
                      <Route
                        key={`${index}-${i}`}
                        path={r.path}
                        component={(props) => {
                          const location =
                            this.props.locations &&
                            this.props.locations.filter(
                              (loc) =>
                                loc.id === props.location.pathname.split('/')[2]
                            )
                          return (
                            <React.Fragment>
                              {route.title} / {r.title}
                              {location &&
                              location.length &&
                              props.location &&
                              props.location.pathname.split('/').length > 2 &&
                              !props.location.pathname.includes('admin')
                                ? ` / ${location[0].name}`
                                : null}
                            </React.Fragment>
                          )
                        }}
                      />
                    )
                  })
                } else {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      component={(props) => {
                        const location =
                          this.props.locations &&
                          this.props.locations.filter(
                            (loc) =>
                              loc.id === props.location.pathname.split('/')[2]
                          )
                        return (
                          <React.Fragment>
                            {route.title}
                            {location &&
                            location.length &&
                            props.location &&
                            props.location.pathname.split('/').length > 2 &&
                            !props.location.pathname.includes('admin')
                              ? ` / ${location[0].name}`
                              : null}
                          </React.Fragment>
                        )
                      }}
                    />
                  )
                }
              })}
            </Typography>
            <IconButton
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
              color="inherit"
            >
              <Icon>more_vert</Icon>
            </IconButton>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.toggleEditUser}>Edit Profile</MenuItem>
              <MenuItem onClick={this.logout}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden mdUp>
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
        <Dialog
          maxWidth="sm"
          fullScreen={fullScreen}
          fullWidth
          className="add-user-modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.editUser}
        >
          <EditProfile
            handleClose={this.toggleEditUser}
            user={this.props.profile}
            profileId={this.props.auth.uid}
            label="Edit Profile"
          />
        </Dialog>
      </div>
    )
  }
}

export default compose(
  withRouter,
  withMobileDialog(),
  withStyles(styles, { withTheme: true }),
  firestoreConnect((props) => {
    return [{ collection: 'locations', orderBy: 'state' }]
  }),
  connect((state) => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      locations: state.firestore.ordered.locations,
    }
  })
)(AppWrapper)
