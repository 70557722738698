/* @flow */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import StyleButton from './StyleButton'

export default class LayoutComponent extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    doExpand: PropTypes.func,
    doCollapse: PropTypes.func,
    onExpandEvent: PropTypes.func,
    config: PropTypes.object,
    onChange: PropTypes.func,
    currentState: PropTypes.object,
    translations: PropTypes.object,
    indentDisabled: PropTypes.bool,
    outdentDisabled: PropTypes.bool
  }

  options = ['unordered', 'ordered', 'indent', 'outdent']

  toggleBlockType = blockType => {
    const { onChange } = this.props
    onChange(blockType)
  }

  indent = () => {
    const { onChange } = this.props
    onChange('indent')
  }

  outdent = () => {
    const { onChange } = this.props
    onChange('outdent')
  }

  // todo: evaluate refactoring this code to put a loop there and in other places also in code
  // hint: it will require moving click handlers
  renderInFlatList() {
    const {
      config,
      currentState: { listType },
      translations
    } = this.props
    const { options, unordered, ordered } = config
    return (
      <div className="rdw-inline-wrapper" aria-label="rdw-list-control">
        {options.indexOf('unordered') >= 0 && (
          <StyleButton
            value="unordered"
            icon={`format_list_bulleted`}
            active={listType === 'unordered'}
            onClick={this.toggleBlockType}
            label={unordered.title || translations['components.controls.list.unordered']}
          />
        )}
        {options.indexOf('ordered') >= 0 && (
          <StyleButton
            value="ordered"
            icon={`format_list_numbered`}
            active={listType === 'ordered'}
            onClick={this.toggleBlockType}
            label={ordered.title || translations['components.controls.list.ordered']}
          />
        )}
      </div>
    )
  }

  render() {
    return this.renderInFlatList()
  }
}
