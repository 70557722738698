import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withMobileDialog } from '@material-ui/core'

import {
  Link,
  Grid,
  Typography,
} from '@material-ui/core'

import {
  Editor,
  EditorState,
  convertFromRaw
} from 'draft-js';

import IconGetApp from '@material-ui/icons/GetApp'
import PublicResourcesHeader from './PublicResourcesHeader'
import LogoLight from '../../../media/logo-light.svg'

class PublicResourcesDetail extends Component {
  state = {
    maxWidth: 580,
    resource: {},
  }

  componentDidMount() {
    this.mounted = true
    this.getData()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  getData = () => {
    this.setState({ resource: {} })
    let query = this.props.firestore
      .collection('resources')
      .doc(this.props.match.params.resourceId)
      .get()
      .then((snapshot) => {
        if (this.mounted && !snapshot.empty) {
          this.setState({
            resource: {
              id: snapshot.id,
              ...snapshot.data(),
            }
          })
        } else {
          this.setState({ resource: {}, })
        }
      })
  }

  render() {
    let contentFormatted = (
      <div
        elevation={0}
        />
      )

    if (this.state.resource && this.state.resource.the_content) {
      const content = this.state.resource.the_content
      const editorState = EditorState.createWithContent(
        convertFromRaw(content)
      )
      contentFormatted = (
        <Editor
          editorState={editorState}
          readOnly={true}
          />
        )
    }

    return (
      <div className="resources-public">
        <PublicResourcesHeader
          title={this.state.resource.title ? this.state.resource.title : '\u00a0'}
          titleFontSize="h5"
          maxWidth={this.state.maxWidth}
          goBackRoute={this.props.match}
          />
        <Grid
          container
          style={{
            margin: '0 auto',
            marginTop: 4,
            padding: '0 16px 16px 16px',
            width: '100%',
            maxWidth: this.state.maxWidth,
          }}
          >
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              fontSize: 14,
              color: '#000000',
            }}
            >
            {contentFormatted}
            {this.state.resource.attachment && (
              <PublicResourcesDetailDownloadAttachment
                file={this.state.resource.attachment} />
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

class PublicResourcesDetailDownloadAttachment extends Component {
  render() {
    // TODO Certainly a better way than explicitly declaring styles for each child
    return (
      <Link
        download
        href={`${this.props.file.url}`}
        style={{
          backgroundColor: '#005a7d',
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '8px 24px 8px 16px',
          borderRadius: 5,
          cursor: 'pointer',
          textDecoration: 'none',
        }}
        >
        <IconGetApp
          style={{
            color: 'rgba(255, 255, 255, 0.75)',
            marginRight: 12
          }}
          />
        <div>
          <Typography
            style={{
              color: '#ffffff',
            }}
            >
            Download Attachment
          </Typography>
          <Typography
            style={{
              color: 'rgba(255, 255, 255, 0.75)',
              fontSize: 10,
            }}
            >
            {this.props.file.label}
          </Typography>
        </div>
      </Link>
    )
  }
}

export default compose(
  withMobileDialog(),
  firestoreConnect(),
  connect((state) => ({
      auth: state.firebase.auth,
      profile: state.firebase.profile,
  })),
)(PublicResourcesDetail)
