import React, { PureComponent } from 'react'
import { firestoreConnect } from 'react-redux-firebase'
import moment from 'moment'
import {
  Button,
  Icon,
  LinearProgress,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core'

class ReportItem extends PureComponent {
  constructor(props) {
    super(props)
    let state = {
      urlSet: false,
      download: null,
      processing: false,
      report: props.report,
      detailOpen: false,
      arrowRef: null,
    }
    if (props.processing) state.processing = true

    this.state = state
  }

  componentDidMount() {
    this.mounted = true
    if (this.state.processing) this.listener()
  }

  listener = () => {
    this.unsubscribe = this.props.firestore
      .collection('reports')
      .doc(this.state.report.id)
      .onSnapshot(snap => {
        const data = snap.data()
        if (!data.generating) {
          if (this.mounted) {
            this.setState(
              {
                processing: false,
                report: { id: snap.id, ...data }
              },
              () => {
                this.unsubscribe()
              }
            )
          }
        }
      })
  }

  componentWillUnmount() {
    this.mounted = false
    this.unsubscribe && this.unsubscribe()
  }

  getDownload = () => {
    if (this.state.report.generating || this.state.urlSet) return
    if (this.state.report.download === null) return
    this.props.firebase
      .storage()
      .ref()
      .child(this.state.report.download)
      .getDownloadURL()
      .then(url => {
        if (this.mounted) {
          this.setState({
            urlSet: false,
            download: url,
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleArrowRef = node => {
    this.setState({
      arrowRef: node
    })
  }

  render() {
    const { report } = this.state

    if (this.state.processing) {
      return (
        <TableRow>
          <TableCell>{moment(report.created_on).format('MMMM D, YYYY')}</TableCell>
          <TableCell colSpan="5">
            <LinearProgress style={{ flex: 1 }} />
          </TableCell>
        </TableRow>
      )
    } else {
      if (!report.generating && !this.state.urlSet) {
        this.getDownload()
      }
    }

    return (
      <React.Fragment>
        <TableRow
        // hover
        // onClick={() => this.setState({ detailOpen: !this.state.detailOpen })}
        // style={{ cursor: 'pointer' }}
        >
          <TableCell>{moment(report.created_on.toDate()).format('MMMM D, YYYY')}</TableCell>
          {report.generating ? (
            <TableCell colSpan="4">
              <LinearProgress style={{ flex: 1 }} />
            </TableCell>
          ) : (
            <React.Fragment>
              <TableCell>{report.total_clients}</TableCell>
              <TableCell>
                <Tooltip
                  title={
                    <div style={{ position: 'relative' }}>
                      {report.locations.map((loc, i) => (
                        <span
                          style={{ fontSize: 12, display: 'inline-block', whiteSpace: 'nowrap', marginRight: 3 }}
                          variant="outlined"
                          key={loc.id}
                        >
                          {loc.name}
                          {i < report.locations.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                      <span
                        style={{
                          position: 'absolute',
                          left: '50%',
                          marginLeft: '-5px',
                          top: '-9px',
                          width: 0,
                          height: 0,
                          borderLeft: '5px solid transparent',
                          borderRight: '5px solid transparent',
                          borderBottom: '5px solid rgba(0,0,0,0.65)'
                        }}
                      />
                    </div>
                  }
                >
                  <div style={{ display: 'inline-flex', verticalAlign: 'middle', lineHeight: '20px' }}>
                    <b>{report.locations.length}</b>
                    <span style={{ display: 'inline-block', marginLeft: 4, marginRight: 4 }}>
                      Location{report.locations.length > 1 ? 's' : ''}
                    </span>
                    <Icon fontSize="small">info_outlined</Icon>
                  </div>
                </Tooltip>
                {/* <div
                  style={{
                    width: '100%',
                    maxWidth: 470,
                    overflow: 'hidden'
                  }}
                >
                  <div style={{ transform: 'translateZ(0)', width: '100%', overflowY: 'auto' }}>
                    {report.locations.map(loc => (
                      <span style={{ fontSize: 10, marginRight: 8 }} variant="outlined" key={loc.id}>
                        {loc.name}
                      </span>
                    ))}
                  </div>
                </div> */}
              </TableCell>
              <TableCell>{report.includeDisabledProfiles ? 'Y' : 'N'}</TableCell>
              <TableCell align="right">
                {this.state.download ? (
                  <Button
                    onClick={e => e.stopPropagation()}
                    href={this.state.download}
                    download={true}
                    alt="Download"
                    size="small"
                    color="default"
                  >
                    <Icon>cloud_download</Icon>
                  </Button>
                ) : (
                  'No data found'
                )}
              </TableCell>
            </React.Fragment>
          )}
        </TableRow>
        {/* <TableRow style={{ minHeight: 0, padding: 0, height: 'auto' }}>
          <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} colSpan={6}>
            <Collapse in={this.state.detailOpen}>
            </Collapse>
          </TableCell>
        </TableRow> */}
      </React.Fragment>
    )
  }
}

export default firestoreConnect()(ReportItem)
