import React from 'react'
import {
  Grid,
  Divider,
  Paper,
  Button,
  List,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { compose } from 'redux'
import orderBy from 'lodash/orderBy'
import { withSnackbar } from 'notistack'
import CategoryItem from './components/CategoryItem'
class Categories extends React.Component {
  state = { open: false, title: '', submitting: false }
  handleClickOpen = () => {
    this.setState({ open: true, title: '', submitting: false })
  }

  handleClose = () => {
    this.setState({ open: false, title: '', submitting: false })
  }
  createCategory = async (e) => {
    e.preventDefault()
    if (this.state.submitting) return true
    this.setState(
      {
        submitting: true,
      },
      () => {
        let doc = this.props.firestore.collection('honorMarket').doc()
        let payload = {
          title: this.state.title,
          order: (this.props.categories && this.props.categories.length) || 0,
          type: 'category',
        }
        console.log('create category')
        doc
          .set(payload)
          .then(() => {
            this.handleClose()
            this.props.enqueueSnackbar(`Category created.`, {
              variant: 'success',
            })
          })
          .catch((err) => {
            console.log(err)
            this.props.enqueueSnackbar(
              `There was an issue creating a category. `,
              {
                variant: 'error',
              }
            )
          })
      }
    )
  }
  render() {
    return (
      <React.Fragment>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Button
                variant="outlined"
                color="default"
                onClick={this.handleClickOpen}
              >
                Add new category
              </Button>
            </div>
            <Divider style={{ marginTop: 8 }} />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <List>
                {this.props.categories
                  ? orderBy(
                      this.props.categories,
                      ['order'],
                      ['asc']
                    ).map((cat, i) => (
                      <CategoryItem
                        index={i}
                        key={cat.id}
                        category={cat}
                        max={this.props.categories.length}
                        firestore={this.props.firestore}
                      />
                    ))
                  : 'No Categories Created'}
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <form autoComplete={'off'} onSubmit={this.createCategory}>
            <input type="hidden" autoComplete={'false'} />
            <DialogTitle id="form-dialog-title">
              Create a new category
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Use categories to sort your goods (snacks, drinks, apparel,
                etc...)
              </DialogContentText>
              <TextField
                required
                autoFocus
                margin="dense"
                id="name"
                label="Category Name"
                type="text"
                fullWidth
                value={this.state.title}
                onChange={(e) => {
                  this.setState({
                    title: e.target.value,
                  })
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Create
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(withSnackbar)(Categories)
