import React, { Component } from 'react'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { IconButton, Typography, Icon, DialogContent, Button, DialogActions, InputBase, Paper } from '@material-ui/core'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import MuiDialogTitle from '@material-ui/core/DialogTitle'

import BlockType from './BlockType'
import InlineType from './InlineType'
import ListType from './ListType'
import LinkType from './LinkType'
import AttachImage from './AttachImage'
import IconPicker from './IconPicker/'

import LoadingSplash from '../../../components/LoadingSplash'

class CreateResource extends Component {
  constructor(props) {
    super(props)
    var state = {
      featured_image: null,
      editorState: EditorState.createEmpty(),
      attachment: null,
      title: '',
      saving: false,
      edited: false,
      icon: null
    }
    const res = props.editResource
    if (res) {
      state.featured_image = res.featured_image
      state.title = res.title
      state.attachment = res.attachment
      state.editorState = EditorState.createWithContent(convertFromRaw(res.the_content))
      state.icon = res.icon ? res.icon : null
    }

    this.state = state
  }
  onChange = editorState => {
    this.setState({
      editorState,
      edited: true
    })
  }
  savePost = () => {
    if (this.state.title.length === 0) {
      this.title.focus()
      return this.props.enqueueSnackbar(`You need to add a title`, {
        variant: 'error'
      })
    }
    this.setState(
      {
        saving: true
      },
      () => {
        const the_content = convertToRaw(this.state.editorState.getCurrentContent())
        if (this.props.editResource) {
          this.props.firestore
            .collection('resources')
            .doc(this.props.editResource.id)
            .update({
              title: this.state.title,
              the_content,
              featured_image: this.state.featured_image,
              attachment: this.state.attachment,
              last_edited: new Date(),
              icon: this.state.icon
            })
            .then(docRef => {
              this.props.createPost(true)
            })
        } else {
          this.props.firestore
            .collection('resources')
            .add({
              enabled: false,
              created_on: new Date(),
              title: this.state.title,
              the_content,
              featured_image: this.state.featured_image,
              attachment: this.state.attachment,
              location: this.props.location,
              last_edited: new Date(),
              order: 0,
              icon: this.state.icon
            })
            .then(docRef => {
              this.props.createPost(true)
            })
        }
      }
    )
  }
  updateImage = featured_image => {
    this.setState({
      featured_image,
      edited: true
    })
  }
  updateAttachment = attachment => {
    this.setState({
      attachment,
      edited: true
    })
  }
  updateIcon = icon => {
    this.setState({
      icon
    })
  }
  deleteResource = () => {
    const confirm = window.confirm('Are you sure you want to delete this resource?')
    const post = this.props.editResource
    if (confirm) {
      if (this.state.attachment) {
        this.props.firebase
          .storage()
          .ref()
          .child(this.state.attachment.path)
          .delete()
          .then(() => {
            this.deletePost(post)
          })
      } else {
        this.deletePost(post)
      }
    }
  }
  deletePost = post => {
    this.props.firestore
      .collection('resources')
      .doc(this.props.editResource.id)
      .delete()
      .then(() => {
        this.props.enqueueSnackbar(`'${post.title}' was deleted.`)
        this.props.createPost(true)
      })
  }
  render() {
    return (
      <React.Fragment>
        <MuiDialogTitle disableTypography className="booking-modal-title">
          <Typography variant="h5">{this.props.title}</Typography>
          <IconButton
            onClick={() => this.props.createPost(!this.state.edited)}
            className="close-button"
            aria-label="Close"
          >
            <Icon>close</Icon>
          </IconButton>
        </MuiDialogTitle>
        <DialogContent style={{ paddingTop: 5, height: 'calc(100% - 95px)' }}>
          <div style={{ marginBottom: 24 }}>
            <InputBase
              inputRef={ref => (this.title = ref)}
              variant="outlined"
              fullWidth
              autoFocus
              style={{ fontSize: 32, fontWeight: '500' }}
              placeholder="Resource Title"
              value={this.state.title}
              onChange={e =>
                this.setState({
                  title: e.target.value,
                  edited: true
                })
              }
            />
          </div>
          <Paper elevation={1} className="RichEditor-root">
            <Editor
              stripPastedStyles
              placeholder="Resource Content..."
              editorState={this.state.editorState}
              wrapperClassName="editor-wrapper"
              editorClassName="editor-content"
              onEditorStateChange={this.onChange}
              toolbar={{
                options: ['inline', 'blockType', 'list', 'link'],
                inline: {
                  component: InlineType,
                  inDropdown: false,
                  options: ['bold', 'italic', 'underline']
                },
                blockType: {
                  component: BlockType,
                  inDropdown: false,
                  options: ['Normal', 'H2', 'H3']
                },
                list: {
                  component: ListType,
                  inDropdown: false,
                  options: ['unordered', 'ordered']
                },
                link: {
                  component: LinkType,
                  inDropdown: false
                }
              }}
            />
          </Paper>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <AttachImage
              selectedImage={this.state.featured_image}
              attachment={this.state.attachment}
              updateImage={this.updateImage}
              updateAttachment={this.updateAttachment}
            />
            <IconPicker updateIcon={this.updateIcon} icon={this.state.icon} />
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', flex: 1 }}>
            {this.props.editResource && (
              <Button
                onClick={this.deleteResource}
                disabled={this.state.saving}
                type="submit"
                size="large"
                color="secondary"
              >
                Delete Resource
              </Button>
            )}
          </div>
          <Button onClick={this.savePost} disabled={this.state.saving} type="submit" size="large" color="primary">
            {this.props.button}
          </Button>
        </DialogActions>
        {this.state.saving && (
          <LoadingSplash
            label={`${this.props.button === 'Create Resource' ? 'Creating Resource...' : 'Updating Resource...'}`}
          />
        )}
      </React.Fragment>
    )
  }
}

CreateResource.defaultProps = {
  title: 'New Resource',
  button: 'Create Resource'
}

export default compose(
  firestoreConnect(),
  connect(state => ({
    profile: state.firebase.profile,
    auth: state.firebase.auth
  })),
  withSnackbar
)(CreateResource)
