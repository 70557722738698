import React, { PureComponent } from 'react'
import { TableRow, TableCell, InputBase, Icon, Switch, Typography, CircularProgress } from '@material-ui/core'
import { firestoreConnect } from 'react-redux-firebase'
import moment from 'moment'
import _ from 'lodash'
class ResourceItem extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { updating: false, order: props.resource.order }
  }
  toggle = () => {
    if (this.state.updating) return false
    this.setState(
      {
        updating: true
      },
      () => {
        this.props.firestore
          .collection('resources')
          .doc(this.props.resource.id)
          .update({
            enabled: !this.props.resource.enabled
          })
          .then(() => {
            this.setState({
              updating: false
            })
          })
      }
    )
  }
  updateOrder = _.debounce(() => {
    this.setState(
      {
        updating: true
      },
      () => {
        this.props.firestore
          .collection('resources')
          .doc(this.props.resource.id)
          .update({
            order: Number(this.state.order)
          })
          .then(() => {
            this.setState({
              updating: false
            })
          })
      }
    )
  }, 3000)
  render() {
    const { resource } = this.props
    return (
      <TableRow hover>
        <TableCell style={{ position: 'relative' }}>
          <InputBase
            className={`input-base-order ${isNaN(this.state.order) ? 'invalid' : ''}`}
            margin="none"
            value={this.state.order}
            onChange={e => {
              this.setState(
                {
                  order: e.target.value === '' ? 0 : Number(e.target.value),
                  updating: true
                },
                this.updateOrder
              )
            }}
            variant="outlined"
            fullWidth
          />
          {this.state.updating ? (
            <div style={{ position: 'absolute', left: 5, top: 20 }}>
              <CircularProgress size={14} thickness={6} />
            </div>
          ) : null}
        </TableCell>
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {resource.icon && (
              <img src={resource.icon} alt="" style={{ width: 24, height: 24, marginRight: 16, opacity: 0.65 }} />
            )}
            <Typography color="primary" onClick={() => this.props.edit(resource)} style={{ cursor: 'pointer' }}>
              {resource.title}
            </Typography>
          </div>
        </TableCell>
        <TableCell>{moment.unix(resource.created_on.seconds).format('LLL')}</TableCell>
        <TableCell align="right">
          {resource.featured_image ? (
            <a href={resource.featured_image} rel="noopener noreferrer" target="_blank" style={{ marginRight: 8 }}>
              <Icon color="primary">insert_photo</Icon>
            </a>
          ) : null}
          {resource.attachment ? (
            <a href={resource.attachment.url} rel="noopener noreferrer" target="_blank" style={{ marginRight: 8 }}>
              <Icon color="primary">attachment</Icon>
            </a>
          ) : null}
        </TableCell>
        <TableCell align="right">
          <Switch onClick={this.toggle} checked={resource.enabled} />
        </TableCell>
      </TableRow>
    )
  }
}

export default firestoreConnect()(ResourceItem)
