import React, { Component } from 'react'
import Fade from '@material-ui/core/Fade'
import Moment from 'moment-timezone'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import {
  Typography,
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Toolbar,
  AppBar,
} from '@material-ui/core'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

class Step3 extends Component {
  getAvailableSlots = () => {
    const month = moment(this.props.activeDate, 'YYYYMMDD')
    console.log(month.format())
    const first_day = month.startOf('month').format('d')
    const last_day = month.endOf('month').format('d')
    const weekdays = moment.weekdaysShort()
    const days = month.daysInMonth()
    let blanks = []
    for (let i = 0; i < first_day; i++) {
      blanks.push(<TableCell key={i} />)
    }
    let daysInMonth = []
    for (let d = 1; d <= days; d++) {
      daysInMonth.push(
        <TableCell align={'center'} key={`${this.props.activeDate}-${d}`}>
          <Button
            size={'large'}
            fullWidth
            disableRipple
            disabled={
              Number(month.clone().set('date', d).format('YYYYMMDD')) <
                Number(moment().format('YYYYMMDD')) ||
              this.isDateBlocked(month.clone().set('date', d))
            }
            onClick={() =>
              this.props.changeTimeValue(month.clone().set('date', d), 'date')
            }
            variant={
              this.props.booking.date ===
              Number(month.clone().set('date', d).format('YYYYMMDD'))
                ? 'contained'
                : this.isDateBlocked(month.clone().set('date', d))
                ? 'contained'
                : Number(month.clone().set('date', d).format('YYYYMMDD')) ===
                  Number(moment().format('YYYYMMDD'))
                ? 'outlined'
                : 'text'
            }
            color={
              this.props.booking.date ===
              Number(month.clone().set('date', d).format('YYYYMMDD'))
                ? 'primary'
                : Number(month.clone().set('date', d).format('YYYYMMDD')) ===
                  Number(moment().format('YYYYMMDD'))
                ? 'primary'
                : 'default'
            }
          >
            {d}
          </Button>
        </TableCell>
      )
    }
    var totalSlots = [...blanks, ...daysInMonth]
    let rows = []
    let cells = []
    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row) // if index not equal 7 that means not go to next week
      } else {
        rows.push(cells) // when reach next week we contain all td in last week to rows
        cells = [] // empty container
        cells.push(row) // in current loop we still push current row to new container
      }
      if (i === totalSlots.length - 1) {
        // when end loop we add remain date
        rows.push(cells)
      }
    })
    let daysinmonth = rows.map((d, i) => {
      return <TableRow key={`${d}-${i}`}>{d}</TableRow>
    })
    return (
      <Paper className="calendar-grid">
        <AppBar color="inherit" elevation={1} position={'static'}>
          <Toolbar>
            <Typography
              color="inherit"
              variant="h6"
              style={{ textAlign: 'left', flex: 1 }}
            >
              {month.format('MMMM YYYY')}
            </Typography>
            <div className="controls">
              <Button
                onClick={() => {
                  this.props.changeTimeValue(undefined, 'date')
                  this.props.changeActiveDate(
                    month.clone().subtract('month', 1)
                  )
                }}
              >
                Prev
              </Button>
              <Button
                onClick={() => {
                  this.props.changeTimeValue(undefined, 'date')
                  this.props.changeActiveDate(month.clone().add('month', 1))
                }}
              >
                Next
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <Table padding={'none'}>
          <TableHead>
            <TableRow>
              {weekdays.map((day) => (
                <TableCell align={'center'} key={day}>
                  {day}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{daysinmonth}</TableBody>
        </Table>
      </Paper>
    )
  }
  selectObj = (opt) => {
    this.props.selectTimeSlot(opt)
  }
  isDateBlocked = (e) => {
    let skip = false
    const date = Number(e.format('YYYYMMDD'))
    this.props.dayBookings.forEach((booking) => {
      if (booking.date === date) {
        skip = true
      }
    })
    return skip
  }
  isOutsideRange = (e) => {
    let skip = false
    const date = Number(e.format('YYYYMMDD'))
    if (date < Number(moment().format('YYYYMMDD'))) {
      skip = true
    }
    return skip
  }
  render() {
    if (this.props.active && this.props.dayBookings) {
      return (
        <Fade in={this.props.active}>
          <div className="step-3">
            <Grid spacing={16} container>
              <Grid xs={12} item style={{ marginTop: 16 }}>
                <Typography
                  gutterBottom
                  component="h6"
                  variant="body1"
                  style={{ fontWeight: '500', marginBottom: 16 }}
                >
                  Select a date
                </Typography>
              </Grid>
              <Grid
                item
                id="datepicker-container"
                className="mobile-calendar"
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: '0',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {this.getAvailableSlots()}
              </Grid>
            </Grid>
          </div>
        </Fade>
      )
    }
    return null
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: 'dayBookings',
      where: [
        ['location.id', '==', props.booking.location.id],
        ['room.uid', '==', props.booking.room.uid],
        [
          'date',
          '>=',
          Number(
            moment(props.activeDate, 'YYYYMMDD')
              .startOf('month')
              .format('YYYYMMDD')
          ),
        ],
        [
          'date',
          '<=',
          Number(
            moment(props.activeDate, 'YYYYMMDD')
              .endOf('month')
              .format('YYYYMMDD')
          ),
        ],
        ['canceled', '==', false],
      ],
    },
  ]),
  connect((state) => {
    return {
      dayBookings: state.firestore.ordered.dayBookings,
    }
  })
)(Step3)
