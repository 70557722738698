import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  Checkbox,
  Paper,
  InputBase,
  List,
  ListItem,
  Divider,
  Typography
} from '@material-ui/core'

import { connectRefinementList } from 'react-instantsearch-dom'

class CustomLocationRefinement extends Component {
  constructor(props) {
    super(props)
    let location_data = props.locations
    if (props.profile.role !== 'admin') {
      location_data = _.filter(props.locations, loc => {
        return props.profile.locations && props.profile.locations[loc.id]
      })
    }
    console.log(location_data)
    this.state = {
      location_data,
      locations: [],
      query: '',
      mobile_open: false
    }
  }
  filterLocations = query => {
    this.setState({ query })
  }
  render() {
    const { items, isFromSearch, refine, createURL } = this.props

    return (
      <div className="refinement location-refinement">
        <Typography gutterBottom variant="h6">
          Locations
        </Typography>
        <Paper elevation={0} className="search-bar-locations">
          <InputBase
            fullWidth
            placeholder="Search Locations"
            type="search"
            onChange={event => this.filterLocations(event.currentTarget.value)}
          />
        </Paper>
        <List
          className="force-scrollbars"
          style={{ height: 260, overflow: 'auto' }}
        >
          {_.orderBy(
            this.state.location_data,
            ['state', 'city'],
            ['asc', 'asc']
          )
            .filter(loc => {
              if (this.state.query) {
                return (
                  loc.name
                    .toUpperCase()
                    .indexOf(this.state.query.toUpperCase()) > -1 ||
                  loc.state
                    .toUpperCase()
                    .indexOf(this.state.query.toUpperCase()) > -1 ||
                  loc.city
                    .toUpperCase()
                    .indexOf(this.state.query.toUpperCase()) > -1 ||
                  this.state.locations.filter(l => l.id === loc.id).length > 0
                )
              } else {
                return true
              }
            })
            .map(locData => {
              let location = items.filter(l => {
                return l.label === locData.id
              })
              if (location.length) {
                const item = location[0]
                return (
                  <ListItem
                    dense
                    key={item.label}
                    onClick={() => refine(item.value)}
                    button
                    disableRipple
                    disableGutters
                  >
                    <Checkbox
                      value={item.label}
                      disableRipple
                      checked={item.isRefined}
                      style={{
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 0,
                        paddingRight: 6
                      }}
                      color="primary"
                    />
                    <Typography
                      style={{ lineHeight: '1.2' }}
                      variant={'caption'}
                    >{`${locData.name}, ${locData.state}`}</Typography>
                    <div className="count-pill">
                      <Typography variant={'caption'}>{item.count}</Typography>
                    </div>
                  </ListItem>
                )
              } else {
                return null
              }
            })}
        </List>
        <Divider />
      </div>
    )
  }
}

export default compose(
  connectRefinementList,
  connect(state => ({
    locations: state.firestore.ordered.locations,
    profile: state.firebase.profile
  }))
)(CustomLocationRefinement)
