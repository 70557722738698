import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import ResourceOverview from './components/ResourceOverview'
import ResourceDetail from './components/ResourceDetail'

class Community extends Component {
  render() {
    return (
      <Switch>
        <Route path="/resources/:location" component={ResourceDetail} />
        <Route exact path="/resources" component={ResourceOverview} />
      </Switch>
    )
  }
}

export default Community
