import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { withSnackbar } from 'notistack'

import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Stepper from '@material-ui/core/Stepper'
import MobileStepper from '@material-ui/core/MobileStepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import Paper from '@material-ui/core/Paper'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step3DayBooking from './Step3DayBooking'
import Step4 from './Step4'
import { CircularProgress } from '@material-ui/core'

import LoadingSplash from '../../../components/LoadingSplash'

class BookingModal extends Component {
  constructor(props) {
    let booking = {
      created_by: {
        uid: props.auth.uid,
        display_name: `${props.profile.first_name} ${props.profile.last_name}`,
      },
      client: null,
      date: Number(moment().format('YYYYMMDD')),
      start_time: '',
      end_time: '',
      room: {
        uid: null,
        display_name: '',
        image: '',
        room: null,
        floor: null,
      },
      notes: '',
      location: {
        id: props.acitveLocationID,
        name: `${props.activeLocation.name}, ${props.activeLocation.state}`,
        address: `${props.activeLocation.address}, ${props.activeLocation.city} ${props.activeLocation.state}, ${props.activeLocation.zip}`,
      },
      canceled: false,
      created_on: props.firebase.firestore.FieldValue.serverTimestamp(),
    }
    if (props.isDaily) {
      delete booking.start_time
      delete booking.end_time
      booking.date = undefined
    }
    super(props)
    console.log(props)
    this.state = {
      open: false,
      updateBooking: false,
      activeStep: 0,
      next: true,
      submitting: false,
      isDaily: props.isDaily,
      activeDate: Number(moment().format('YYYYMMDD')),
      duration: {
        hours: 1,
        minutes: 0,
      },
      booking,
    }
    if (props.booking !== undefined) {
      let duration = moment.duration(
        moment
          .unix(props.booking.end_time.seconds)
          .diff(moment.unix(props.booking.start_time.seconds))
      )
      let booking = Object.assign({}, props.booking)
      this.state.updateBooking = true
      this.state.next = false
      this.state.duration = {
        hours: duration.hours(),
        minutes: duration.minutes(),
      }
      booking.start_time = new this.props.firebase.firestore.Timestamp(
        moment.unix(props.booking.start_time.seconds).unix()
      )
      booking.end_time = new this.props.firebase.firestore.Timestamp(
        moment.unix(props.booking.end_time.seconds).unix()
      )
      this.state.booking = booking
    }
  }
  updateStep = (dir) => {
    this.setState(
      {
        activeStep: this.state.activeStep + dir,
      },
      () => this.disableCheck()
    )
  }

  dismiss = () => {
    const confirm = window.confirm(
      'You will lose your progress if you close this window.'
    )
    if (confirm) {
      this.props.handleClose()
    }
  }

  selectClient = (user) => {
    let booking = Object.assign({}, this.state.booking)
    booking.client = {
      uid: user.objectID,
      display_name: user.name,
      email_address: user.email_address,
      image: user.image,
      company: user.company_id
        ? {
            name: user.company_name,
            id: user.company_id,
          }
        : null,
    }
    this.setState(
      {
        booking,
      },
      () => this.disableCheck()
    )
  }

  changeActiveDate = (e) => {
    this.setState({
      activeDate: Number(e.format('YYYYMMDD')),
    })
  }

  changeTimeValue = (e, key) => {
    let booking = Object.assign({}, this.state.booking)
    let duration = Object.assign({}, this.state.duration)
    if (e === undefined) {
      booking[key] = e
    } else {
      booking[key] = Number(e.format('YYYYMMDD'))
    }
    booking.start_time = ''
    booking.end_time = ''

    this.setState(
      {
        booking,
        duration,
      },
      () => this.disableCheck()
    )
  }

  selectTimeSlot = (slot) => {
    let booking = Object.assign({}, this.state.booking)
    booking.start_time = new this.props.firebase.firestore.Timestamp(
      slot.start_time.unix()
    )
    booking.end_time = new this.props.firebase.firestore.Timestamp(
      slot.end_time.unix()
    )
    this.setState(
      {
        booking,
      },
      () => this.disableCheck()
    )
  }

  changeTimeDuration = (e) => {
    let booking = Object.assign({}, this.state.booking)
    let duration = Object.assign({}, this.state.duration)
    duration[e.target.name] = Number(e.target.value)
    booking.start_time = ''
    booking.end_time = ''
    if (duration.hours === 0 && duration.minutes === 0) {
      duration.minutes = 15
    }

    let payload = {
      booking,
      duration,
    }
    if (this.state.isDaily) {
      delete payload.duration
      delete payload.booking.start_time
      delete payload.booking.end_time
    }

    console.log(payload)

    this.setState(
      {
        ...payload,
      },
      () => this.disableCheck()
    )
  }

  updateTimeDuration = (booking, duration) => {
    return Number(
      moment(booking.start_time)
        .add(duration.hours, 'hours')
        .add(duration.minutes, 'minutes')
        .toDate()
    )
  }

  disableCheck = () => {
    let next = true
    const booking = Object.assign({}, this.state.booking)
    if (this.state.activeStep === 0) {
      if (booking.client !== null) {
        next = false
      }
    }
    if (this.state.activeStep === 1) {
      if (booking.room.uid !== null) {
        next = false
      }
    }
    if (this.state.activeStep === 2) {
      if (booking.start_time !== '' && booking.end_time !== '') {
        next = false
      } else if (this.props.isDaily) {
        next = false
      }
    }
    if (this.state.activeStep === 3) {
      next = false
    }
    this.setState({
      next,
    })
  }

  selectRoom = (room) => {
    let booking = Object.assign({}, this.state.booking)
    booking.room = {
      uid: room.id,
      display_name: room.name,
      image: room.image,
    }
    if (room.floor_number) {
      booking.room.floor_number = room.floor_number
      booking.room.room_number = room.room_number
    }
    this.setState(
      {
        booking,
      },
      () => this.disableCheck()
    )
  }

  leaveNotes = (notes) => {
    let booking = Object.assign({}, this.state.booking)
    booking.notes = notes
    this.setState({
      booking,
    })
  }

  bookRoom = () => {
    const collection = this.props.isDaily ? 'dayBookings' : 'bookings'
    this.setState(
      {
        submitting: true,
      },
      () => {
        if (this.state.updateBooking) {
          this.props.firestore
            .collection(collection)
            .doc(this.props.booking_id)
            .update(this.state.booking)
            .then(() => {
              this.props.enqueueSnackbar(
                `Booking updated for ${this.state.booking.client.display_name}.`,
                {
                  variant: 'success',
                }
              )
              this.props.handleClose()
            })
            .catch((err) => {
              console.error(err)
              this.props.enqueueSnackbar(
                `There was an issue updating this booking.`,
                {
                  variant: 'error',
                }
              )
              this.setState({
                submitting: false,
              })
            })
        } else {
          this.props.firestore
            .collection(collection)
            .add(this.state.booking)
            .then(() => {
              this.props.enqueueSnackbar(
                `Booking created for ${this.state.booking.client.display_name}.`,
                {
                  variant: 'success',
                }
              )
              this.props.handleClose()
            })
            .catch((err) => {
              this.props.enqueueSnackbar(
                `There was an issue creating this booking.`,
                {
                  variant: 'error',
                }
              )
              this.setState({
                submitting: false,
              })
            })
        }
      }
    )
  }

  render() {
    if (!isLoaded(this.props.rooms)) return <CircularProgress />
    return (
      <React.Fragment>
        <MuiDialogTitle disableTypography className="booking-modal-title">
          <Typography variant="h5">
            {this.props.isDaily ? 'Book a Day Office' : 'Book a Room'}
          </Typography>
          <IconButton
            onClick={this.dismiss}
            className="close-button"
            aria-label="Close"
          >
            <Icon>close</Icon>
          </IconButton>
        </MuiDialogTitle>
        <DialogContent>
          <Step1
            activeLocation={this.props.acitveLocationID}
            leaveNotes={this.leaveNotes}
            booking={this.state.booking}
            selectClient={this.selectClient}
            active={this.state.activeStep === 0}
            isDaily={this.state.isDaily}
          />
          <Step2
            booking={this.state.booking}
            selectRoom={this.selectRoom}
            rooms={this.props.rooms}
            active={this.state.activeStep === 1}
          />
          {this.state.isDaily ? (
            <Step3DayBooking
              activeLocation={this.props.activeLocation.timezone.value}
              selectTimeSlot={this.selectTimeSlot}
              changeTimeValue={this.changeTimeValue}
              changeTimeDuration={this.changeTimeDuration}
              changeActiveDate={this.changeActiveDate}
              duration={this.state.duration}
              booking={this.state.booking}
              activeDate={this.state.activeDate}
              active={this.state.activeStep === 2}
            />
          ) : (
            <Step3
              activeLocation={this.props.activeLocation.timezone.value}
              selectTimeSlot={this.selectTimeSlot}
              changeTimeValue={this.changeTimeValue}
              changeTimeDuration={this.changeTimeDuration}
              duration={this.state.duration}
              booking={this.state.booking}
              active={this.state.activeStep === 2}
            />
          )}
          <Step4
            activeLocation={this.props.activeLocation.timezone.value}
            rooms={this.props.rooms}
            booking={this.state.booking}
            active={this.state.activeStep === 3}
            isDaily={this.state.isDaily}
          />
        </DialogContent>
        <Paper elevation={3} style={{ position: 'relative', zIndex: 10 }}>
          <DialogActions style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Button
              onClick={() => this.updateStep(-1)}
              variant="contained"
              color="default"
              disabled={this.state.activeStep === 0}
            >
              Back
            </Button>
            <Hidden xsDown>
              <Stepper style={{ flex: 1 }} activeStep={this.state.activeStep}>
                <Step>
                  <StepLabel>Select Client</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Select Room</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Select Availability</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Confirm</StepLabel>
                </Step>
              </Stepper>
            </Hidden>
            <Hidden smUp>
              <MobileStepper
                variant="progress"
                steps={4}
                position="static"
                className="mobile-stepper"
                activeStep={this.state.activeStep}
                style={{ flexGrow: 1, flex: 1 }}
              />
            </Hidden>
            {this.state.activeStep === 3 ? (
              <Button
                disabled={this.state.submitting}
                onClick={() => this.bookRoom()}
                variant="contained"
                color="primary"
              >
                Book
              </Button>
            ) : (
              <Button
                disabled={this.state.submitting || this.state.next}
                onClick={() => this.updateStep(1)}
                variant="contained"
                color="default"
              >
                Next
              </Button>
            )}
          </DialogActions>
        </Paper>
        {this.state.submitting && <LoadingSplash label="Booking Room" />}
      </React.Fragment>
    )
  }
}

export default compose(
  withSnackbar,
  withRouter,
  firestoreConnect(),
  connect((state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }))
)(BookingModal)
