import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import {
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  withMobileDialog,
  Dialog,
  Link,
} from '@material-ui/core'
import moment from 'moment-timezone'

import BookingModal from './BookingModal'

class BookingSummary extends Component {
  state = {
    anchorEl: null,
    edit: false,
    booking_id: null,
    selectedBooking: null,
  }
  componentDidMount() {
    this.mounted = true
    this.getBooking()
    console.log('fetch mount', this.state.selectedBooking)
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.booking !== this.props.match.params.booking) {
      console.log('fetch new')
      this.getBooking()
    }
  }
  componentWillUnmount() {
    this.mounted = false
    if (this.listener) this.listener()
  }
  getBooking = () => {
    if (this.listener) this.listener()
    this.setState({ selectedBooking: null })

    this.listener = this.props.firestore
      .collection(this.props.isDaily ? 'dayBookings' : 'bookings')
      .doc(this.props.match.params.booking)
      .onSnapshot((doc) => {
        if (this.mounted && doc.exists) {
          this.setState({
            selectedBooking: { id: doc.id, ...doc.data() },
          })
        } else {
          this.setState({
            selectedBooking: false,
          })
        }
      })
  }
  editBooking = () => {
    this.setState({
      edit: !this.state.edit,
      anchorEl: null,
    })
  }
  cancelBooking = () => {
    this.setState({
      anchorEl: null,
    })
    const confirm = window.confirm(
      'Are you sure you want to cancel this booking? This action can not be undone.'
    )
    if (confirm) {
      this.props.firestore
        .collection(this.props.isDaily ? 'dayBookings' : 'bookings')
        .doc(this.props.match.params.booking)
        .update({
          canceled: {
            uid: this.props.auth.uid,
            name: `${this.props.profile.first_name} ${this.props.profile.last_name}`,
            canceled_on: this.props.firebase.firestore.FieldValue.serverTimestamp(),
          },
        })
        .then(() => {
          this.props.enqueueSnackbar(`Booking canceled.`, {
            variant: 'success',
          })
        })
    }
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  render() {
    const { fullScreen } = this.props
    if (this.state.selectedBooking === null) {
      return (
        <div style={{ margin: 40, textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )
    }
    if (this.state.selectedBooking === false) {
      return (
        <div style={{ margin: 40, textAlign: 'center' }}>
          there was an issue loading data
        </div>
      )
    }
    const booking = this.state.selectedBooking
    console.log(booking.date, Number(moment().format('YYYYMMDD')))
    return (
      <div className="booking-summary">
        <div
          className="hero"
          style={{
            backgroundImage: `url('${booking.room.image}')`,
          }}
        />
        <div className="details" style={{ maxWidth: 900, margin: '0 auto' }}>
          <Grid container spacing={16}>
            <Grid xs={12} sm={'auto'} style={{ flex: 1 }} item>
              <Typography variant="h4">{booking.room.display_name}</Typography>
            </Grid>
            <Grid
              xs={12}
              sm={'auto'}
              item
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {booking.canceled !== false ? null : (
                <IconButton
                  aria-haspopup="true"
                  onClick={this.handleClick}
                  aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
                  style={{ marginLeft: 32 }}
                >
                  <Icon>more_vert</Icon>
                </IconButton>
              )}
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem
                  disabled={
                    this.props.isDaily
                      ? booking.date <= Number(moment().format('YYYYMMDD'))
                      : Number(
                          moment
                            .unix(booking.start_time.seconds)
                            .format('YYYYMMDDHHmm')
                        ) < Number(moment().format('YYYYMMDDHHmm'))
                  }
                  onClick={this.editBooking}
                >
                  Edit
                </MenuItem>
                <MenuItem onClick={this.cancelBooking}>Cancel</MenuItem>
              </Menu>
            </Grid>
          </Grid>
          {booking.canceled !== false ? (
            <React.Fragment>
              <Divider className="spacer" />
              <Grid container spacing={16}>
                <Grid style={{ marginTop: 8 }} item xs={12}>
                  <Typography variant="h6" component="h6" color="error">
                    Meeting Canceled
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon style={{ margin: '0px 8px' }}>
                        <Icon>person</Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={booking.canceled.name}
                        secondary="Canceled by"
                      />
                    </ListItem>
                    {booking.canceled.canceled_on !== null ? (
                      <ListItem>
                        <ListItemIcon style={{ margin: '0px 8px' }}>
                          <Icon>calendar_today</Icon>
                        </ListItemIcon>
                        <ListItemText
                          primary={`${moment
                            .unix(booking.canceled.canceled_on.seconds)
                            .format('MM/DD/YYYY')}`}
                          secondary="Canceled On"
                        />
                      </ListItem>
                    ) : null}
                  </List>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : null}
          <Divider className="spacer" />
          <Grid container spacing={16}>
            <Grid style={{ marginTop: 8 }} item xs={12}>
              <Typography variant="h6" component="h6">
                Requested Time
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon style={{ margin: '0px 8px' }}>
                    <Icon>access_time</Icon>
                  </ListItemIcon>
                  {this.props.isDaily ? (
                    <ListItemText primary="All Day" secondary={'Time'} />
                  ) : (
                    <ListItemText
                      primary={`${moment
                        .unix(booking.start_time.seconds)
                        .tz(this.props.activeLocation.timezone.value)
                        .format('h:mma')} – ${moment
                        .unix(booking.end_time.seconds)
                        .tz(this.props.activeLocation.timezone.value)
                        .format('h:mma z')}`}
                      secondary="Time"
                    />
                  )}
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ margin: '0px 8px' }}>
                    <Icon>calendar_today</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={`${moment(booking.date, 'YYYYMMDD').format(
                      'MM/DD/YYYY'
                    )}`}
                    secondary="Date"
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Divider className="spacer" />
          <Grid container spacing={16}>
            <Grid style={{ marginTop: 8 }} item xs={12}>
              <Typography variant="h6" component="h6">
                Requested By
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={booking.client.image} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={booking.client.display_name}
                    secondary="client"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ margin: '0px 8px' }}>
                    <Icon>email</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={booking.client.email_address}
                    secondary="Email Address"
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Divider className="spacer" />
          <Grid container spacing={16}>
            <Grid style={{ marginTop: 8 }} item xs={12}>
              <Typography variant="h6" component="h6">
                Additional Info
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon style={{ margin: '0px 8px' }}>
                    <Icon>person</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={booking.created_by.display_name}
                    secondary="Created By"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ margin: '0px 8px' }}>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={moment
                      .unix(booking.created_on.seconds)
                      .format('MM/DD/YYYY')}
                    secondary="Created On"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ margin: '0px 8px' }}>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={booking.notes ? booking.notes : '--'}
                    secondary="Notes"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ margin: '0px 8px' }}>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      booking.openpath ? (
                        <div>
                          <Typography gutterBottom variant="body2">
                            Share the link below to allow guests to open the
                            door themselves
                          </Typography>
                          <div>
                            <Link
                              href={booking.openpath.webUrl}
                              target="_blank"
                              rel="noopener"
                            >
                              {booking.openpath.webUrl}{' '}
                              <Icon style={{ fontSize: 16 }}>open_in_new</Icon>
                            </Link>
                          </div>
                        </div>
                      ) : (
                        '--'
                      )
                    }
                    secondary="OpenPath"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ margin: '0px 8px' }}>
                    <Icon/>
                  </ListItemIcon>
                  <ListItemText
                    primary={booking.yardi_id}
                    secondary="Yardi Reservation Id"
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
        <Dialog
          maxWidth="md"
          fullWidth
          fullScreen={fullScreen}
          className="add-user-modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.edit}
        >
          <BookingModal
            acitveLocationID={this.props.match.params.location}
            activeLocation={this.props.activeLocation}
            booking_id={this.props.match.params.booking}
            booking={booking}
            isDaily={this.props.isDaily || false}
            auth={this.props.auth}
            profile={this.props.profile}
            handleClose={this.editBooking}
            rooms={this.props.rooms}
          />
        </Dialog>
      </div>
    )
  }
}

export default compose(
  withMobileDialog(),
  firestoreConnect(),
  connect((state) => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
    }
  }),
  withRouter,
  withSnackbar
)(BookingSummary)
