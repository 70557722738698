import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import HomeDetail from './components/HomeDetail'
import HomeOverview from './components/HomeOverview'

class Home extends Component {
  render() {
    return (
      <div className="directory container">
        <Switch>
          <Route path="/overview/:id" component={HomeDetail} />
          <Route exact path="/overview" component={HomeOverview} />
        </Switch>
      </div>
    )
  }
}

export default Home
