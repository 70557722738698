import React, { Component } from 'react'
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Icon,
  CircularProgress,
  DialogActions,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Typography,
} from '@material-ui/core'
import CurrencyInput from './CurrencyInput'
import LoadingSplash from '../../../components/LoadingSplash'
import ImageTools from '../../../helpers/resizeImage'
import { compose } from 'redux'
import { withSnackbar } from 'notistack'

const initialState = {
  submitting: false,
  price: 0,
  imageBlob: null,
  imagePreviewUrl: null,
  name: '',
  active: true,
  description: '',
  categories: [],
  image: '',
  update_product: false,
  isUploading: false,
  product: null,
}

class ProductForm extends Component {
  state = initialState
  constructor(props) {
    super(props)
    console.log(props)
    if (props.update_product) {
      // filter out deleted categories
      console.log(props.update_product.categories)
      let categories = props.update_product.categories.filter(
        (cat) => this.props.categories.filter((c) => cat === c.id).length
      )
      console.log(categories)
      this.state = {
        update_product: props.update_product,
        imageBlob: props.imageBlob,
        imagePreviewUrl: props.update_product.image,
        name: props.update_product.name,
        price: props.update_product.price,
        active: props.update_product.active,
        description: props.update_product.description,
        categories,
      }
    }
  }

  handleImageChange = (e) => {
    e.preventDefault()
    if (!e.target.files) return

    ImageTools.resize(
      e.target.files[0],
      {
        width: 600,
        height: 600,
      },
      (blob, success) => {
        console.log(blob, success)
        let reader = new FileReader()
        reader.onloadend = () => {
          this.setState({
            imageBlob: blob,
            imagePreviewUrl: reader.result,
          })
        }
        reader.readAsDataURL(blob)
      },
      'image/jpeg'
    )
  }

  uploadImage = (id) =>
    new Promise((res, ref) => {
      const storage = this.props.firebase
        .storage()
        .ref()
        .child(`product_images/${id}.jpg`)
      const upload = storage.put(this.state.imageBlob)
      upload.on(
        this.props.firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {},
        (err) => {},
        () => {
          upload.snapshot.ref
            .getDownloadURL()
            .then((url) => {
              res(url)
            })
            .catch((err) => {
              this.props.enqueueSnackbar(
                `There was an error creating the user, ${err.message}`,
                {
                  variant: 'error',
                }
              )
              console.log(err)
            })
        }
      )
    })

  submitForm = async (e) => {
    e.preventDefault()
    this.setState({
      submitting: true,
    })
    const doc = this.state.update_product
      ? this.props.firestore
          .collection('honorMarket')
          .doc(this.state.update_product.id)
      : this.props.firestore.collection('honorMarket').doc()
    let payload = {
      type: 'product',
      name: this.state.name,
      description: this.state.description,
      price: this.state.price,
      categories: this.state.categories,
      image: this.state.imagePreviewUrl,
    }
    if (this.state.imageBlob) {
      payload.image = await this.uploadImage(doc.id)
    }
    console.log(payload)
    await doc.set(payload)
    if (this.state.update_product) {
      this.props.enqueueSnackbar(`Product updated.`, {
        variant: 'success',
      })
      this.setState(
        {
          submitting: false,
        },
        () => {
          this.props.onClose()
        }
      )
    } else {
      this.props.enqueueSnackbar(`Product created.`, {
        variant: 'success',
      })
      this.setState(initialState)
    }
  }

  setValue = (key, val) => {
    this.setState({
      [key]: val,
    })
  }

  render() {
    if (this.state.fetching) {
      return <CircularProgress size="large" />
    }
    return (
      <React.Fragment>
        <div
          style={{ display: 'flex', alignItems: 'center', paddingRight: 16 }}
        >
          <DialogTitle style={{ flex: 1 }}>
            {this.props.update_product ? 'Update' : 'Create'} Product
          </DialogTitle>
          <IconButton onClick={() => this.props.onClose()}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <form
          className="honor-market-add-product"
          onSubmit={this.submitForm}
          autoComplete={'off'}
        >
          <input type="hidden" autoComplete={'false'} />
          <DialogContent style={{ paddingTop: 10 }}>
            <div className="product-image">
              <input
                onChange={(e) => this.handleImageChange(e)}
                accept="image/*"
                id="image"
                name="image"
                type="file"
                required={this.state.imagePreviewUrl ? false : true}
              />
              <label htmlFor="image" style={{ cursor: 'pointer' }}>
                {this.state.imagePreviewUrl ? (
                  <img alt="" src={this.state.imagePreviewUrl} />
                ) : (
                  <div />
                )}
                <Typography align="center" variant="button">
                  Upload Product Image
                </Typography>
              </label>
            </div>
            <TextField
              value={this.state.name}
              onChange={(e) => this.setValue('name', e.target.value)}
              label="Product Name"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              placeholder="La Croix"
              style={{ marginTop: 0 }}
            />
            <FormControl required fullWidth variant="outlined" margin="normal">
              <InputLabel htmlFor="categories">Select a Category</InputLabel>
              <Select
                required
                multiple
                value={this.state.categories}
                onChange={(e) => this.setValue('categories', e.target.value)}
                input={<OutlinedInput labelWidth={130} id="categories" />}
                renderValue={(selected) => {
                  return selected
                    .map(
                      (cat) =>
                        this.props.categories.filter((c) => c.id === cat)[0]
                          .title
                    )
                    .join(', ')
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {this.props.categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    <Checkbox
                      checked={this.state.categories.indexOf(cat.id) > -1}
                    />
                    <ListItemText primary={cat.title} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CurrencyInput
              value={this.state.price}
              onValueChange={(val) => this.setValue('price', val)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              disabled={this.state.submitting}
              color="primary"
              type="submit"
            >
              {this.props.update_product ? 'Update' : 'Create'} Product
            </Button>
          </DialogActions>
        </form>
        {this.state.submitting && (
          <LoadingSplash
            label={`${
              this.props.update_product ? 'Updating' : 'Creating'
            } Product`}
          />
        )}
      </React.Fragment>
    )
  }
}

export default compose(withSnackbar)(ProductForm)
