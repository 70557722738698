/* @flow */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import StyleButton from './StyleButton'

export default class InlineType extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    doExpand: PropTypes.func,
    doCollapse: PropTypes.func,
    onExpandEvent: PropTypes.func,
    config: PropTypes.object,
    onChange: PropTypes.func,
    currentState: PropTypes.object,
    translations: PropTypes.object
  }

  renderInFlatList = () => {
    const { config, currentState, onChange, translations } = this.props
    return (
      <div className={classNames('rdw-inline-wrapper', config.className)} aria-label="rdw-inline-control">
        {config.options.map((style, index) => (
          // <Option
          //   key={index}
          //   value={style}
          //   onClick={onChange}
          //   className={classNames(config[style].className)}
          //   active={currentState[style] === true || (style === 'MONOSPACE' && currentState.CODE)}
          //   title={config[style].title || translations[`components.controls.inline.${style}`]}
          // >
          //   <img alt="" src={config[style].icon} />
          // </Option>
          <StyleButton
            key={index}
            value={style}
            icon={`format_${style}`}
            active={currentState[style] === true || (style === 'MONOSPACE' && currentState.CODE)}
            onClick={onChange}
            label={config[style].title || translations[`components.controls.inline.${style}`]}
          />
        ))}
      </div>
    )
  }

  render() {
    return this.renderInFlatList()
  }
}

// todo: make subscript less low
