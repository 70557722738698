import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'

import { List, ListItem, ListItemText, ListSubheader, CircularProgress } from '@material-ui/core'

import CMListItem from './CMListItem'

class CMList extends Component {
  selectRow = id => {
    this.props.onSelect(id)
  }
  render() {
    return (
      <List>
        <ListSubheader style={{ backgroundColor: '#fff' }}>{this.props.label}</ListSubheader>
        {this.props.users === undefined || this.props.users === null || !Object.keys(this.props.users).length ? (
          <ListItem>
            <CircularProgress size="small" />
          </ListItem>
        ) : Object.keys(this.props.users).length === 0 ? (
          <ListItem>
            <ListItemText secondary="There are no users assigned to this role." />
          </ListItem>
        ) : (
          Object.keys(this.props.users).map(key => (
            <CMListItem onClick={this.selectRow} key={key} id={key} user={this.props.users[key]} />
          ))
        )}
      </List>
    )
  }
}

export default CMList
