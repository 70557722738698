import React, { Component } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure, Pagination } from 'react-instantsearch-dom'
import {
  Drawer,
  Paper,
  Hidden,
  withStyles,
  Icon,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  IconButton
} from '@material-ui/core'
import { compose } from 'redux'
import { connect } from 'react-redux'
import qs from 'qs'
import { withRouter } from 'react-router-dom'

import CustomSearchBar from './components/CustomSearchBar'
import CustomLocationRefinement from './components/CustomLocationRefinement'
import RefinementItem from './components/RefinementItem'
import MenuItem from './components/MenuItem'
import CustomHits from './components/CustomHits'
import FilterHeader from './components/FilterHeader'

const searchClient = algoliasearch(
  'AA2IDOZJC5',
  'e7242b645e57c9f0cf094cb8e4e43311'
)

const styles = theme => ({
  root: {
    display: 'flex'
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20
  }
})

class Directory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      account_type: props.location
        ? this.urlToSearchState(props.location).menu
          ? this.urlToSearchState(props.location).menu.type
          : 'company'
        : 'company',
      searchState: props.location ? this.urlToSearchState(props.location) : null
    }
  }
  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }))
  }
  trackRefinment = (key, val) => {
    this.setState({
      [key]: val.toLowerCase()
    })
  }

  createURL = state => `?${qs.stringify(state)}`

  searchStateToUrl = searchState => {
    return searchState
      ? `${this.props.location.pathname}${this.createURL(searchState)}`
      : ''
  }

  urlToSearchState = ({ search }) => qs.parse(search.slice(1))

  onSearchStateChange = searchState => {
    console.log(searchState)
    this.setState(
      {
        searchState
      },
      () => {
        this.props.history.push(this.searchStateToUrl(searchState), searchState)
      }
    )
  }

  render() {
    if (this.props.profile === undefined) return null
    const { classes, theme } = this.props
    const drawer = (
      <div className="refinements">
        {this.props.profile.role === 'admin' ? (
          <CustomLocationRefinement attribute="default_location" limit={100} />
        ) : (
          Object.keys(this.props.profile.locations).length > 1 && (
            <CustomLocationRefinement
              attribute="default_location"
              limit={100}
            />
          )
        )}
        <RefinementItem
          transformItems={items =>
            items.map(item => ({
              ...item,
              label: item.label === 'true' ? 'Yes' : 'No'
            }))
          }
          attribute="show_in_directory"
          label="Show in Directory"
        />
        <MenuItem
          transformItems={items =>
            items.map(item => ({
              ...item,
              label: item.label
                .split(' ')
                .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
                .join(' ')
            }))
          }
          trackRefinment={this.trackRefinment}
          defaultRefinement={'company'}
          attribute="type"
          label="Account Type"
        />
        {this.state.account_type === 'user' && (
          <React.Fragment>
            <MenuItem
              transformItems={items =>
                items.map(item => ({
                  ...item,
                  label: item.label === 'true' ? 'Yes' : 'No'
                }))
              }
              defaultRefinement={'false'}
              attribute="disabled"
              label="Account Disabled"
            />
          </React.Fragment>
        )}
      </div>
    )
    let filters = 'NOT role:admin'
    if (this.props.profile.role !== 'admin') {
      const keys = Object.keys(this.props.profile.locations)
      filters += ' AND '
      keys.forEach((key, i) => {
        filters += `default_location:${key}`
        if (i + 1 < keys.length) {
          filters += ' OR '
        }
      })
      console.log(filters)
    }
    return (
      <div className="directory">
        <FilterHeader />
        <InstantSearch
          indexName="Clients Page"
          searchClient={searchClient}
          searchState={this.state.searchState}
          onSearchStateChange={this.onSearchStateChange}
          createURL={this.createURL}
        >
          <Configure hitsPerPage={40} filters={filters} />
          <div className="search-body">
            <div className="filter-bar">
              <Hidden mdUp implementation="css">
                <Drawer
                  container={this.props.container}
                  // variant="persistent"
                  ModalProps={{
                    keepMounted: true
                  }}
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden smDown implementation="css">
                {drawer}
              </Hidden>
            </div>
            <div className="results">
              <Paper>
                <div className="top-bar">
                  <CustomSearchBar />
                  <div className="buttons">
                    <Hidden mdUp implementation="css">
                      <div className="filter-menu-toggle">
                        <IconButton
                          onClick={this.handleDrawerToggle}
                          variant="contained"
                          size="small"
                          className={classes.button}
                        >
                          <Icon>filter_list</Icon>
                        </IconButton>
                      </div>
                    </Hidden>
                  </div>
                </div>
                <Divider />
                <Paper elevation={0} className="overflow-table">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ padding: '4px 10px 4px 20px' }}>
                          Name
                        </TableCell>
                        <TableCell style={{ padding: '4px 10px' }}>
                          Location
                        </TableCell>
                        <TableCell
                          style={{ padding: '4px 10px' }}
                          align="right"
                        >
                          Room
                        </TableCell>
                        <TableCell
                          style={{ padding: '4px 20px 4px 10px' }}
                          align="right"
                        >
                          Floor
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <CustomHits />
                    </TableBody>
                  </Table>
                </Paper>
              </Paper>
              <Pagination padding={2} showFirst={false} />
            </div>
          </div>
        </InstantSearch>
      </div>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(state => ({
    profile: state.firebase.profile
  }))
)(Directory)
