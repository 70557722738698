import React from 'react'
import classNames from 'classnames'
import Async from 'react-select/lib/Async'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NoSsr from '@material-ui/core/NoSsr'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import { compose } from 'redux'
import cityTimezones from 'city-timezones'
import _ from 'lodash'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    display: 'flex',
    padding: 0
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08)
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 100,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  }
})

function NoOptionsMessage(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  return (
    <TextField
      fullWidth
      placeholder="Search by City or State"
      style={{ height: 56 }}
      InputProps={{
        inputComponent,
        inputProps: {
          style: { height: 56, paddingLeft: 16 },

          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      style={{ maxHeight: 200, overflow: 'hidden' }}
    >
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option
}

class TimezonePicker extends React.Component {
  state = {
    selected: null,
    searching: false,
    query: ''
  }

  query = _.debounce((inputValue, callback) => {
    console.log('test')
    if (inputValue.length > 3) {
      const results = cityTimezones.findFromCityStateProvince(inputValue).map(location => ({
        value: location.timezone,
        label: `${location.city}, (${location.timezone})`
      }))
      callback(results)
    }
  }, 1000)
  handleInputChange = _.debounce(query => {
    this.setState({ query })
  }, 1000)
  render() {
    const { classes, theme } = this.props

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        width: '100%',
        '& input': {
          font: 'inherit'
        }
      })
    }
    return (
      <div className={classes.root}>
        <NoSsr>
          <Async
            cacheOptions
            loadOptions={(inputValue, callback) => this.query(inputValue, callback)}
            classes={classes}
            styles={selectStyles}
            style={{ height: 56 }}
            ref={ref => (this.input = ref)}
            textFieldProps={{
              label: 'Timezone',
              InputLabelProps: {
                shrink: true
              },
              variant: 'outlined',
              required: true,
              fullWidth: true,
              placeholder: 'Chicago, IL',
              style: {
                height: 56
              }
            }}
            components={components}
            value={this.props.value}
            onChange={this.props.updateValue}
            placeholder="Chicago, IL"
          />
        </NoSsr>
      </div>
    )
  }
}

TimezonePicker.defaultProps = {
  isMulti: true
}

export default compose(withStyles(styles, { withTheme: true }))(TimezonePicker)
