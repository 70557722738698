import React, { PureComponent } from 'react'
import {
  TableRow,
  TableCell,
  Icon,
  LinearProgress,
  Button,
  Collapse,
  ListItem,
  Typography,
} from '@material-ui/core'
import { firestoreConnect } from 'react-redux-firebase'
import moment from 'moment'
import { List } from 'react-virtualized'
import 'react-virtualized/styles.css' // only needs to be imported once

class ReportItem extends PureComponent {
  constructor(props) {
    super(props)
    let state = {
      urlSet: false,
      download: null,
      processing: false,
      report: props.report,
      detailOpen: false,
    }
    if (props.processing) state.processing = true

    this.state = state
  }
  componentDidMount() {
    this.mounted = true
    if (this.state.processing) this.listener()
  }
  listener = () => {
    this.unsubscribe = this.props.firestore
      .collection('reports')
      .doc(this.state.report.id)
      .onSnapshot((snap) => {
        const data = snap.data()
        if (!data.generating) {
          if (this.mounted) {
            this.setState(
              {
                processing: false,
                report: { id: snap.id, ...data },
              },
              () => {
                this.unsubscribe()
              }
            )
          }
        }
      })
  }
  componentWillUnmount() {
    this.mounted = false
    this.unsubscribe && this.unsubscribe()
  }
  getDownload = () => {
    if (this.state.report.generating || this.state.urlSet) return
    if (this.state.report.download === null) return
    this.props.firebase
      .storage()
      .ref()
      .child(this.state.report.download)
      .getDownloadURL()
      .then((url) => {
        if (this.mounted) {
          this.setState({
            urlSet: false,
            download: url,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  render() {
    const { report } = this.state
    if (this.state.processing) {
      return (
        <TableRow>
          <TableCell>
            {report.type === 'dayBookings' ? (
              <React.Fragment>
                {moment(report.startDate, 'YYYYMMDD').format('MMMM D, YYYY')} -{' '}
                {moment(report.endDate, 'YYYYMMDD').format('MMMM D, YYYY')}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {moment(report.startDate).format('MMMM D, YYYY')} -{' '}
                {moment(report.endDate).format('MMMM D, YYYY')}
              </React.Fragment>
            )}
          </TableCell>
          <TableCell colSpan="5">
            <LinearProgress style={{ flex: 1 }} />
          </TableCell>
        </TableRow>
      )
    } else {
      if (!report.generating && !this.state.urlSet) {
        this.getDownload()
      }
    }
    // let data = []
    // Object.keys(report.data).map(key => {
    //   console.log(key)
    //   report.data[key].forEach(json => {
    //     data.push(JSON.parse(json))
    //   })
    // })
    // let company = data.filter(d => d.client.id === 'ryUVFUqvwWwPa3KMbwjG')
    // let total = 0
    // company.forEach(entry => {
    //   const dur = moment.duration(moment.unix(entry.end_time._seconds).diff(moment.unix(entry.start_time._seconds)))
    //   console.log(
    //     moment.unix(entry.start_time._seconds).format('hh:mm a'),
    //     moment.unix(entry.end_time._seconds).format('hh:mm a')
    //   )
    //   total += dur.asHours()
    // })
    // console.log(total)
    return (
      <React.Fragment>
        <TableRow
        // hover
        // onClick={() => this.setState({ detailOpen: !this.state.detailOpen })}
        // style={{ cursor: 'pointer' }}
        >
          <TableCell>
            {report.type === 'dayBookings' ? (
              <React.Fragment>
                {moment(report.startDate, 'YYYYMMDD').format('MMMM D, YYYY')} -{' '}
                {moment(report.endDate, 'YYYYMMDD').format('MMMM D, YYYY')}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {moment.unix(report.startDate.seconds).format('MMMM D, YYYY')} -{' '}
                {moment.unix(report.endDate.seconds).format('MMMM D, YYYY')}
              </React.Fragment>
            )}
          </TableCell>
          {report.generating ? (
            <TableCell colSpan="5">
              <LinearProgress style={{ flex: 1 }} />
            </TableCell>
          ) : (
            <React.Fragment>
              <TableCell>{report.total_clients}</TableCell>
              {report.type === 'dayBookings' ? null : (
                <TableCell>{report.total_hours}</TableCell>
              )}
              <TableCell>
                {report.type === 'dayBookings'
                  ? report.total_days
                  : report.total_bookings}
              </TableCell>
              <TableCell>
                {report.type === 'dayBookings'
                  ? '$89'
                  : Number(
                      moment.unix(report.startDate.seconds).format('YYYY')
                    ) <= 2019
                  ? '$20'
                  : '$30'}
              </TableCell>
              <TableCell align="right">
                {this.state.download ? (
                  <Button
                    onClick={(e) => e.stopPropagation()}
                    download={true}
                    style={{ minWidth: 0, height: 40 }}
                    href={this.state.download}
                    alt="download"
                    size="small"
                    color="primary"
                  >
                    <Icon>cloud_download</Icon>
                  </Button>
                ) : (
                  'No data found'
                )}
              </TableCell>
            </React.Fragment>
          )}
        </TableRow>
      </React.Fragment>
    )
  }
}

export default firestoreConnect()(ReportItem)
