import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import BookingDetail from './components/BookingDetail'
import BookingOverview from './components/BookingOverview'
import BookingDayDetail from './components/BookingDayDetail'

class Booking extends Component {
  render() {
    return (
      <div className="booking">
        <Switch>
          <Route path="/bookings-hour/:id" component={BookingDetail} />
          <Route path="/bookings-private/:id" component={BookingDayDetail} />
          <Route
            exact
            path="/bookings-hour"
            render={() => <BookingOverview rootPath={'bookings-hour'} />}
          />
          <Route
            exact
            path="/bookings-private"
            render={() => <BookingOverview rootPath={'bookings-private'} />}
          />
          <Redirect
            path="/bookings/:location/:id"
            to="/bookings-hour/:location/:id"
          />
          <Redirect path="/bookings" to="/bookings-hour" />
        </Switch>
      </div>
    )
  }
}

export default Booking
