import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'
import moment from 'moment'
import { Route } from 'react-router-dom'

import { withMobileDialog } from '@material-ui/core'

import PanelListDetail from '../../../wrappers/PanelListDetail'
import BookingControls from './BookingControls'
import BookingSummary from './BookingSummary'
import ListItems from './ListItems'

class BookingDetail extends Component {
  state = {
    bookingOpened: false,
    focusedInput: null,
    startDate: moment().startOf('week'),
    endDate: moment().endOf('week'),
  }
  componentDidMount() {
    this.mounted = true
    console.log(this.props)
    this.checkIfAlreadySelected()
  }
  checkIfAlreadySelected = () => {
    const url = this.props.location.pathname.split('/')

    if (url.length > 3) {
      this.setState({
        bookingOpened: true,
      })
    }
  }
  componentWillUnmount() {
    this.mounted = false
  }
  handleMobileClose = () => {
    this.props.history.push(`/bookings-hour/${this.props.match.params.id}`)
    this.setState({
      bookingOpened: false,
    })
  }
  openDetail = () => {
    this.setState({ bookingOpened: true })
  }
  render() {
    return (
      <div className="booking-detail">
        <PanelListDetail
          loadMoreButton={false}
          onClickBack={() => this.handleMobileClose(null)}
          open={this.state.bookingOpened}
          list={
            this.props.locations[this.props.match.params.id] !== undefined ? (
              <ListItems
                openDetail={this.openDetail}
                firestore={this.props.firestore}
                activeLocation={{
                  id: this.props.match.params.id,
                  ...this.props.locations[this.props.match.params.id],
                }}
              />
            ) : null
          }
          detail={
            this.props.locations[this.props.match.params.id] !== undefined ? (
              <Route
                exact
                path={`/bookings-hour/:location/:booking`}
                component={() => (
                  <BookingSummary
                    activeLocation={
                      this.props.locations[this.props.match.params.id]
                    }
                    rooms={this.props.rooms}
                  />
                )}
              />
            ) : null
          }
        />
        <BookingControls
          acitveLocationID={this.props.match.params.id}
          activeLocation={this.props.locations[this.props.match.params.id]}
          rooms={this.props.rooms}
        />
      </div>
    )
  }
}

export default compose(
  withMobileDialog(),
  firestoreConnect((props) => [
    {
      collection: 'locations',
      doc: props.match.params.id,
      subcollections: [{ collection: 'rooms' }],
      type: 'once',
      storeAs: 'hourlyRooms',
    },
  ]),
  connect((state, props) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    locations: state.firestore.data.locations,
    rooms: state.firestore.ordered.hourlyRooms,
  }))
)(BookingDetail)
