import React, { Component } from 'react'
import {
  Button,
  Paper,
  InputBase,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  Icon,
  Dialog,
  withMobileDialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton
} from '@material-ui/core'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import UserPicker from '../../../components/UserPicker'
import AddClient from './AddClient'
import AddCompany from './AddCompany'
import LocationSelector from '../../../components/LocationSelector'

class FilterHeader extends Component {
  state = {
    searchFocused: false,
    modalOpen: false,
    createDirectory: false,
    selectedLocation: null,
    createType: null,
  }
  toggleCreateType = type => {
    this.setState({
      createDirectory: !this.state.createDirectory,
      createType: type,
      selectedLocation: null
    })
  }
  setLocation = selectedLocation => {
    console.log(selectedLocation)
    this.setState({ selectedLocation })
  }
  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }
  render() {
    const { fullScreen } = this.props
    return (
      <React.Fragment>
        <div className="filter-header">
          <div className="search-bar-placeholder" />
          <div className="button-group">
            <Button
              color="primary"
              onClick={this.toggleModal}
              className="flex"
              style={{ textTransform: 'initial', fontWeight: '400' }}
            >
              {`Can't find a client or company?`}
            </Button>
            <Button
              onClick={() => this.toggleCreateType('user')}
              color="primary"
              variant="outlined"
            >
              Add Client
            </Button>
            <Button
              onClick={() => this.toggleCreateType('company')}
              color="primary"
              variant="outlined"
            >
              Add Company
            </Button>
          </div>
        </div>
        <Dialog
          maxWidth={this.state.selectedLocation ? 'lg' : 'xs'}
          fullWidth
          fullScreen={fullScreen}
          onBackdropClick={() => this.toggleCreateType(null)}
          className="add-user-modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.createDirectory}
        >
          {this.state.selectedLocation ? (
            this.state.createType === 'user' ? (
              <AddClient
                location={this.state.selectedLocation.id}
                handleClose={() => this.toggleCreateType(null)}
                label={`Add New Client to ${this.state.selectedLocation.name}`}
              />
            ) : (
              <AddCompany
                selectedLocation={this.state.selectedLocation}
                handleClose={() => this.toggleCreateType(null)}
                label={`Add New Company to ${this.state.selectedLocation.name}`}
              />
            )
          ) : (
            <LocationSelector
              dismiss={() => this.toggleCreateType(null)}
              onSelectLocation={this.setLocation}
              title="Select Location"
              noSubtitle
              description="This will be the location new clients are added to."
              singleSelect
            />
          )}
        </Dialog>
        <Dialog
          open={this.state.modalOpen}
          maxWidth={'sm'}
          fullWidth
          fullScreen={fullScreen}
        >
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: 12,
              paddingRight: 12,
              paddingBottom: 0
            }}
          >
            <Typography style={{ lineHeight: '1.1', flex: 1 }} variant="h6">
              Search Expansive Portfolio
            </Typography>
            <IconButton onClick={this.toggleModal}>
              <Icon>close</Icon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              gutterBottom
              style={{ marginBottom: 24 }}
            >
              Use this tool to search the entire Expansive Workspace client
              portfolio to find a user or company. Search by name, company name,
              or email address.
            </Typography>
            <UserPicker
              customFilter={'(type:user OR type:company) AND role:client'}
              dontExcludeByLocation
              searchIndicatorStyle={{ top: 13, right: 13 }}
              searchBarWrapper={Paper}
              searchBarWrapperStyle={{ padding: 4, paddingLeft: 12 }}
              searchBarComponent={InputBase}
              resultsWrapperStyle={{ marginTop: 8 }}
              resultsWrapperContainer={Paper}
              hitsPerPage={20}
              resultsMaxHeight={'100%'}
              resultItem={res => (
                <ListItem dense button key={res.objectID}>
                  <ListItemAvatar>
                    <Avatar
                      style={
                        res.type === 'company' ? { borderRadius: 8 } : null
                      }
                      src={res.image}
                    >
                      {res.type === 'company' ? (
                        <Icon>business</Icon>
                      ) : (
                        <Icon>person</Icon>
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="client-details"
                    primary={res.name}
                    secondary={
                      res.type === 'company' ? 'Company' : res.email_address
                    }
                  />
                  {res.disabled && (
                    <ListItemSecondaryAction
                      style={{ paddingRight: 8, textAlign: 'right' }}
                    >
                      <Icon color="disabled">person_add_disabled</Icon>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              )}
              label={''}
              placeholder={'Search for Company or User'}
              onSelect={user => {
                this.props.history.push(`/clients/${user.objectID}`)
              }}
            />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(
  withRouter,
  withMobileDialog(),
  connect(state => ({
    locations: state.firestore.ordered.locations
  }))
)(FilterHeader)
