import React from 'react'

import { Button, Tooltip, Icon } from '@material-ui/core'

class StyleButton extends React.Component {
  render() {
    return (
      <Button
        disabled={this.props.disabled === true}
        style={{
          minWidth: 0,
          lineHeight: 1,
          width: 36,
          height: 36,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'inline-flex',
          margin: 0,
          padding: 0
        }}
        size="small"
        color={this.props.active ? 'primary' : 'default'}
        className={this.props.active ? 'active' : ''}
        variant={'outlined'}
        onClick={() => this.props.onClick(this.props.value)}
      >
        {this.props.icon ? (
          <Tooltip title={this.props.label}>
            <Icon style={{ fontSize: 18 }}>{this.props.icon}</Icon>
          </Tooltip>
        ) : (
          <span style={{ fontSize: 15, fontWeight: '700' }}>{this.props.label}</span>
        )}
      </Button>
    )
  }
}

export default StyleButton
