import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField, Switch, Button, FormControl, FormControlLabel, Popper, Paper } from '@material-ui/core'

import StyleButton from './StyleButton'

class LayoutComponent extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    doExpand: PropTypes.func,
    doCollapse: PropTypes.func,
    onExpandEvent: PropTypes.func,
    config: PropTypes.object,
    onChange: PropTypes.func,
    currentState: PropTypes.object,
    translations: PropTypes.object
  }

  state = {
    showModal: false,
    linkTarget: '',
    linkTitle: '',
    linkTargetOption: this.props.config.defaultTargetOption
  }

  componentWillReceiveProps(props) {
    if (this.props.expanded && !props.expanded) {
      this.setState({
        showModal: false,
        linkTarget: '',
        linkTitle: '',
        linkTargetOption: this.props.config.defaultTargetOption
      })
    }
  }

  removeLink = () => {
    const { onChange } = this.props
    onChange('unlink')
  }

  addLink = () => {
    const { onChange } = this.props
    const { linkTitle, linkTarget, linkTargetOption } = this.state
    onChange('link', linkTitle, linkTarget, linkTargetOption)
  }

  updateValue = event => {
    this.setState({
      [`${event.target.name}`]: event.target.value
    })
  }

  updateTargetOption = event => {
    this.setState({
      linkTargetOption: event.target.checked ? '_blank' : '_self'
    })
  }

  hideModal = () => {
    this.setState({
      showModal: false
    })
  }

  signalExpandShowModal = () => {
    const {
      onExpandEvent,
      currentState: { link, selectionText }
    } = this.props
    const { linkTargetOption } = this.state
    onExpandEvent()
    this.setState({
      showModal: true,
      linkTarget: (link && link.target) || '',
      linkTargetOption: (link && link.targetOption) || linkTargetOption,
      linkTitle: (link && link.title) || selectionText
    })
  }

  forceExpandAndShowModal = () => {
    const {
      doExpand,
      currentState: { link, selectionText }
    } = this.props
    const { linkTargetOption } = this.state
    doExpand()
    this.setState({
      showModal: true,
      linkTarget: link && link.target,
      linkTargetOption: (link && link.targetOption) || linkTargetOption,
      linkTitle: (link && link.title) || selectionText
    })
  }

  renderAddLinkModal() {
    const { doCollapse, translations } = this.props
    const { linkTitle, linkTarget, linkTargetOption } = this.state
    return (
      <Paper elevation={10} className={'rdw-link-modal'} onClick={e => e.stopPropagation()}>
        <TextField
          variant="outlined"
          label={translations['components.controls.link.linkTitle']}
          id="linkTitle"
          onChange={this.updateValue}
          onBlur={this.updateValue}
          name="linkTitle"
          value={linkTitle}
          margin={'dense'}
        />
        <TextField
          variant="outlined"
          label={translations['components.controls.link.linkTarget']}
          id="linkTarget"
          onChange={this.updateValue}
          onBlur={this.updateValue}
          name="linkTarget"
          value={linkTarget}
          margin={'dense'}
        />
        <FormControl>
          <FormControlLabel
            control={<Switch />}
            value="_blank"
            onChange={this.updateTargetOption}
            type="checkbox"
            id="openLinkInNewWindow"
            defaultChecked={linkTargetOption === '_blank'}
            label={translations['components.controls.link.linkTargetOption']}
          />
        </FormControl>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 8 }}>
          <Button color="primary" size="small" onClick={this.addLink} disabled={!linkTarget || !linkTitle}>
            {translations['generic.add']}
          </Button>
          <Button color="default" size="small" onClick={doCollapse}>
            {translations['generic.cancel']}
          </Button>
        </div>
      </Paper>
    )
  }

  renderInFlatList() {
    const {
      config: { options, link, unlink },
      currentState,
      expanded,
      translations
    } = this.props
    const { showModal } = this.state
    return (
      <div className={'rdw-inline-wrapper rdw-link-wrapper'} aria-label="rdw-link-control">
        {options.indexOf('link') >= 0 && (
          <StyleButton
            value="unordered-list-item"
            icon={`link`}
            aria-haspopup="true"
            aria-expanded={showModal}
            onClick={this.signalExpandShowModal}
            label={link.title || translations['components.controls.link.link']}
          />
        )}
        {options.indexOf('unlink') >= 0 && (
          <StyleButton
            disabled={!currentState.link}
            value="ordered-list-item"
            icon={`link_off`}
            onClick={this.removeLink}
            label={unlink.title || translations['components.controls.link.unlink']}
          />
        )}
        {expanded && showModal ? this.renderAddLinkModal() : undefined}
      </div>
    )
  }

  render() {
    return this.renderInFlatList()
  }
}

export default LayoutComponent
