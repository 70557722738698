import React, { Component } from 'react'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import Papa from 'papaparse'

import {
  Fab,
  IconButton,
  Typography,
  Icon,
  DialogContent,
  withMobileDialog,
  Divider,
  Button,
  DialogActions,
  CircularProgress
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

import ClientRow from './ClientRow'

import { FunctionsDir } from './../../../config/firebase'

import LoadingSplash from '../../../components/LoadingSplash'

class USER_MODEL {
  constructor() {
    this.type = 'user'
    this.email_address = ''
    this.first_name = ''
    this.last_name = ''
    this.default_location = null
    this.company = null
    this.office_number = ''
    this.floor_number = ''
    this.role = 'client'
    this.locations = null
    this.image = ''
    this.bio = ''
    this.disabled = false
    this.job_title = ''
    this.linkedin = ''
    this.twitter = ''
    this.website = ''
    this.first_time_user = true
    this.show_in_directory = true
    this.verified = true
    this.yardi_id = null
    this.yardi_company_id = null
  }
}

class AddClient extends Component {
  state = {
    default_location: null,
    usersToAdd: [],
    submitted: false,
    count: 1,
    hasErrors: {},
    rowsWithErrors: []
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.locations !== undefined &&
      this.state.default_location === null
    ) {
      const location = _.filter(
        this.props.locations,
        loc => loc.id === this.props.location
      )[0]
      this.setState(
        {
          default_location: {
            value: location.id,
            label: `${location.name} - ${location.city}, ${location.state}`
          }
        },
        () => {
          this.addRow()
        }
      )
    }
  }
  handleChange = (key, value, row, error = false) => {
    let usersToAdd = [...this.state.usersToAdd]
    if (key === 'email_address') {
      if (error) {
        this.setState({
          hasErrors: {
            ...this.state.hasErrors,
            [`${usersToAdd[row].created.getTime()}`]: true
          }
        })
      } else {
        let hasErrors = Object.assign({}, this.state.hasErrors)
        delete hasErrors[`${usersToAdd[row].created.getTime()}`]
        this.setState({
          hasErrors
        })
      }
    }

    usersToAdd[row][key] = value

    this.setState(
      {
        usersToAdd
      },
      () => {
        this.duplicateCheck()
      }
    )
  }
  duplicateCheck = () => {
    let rowsWithErrors = []
    this.state.usersToAdd.forEach((user, i) => {
      this.state.usersToAdd.forEach((userComparedTo, x) => {
        if (user.email_address === '' && i !== x) {
          rowsWithErrors.push(i)
        } else if (
          user.email_address === userComparedTo.email_address &&
          i !== x
        ) {
          rowsWithErrors.push(i)
        }
      })
    })
    rowsWithErrors = rowsWithErrors.filter(
      (el, index, self) => index === self.findIndex(t => t === el)
    )
    this.setState({
      rowsWithErrors
    })
  }
  addRow = () => {
    let newUser = new USER_MODEL()
    newUser.default_location = this.state.default_location
    newUser.created = new Date()
    this.setState({
      usersToAdd: [...this.state.usersToAdd, newUser],
      count: [...this.state.usersToAdd, newUser].length
    })
  }
  removeRow = index => {
    console.log(index)
    let updatedUsers = [...this.state.usersToAdd]
    updatedUsers.splice(index, 1)
    let hasErrors = Object.assign({}, this.state.hasErrors)
    delete hasErrors[`${this.state.usersToAdd[index].created.getTime()}`]
    console.log(updatedUsers)
    this.setState(
      {
        usersToAdd: [],
        count: 0,
        hasErrors
      },
      () => {
        this.setState(
          {
            usersToAdd: updatedUsers,
            count: updatedUsers.length,
            hasErrors
          },
          () => this.duplicateCheck()
        )
      }
    )
  }
  onSubmit = e => {
    e.preventDefault()
    let body = {
      userData: this.state.usersToAdd,
      user_id: this.props.auth.uid
    }
    if (
      this.state.submitted ||
      this.state.rowsWithErrors.length ||
      Object.keys(this.state.hasErrors).length
    ) {
      return this.props.enqueueSnackbar(
        `There are errors above. ${Object.keys(this.state.hasErrors).length +
          this.state.rowsWithErrors.length}`,
        {
          variant: 'error'
        }
      )
    }
    this.setState(
      {
        submitted: true
      },
      () => {
        // validate data before sending request
        this.sendRequest(body)
      }
    )
  }
  sendRequest = body => {
    this.props.firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(token => {
        console.log(token)
        return axios.post(
          `${FunctionsDir}/novel/createClient`,
          {
            body
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'content-type': 'application/octet-stream'
            }
          }
        )
      })
      .then(
        res => {
          console.log(res)
          this.props.enqueueSnackbar(`User updated.`, {
            variant: 'success'
          })
          this.props.handleClose(true)
        },
        err => {
          console.log(err)
          this.setState({
            submitted: false
          })
        }
      )
      .catch(error => {
        console.log(error)
        this.setState({
          submitted: false
        })
      })
  }
  uploadCSV = e => {
    Papa.parse(e.target.files[0], {
      complete: res => {
        console.log(res.data)
        let rows = []
        res.data.forEach((row, i) => {
          if (i === 0) return
          let newUser = new USER_MODEL()
          const company = this.props.companies.filter(
            c => c.company_name === row[2]
          )[0]
          newUser.default_location = this.state.default_location
          newUser.created = new Date()
          newUser.first_name = row[0]
          newUser.last_name = row[1]
          newUser.company = company
            ? {
                value: company.id,
                label: company.company_name
              }
            : null
          newUser.email_address = String(row[3]).toLowerCase()
          newUser.office_number = row[5]
          newUser.floor_number = row[4]

          rows.push(newUser)
        })
        console.log(rows)
        this.setState(
          {
            usersToAdd: [],
            count: 0
          },
          () => {
            this.setState({
              usersToAdd: rows,
              count: rows.length
            })
          }
        )
      }
    })
  }
  render() {
    if (this.state.default_location === null)
      return (
        <div style={{ padding: 24, textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )
    return (
      <React.Fragment>
        <MuiDialogTitle disableTypography className="booking-modal-title">
          <Typography variant="h5">{this.props.label}</Typography>
          <IconButton
            onClick={this.props.handleClose}
            className="close-button"
            aria-label="Close"
          >
            <Icon>close</Icon>
          </IconButton>
        </MuiDialogTitle>
        <DialogContent style={{ paddingTop: 5, height: 'calc(100% - 95px)' }}>
          <form
            onSubmit={this.onSubmit}
            id="create-users-form"
            autoComplete="off"
          >
            {this.state.usersToAdd.map((user, i) => (
              <React.Fragment
                key={`${user.email_address}-${user.created.getTime()}`}
              >
                <div className="count" style={{ display: 'flex' }}>
                  <div
                    className="controls"
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      marginLeft: -20,
                      marginRight: 4,
                      width: 48
                    }}
                  >
                    {i + 1}
                    {this.state.usersToAdd.length > 1 ? (
                      <IconButton
                        onClick={() => {
                          this.removeRow(i)
                        }}
                        color="secondary"
                      >
                        <Icon>remove</Icon>
                      </IconButton>
                    ) : null}
                  </div>
                  <ClientRow
                    handleChange={this.handleChange}
                    defaultLocation={this.state.default_location}
                    company={this.props.company || null}
                    locations={this.props.locations}
                    companies={this.props.companies}
                    user={user}
                    row={i}
                    hasDuplicate={this.state.rowsWithErrors.includes(i)}
                  />
                </div>
                {this.state.usersToAdd.length > 1 &&
                i + 1 !== this.state.usersToAdd.length ? (
                  <Divider
                    style={{
                      marginTop: 8,
                      marginBottom: 24,
                      marginLeft: -24,
                      marginRight: -24,
                      height: 3
                    }}
                  />
                ) : null}
              </React.Fragment>
            ))}
            {this.state.count < 30 && (
              <div
                style={{
                  position: 'relative',
                  textAlign: 'center',
                  marginTop: 16,
                  marginBottom: 16
                }}
                className="divider"
              >
                <Divider
                  style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: '49%'
                  }}
                />
                <Fab onClick={this.addRow} size="small">
                  <Icon>add</Icon>
                </Fab>
              </div>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              flex: 1,
              padding: 10,
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography variant="caption" color="textSecondary">
              {this.state.count}/30
            </Typography>
            {/* <Button component={'label'} size="large" color="default" style={{ position: 'relative' }}>
              Import CSV
              <input
                onChange={this.uploadCSV}
                type="file"
                style={{ width: 0, height: 0, opacity: 0, zIndex: -1, overflow: 'hidden', position: 'absolute' }}
              />
            </Button> */}
          </div>
          <Button
            disabled={
              this.state.submitted || this.state.rowsWithErrors.length > 0
            }
            type="submit"
            size="large"
            form="create-users-form"
            color="primary"
          >
            Create Client{this.state.usersToAdd.length > 1 ? 's' : ''}
          </Button>
        </DialogActions>
        {this.state.submitted && (
          <LoadingSplash
            label={`Creating User${
              this.state.usersToAdd.length > 1 ? 's' : ''
            }...`}
          />
        )}
      </React.Fragment>
    )
  }
}

export default compose(
  withMobileDialog(),
  withSnackbar,
  firestoreConnect(props => [
    {
      collection: 'locations',
      orderBy: 'state'
    },
    {
      collection: 'profiles',
      where: [
        ['type', '==', 'company'],
        ['default_location.value', '==', props.location]
      ],
      orderBy: 'company_name',
      storeAs: 'companies'
    }
  ]),
  connect(state => {
    return {
      locations: state.firestore.ordered.locations,
      companies: state.firestore.ordered.companies,
      auth: state.firebase.auth
    }
  })
)(AddClient)
