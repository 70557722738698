import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { NavLink, Route, Redirect } from 'react-router-dom'
import _ from 'lodash'
import {
  Fab,
  Icon,
  Zoom,
  Dialog,
  withMobileDialog,
  CircularProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from '@material-ui/core'

import PanelListDetail from '../../../wrappers/PanelListDetail'
import AddCompany from './AddCompany'
import CompanyDetail from './CompanyDetail'

class Companies extends Component {
  state = {
    createCompany: false,
    disabled: true,
    index: 0,
    lastDoc: null,
    data: [],
    loadMore: true,
    allDataLoaded: false,
    detailOpened: false
  }
  componentDidMount() {
    this.mounted = true
    console.log(this.props)
    this.getData()
    this.checkIfAlreadySelected()
  }
  checkIfAlreadySelected = () => {
    const url = this.props.location.pathname.split('/')

    if (url.length > 3) {
      this.setState({
        detailOpened: true
      })
    }
  }
  componentWillUnmount() {
    this.mounted = false
  }
  getData = (clear = false) => {
    if (this.state.allDataLoaded && !clear) {
      console.log('all tax items loaded')
      return
    }
    let query = this.props.firestore
      .collection('profiles')
      .where(`type`, '==', 'company')
      .where('default_location.value', '==', this.props.match.params.id)
      .orderBy('company_name')
    if (this.state.lastDoc !== null && !clear) {
      query = query.startAfter(this.state.lastDoc)
    }
    query = query.limit(40)
    query.get().then(docs => {
      let data = clear ? [] : this.state.data
      docs.forEach(doc => {
        data.push({ ...doc.data(), id: doc.id })
      })
      console.log(data)
      if (this.mounted) {
        this.setState({
          data,
          lastDoc: docs.docs[docs.docs.length - 1],
          allDataLoaded: docs.docs[docs.docs.length - 1] === undefined ? true : false,
          loadMore: false
        })
      }
    })
  }
  onScrollBottom = _.debounce(() => {
    if (this.state.loadMore) return

    this.setState({ loadMore: true }, () => {
      this.getData()
    })
  }, 350)
  toggleCreateCompany = () => {
    this.setState(
      {
        createCompany: !this.state.createCompany
      },
      () => {
        this.getData(true)
      }
    )
  }
  handleMobileClose = () => {
    this.props.history.push(`/companies/${this.props.match.params.id}`)
    this.setState({
      detailOpened: false
    })
  }
  render() {
    const { fullScreen } = this.props
    return (
      <div className="companies">
        <Redirect from={`/companies/${this.props.match.params.id}`} to={`/clients/${this.props.match.params.id}`} />
        <PanelListDetail
          onBottom={this.onScrollBottom}
          onClickBack={() => this.handleMobileClose(null)}
          open={this.state.detailOpened}
          list={
            <React.Fragment>
              <List>
                {this.state.data.map(user => {
                  return (
                    <ListItem
                      className="company-list"
                      onClick={() => this.setState({ detailOpened: true })}
                      key={user.id}
                      to={`/companies/${this.props.match.params.id}/${user.id}`}
                      component={NavLink}
                      button
                    >
                      <ListItemText primary={`${user.company_name}`} secondary={user.email_address} />
                      {user.first_time_user ? (
                        <ListItemSecondaryAction>
                          <Icon color="disabled" style={{ marginRight: 8 }}>
                            phonelink_erase
                          </Icon>
                        </ListItemSecondaryAction>
                      ) : null}
                    </ListItem>
                  )
                })}
              </List>
              {this.state.loadMore ? <CircularProgress /> : null}
            </React.Fragment>
          }
          detail={<Route exact path={`/companies/:id/:company`} component={CompanyDetail} />}
        />
        <Dialog
          maxWidth="lg"
          fullWidth
          fullScreen={fullScreen}
          className="add-user-modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.createCompany}
        >
          <AddCompany
            selectedLocation={{ id: this.props.match.params.id, ...this.props.activeLocation }}
            handleClose={this.toggleCreateCompany}
            label="Add New Company"
          />
        </Dialog>
        <Zoom in>
          <Tooltip title="Add Company">
            <Fab onClick={this.toggleCreateCompany} className="main-action-fab" size="large" color="secondary">
              <Icon>add</Icon>
            </Fab>
          </Tooltip>
        </Zoom>
      </div>
    )
  }
}

export default compose(
  withMobileDialog(),
  firestoreConnect(props => [
    {
      collection: 'locations',
      doc: props.match.params.id,
      type: 'once',
      storeAs: 'activeLocation'
    }
  ]),
  connect(state => {
    return {
      activeLocation: state.firestore.data.activeLocation,
      listeners: state.firestore.listeners.allIds
    }
  })
)(Companies)
