import React, { Component } from 'react'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Icon,
} from '@material-ui/core'
export default (props) => {
  return (
    <Card elevation={props.active ? 2 : 0} style={{ height: '100%' }}>
      <CardActionArea
        disabled={props.disabled ? true : false}
        onClick={() => props.selectRoom(props.room)}
        style={{ height: '100%' }}
      >
        <CardMedia style={{ height: 150 }} image={props.room.image}>
          {props.active ? (
            <Icon
              style={{
                backgroundColor: '#fff',
                borderRadius: '100%',
                margin: 8,
                fontSize: 30,
              }}
              color="primary"
            >
              check_circle
            </Icon>
          ) : null}
        </CardMedia>
        <CardContent style={{ height: 'calc(100% - 150px)' }}>
          <Typography variant="h6" component="h2">
            {props.room.name}
          </Typography>
          {props.isDaily ? null : (
            <React.Fragment>
              <Typography color="textSecondary" style={{ marginBottom: 8 }}>
                Room {props.room.room_number} | Floor {props.room.floor_number}{' '}
                | Seats {props.room.seating}
              </Typography>
              <Typography component="p" variant="caption" color="textSecondary">
                {props.room.amenities.map((am, i) => (
                  <span
                    key={i}
                    style={{ marginRight: 16, display: 'inline-block' }}
                  >
                    — {am}
                  </span>
                ))}
              </Typography>
            </React.Fragment>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
