import React, { Component } from 'react'
import { firestoreConnect } from 'react-redux-firebase'
import { Grid, TextField, Select, OutlinedInput, FormControl, InputLabel } from '@material-ui/core'
class CompanyRow extends Component {
  state = { emailExists: false }
  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.props.handleChange(name, value, this.props.row, false)
  }
  render() {
    return (
      <div
        className="client-row"
        style={{ width: '100%', backgroundColor: this.props.hasDuplicate ? '#ffebee' : null }}
      >
        <Grid alignItems={'center'} container spacing={16}>
          {/* <Grid item xs={12} sm={12} md={4}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              type="email"
              label="Primary Email"
              name="email_address"
              defaultValue={this.props.company.email_address}
              onBlur={this.handleChange}
              style={{ margin: 0 }}
            />
          </Grid> */}
          <Grid item xs={8} md={5}>
            <TextField
              fullWidth
              required
              variant="outlined"
              margin="dense"
              label="Company Name"
              name="company_name"
              defaultValue={this.props.company.company_name}
              onBlur={this.handleChange}
              style={{ margin: 0 }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <FormControl
              disabled
              required
              name="default_location"
              variant="outlined"
              fullWidth
              margin="dense"
              style={{ margin: 0 }}
            >
              <InputLabel>Location</InputLabel>
              <Select
                required
                native
                defaultValue={this.props.defaultLocation.value}
                onChange={this.handleChange}
                name="default_location"
                input={<OutlinedInput name="default_location" labelWidth={70} />}
              >
                <option value={this.props.defaultLocation.value}>{this.props.defaultLocation.label}</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} md={2}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              label="Office"
              name="office_number"
              defaultValue={this.props.company.office_number}
              onBlur={this.handleChange}
              style={{ margin: 0 }}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              label="Floor"
              name="floor_number"
              defaultValue={this.props.company.floor_number}
              onBlur={this.handleChange}
              style={{ margin: 0 }}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default firestoreConnect()(CompanyRow)
