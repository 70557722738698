import React, { Component } from 'react'
import { firestoreConnect } from 'react-redux-firebase'
import {
  Grid,
  TextField,
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress
} from '@material-ui/core'
import axios from 'axios'

import { FunctionsDir } from './../../../config/firebase'
var CHECK_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class ClientRow extends Component {
  state = {
    emailExists: false,
    emailValidating: false,
    emptyFields: new Set(),
  }
  componentDidMount() {
    this.mounted = true
  }
  componentWillUnmount() {
    this.mounted = false
  }
  handleChange = e => {
    if (!this.mounted) return
    const name = e.target.name
    const value = e.target.value
    if (name === 'email_address') {
      if (CHECK_EMAIL.test(value)) {
        this.props.firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(token => {
            this.setState(
              { emailValidating: true, },
              () => {
                console.log(JSON.stringify(value))
                axios
                  .get(
                    `${FunctionsDir}/novel/checkEmailExists?email=${JSON.stringify(value)}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                        'content-type': 'application/octet-stream'
                      }
                    }
                  )
                  .then(res => {
                    console.log(res.data)
                    if (this.mounted) {
                      this.setState(
                        {
                          emailExists: res.data,
                          emailValidating: false
                        },
                        () =>
                          this.props.handleChange(
                            name,
                            value,
                            this.props.row,
                            res.data
                          )
                      )
                    }
                  })
              }
            )
          })
      } else {
        this.setState(
          { emailExists: true, },
          () => {
            this.props.handleChange(name, value, this.props.row, true)
          }
        )
      }
    } else if (name === 'company') {
      const company = this.props.companies.find(c => c.id === e.target.value)
      if (company) {
        this.props.handleChange(
          name,
          { value, label: company.company_name },
          this.props.row,
          false
        )
      } else {
        const emptyFields = (new Set(this.state.emptyFields)).add(name)
        this.setState({ emptyFields })
        this.props.handleChange(name, null, this.props.row, true)
      }
    } else {
      const emptyField = (value === '')
      if (emptyField) {
        const emptyFields = (new Set(this.state.emptyFields)).add(name)
        this.setState({ emptyFields })
      }
      this.props.handleChange(name, value, this.props.row, emptyField)
    }
  }
  render() {
    return (
      <div
        className="client-row"
        style={{
          width: '100%',
          backgroundColor: this.props.hasDuplicate ? '#ffebee' : null
        }}
      >
        <Grid container spacing={16}>
          <Grid item xs={12} sm={12} md={4} style={{ position: 'relative' }}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              style={{ margin: 0 }}
              label="First Name"
              name="first_name"
              defaultValue={this.props.user.first_name}
              onBlur={this.handleChange}
              error={this.state.emptyFields.has('first_name')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ position: 'relative' }}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              style={{ margin: 0 }}
              label="Last Name"
              name="last_name"
              defaultValue={this.props.user.last_name}
              onBlur={this.handleChange}
              error={this.state.emptyFields.has('last_name')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ position: 'relative' }}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              style={{ margin: 0 }}
              type="email"
              label="Email"
              name="email_address"
              defaultValue={this.props.user.email_address}
              onBlur={this.handleChange}
              error={this.state.emailExists}
            />
            {this.state.emailExists || this.props.hasDuplicate ? (
              <FormHelperText error={this.state.emailExists}>
                This email already exists or is invalid.
              </FormHelperText>
            ) : null}
            {this.state.emailValidating && (
              <div
                style={{
                  position: 'absolute',
                  right: 20,
                  top: 22
                }}
              >
                <CircularProgress size={20} thickness={5} />
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          {/*
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <FormControl
              disabled
              required
              name="default_location"
              variant="outlined"
              fullWidth
              margin="dense"
              style={{ margin: 0 }}
            >
              <InputLabel>Location</InputLabel>
              <Select
                required
                native
                defaultValue={this.props.defaultLocation.value}
                onChange={this.handleChange}
                name="default_location"
                input={
                  <OutlinedInput name="default_location" labelWidth={70} />
                }
              >
                <option value={{ value: '', label: '' }} />
                {this.props.locations
                  ? this.props.locations.map(location => {
                      return (
                        <option key={location.id} value={location.id}>
                          {location.name} - {location.city}, {location.state}
                        </option>
                      )
                    })
                  : null}
              </Select>
            </FormControl>
          </Grid>
          */}
          <Grid item xs={12} sm={6} md={8}>
            <FormControl
              required
              fullWidth
              variant="outlined"
              margin="dense"
              style={{ margin: 0 }}
              name="company"
              error={this.state.emptyFields.has('company')}
            >
              <InputLabel>Company</InputLabel>
              <Select
                native
                defaultValue={
                  this.props.user.company
                    ? this.props.user.company.value
                    : this.props.company
                    ? this.props.company
                    : ''
                }
                disabled={this.props.company || false}
                onChange={this.handleChange}
                name="company"
                placeholder="Select a Company"
                input={<OutlinedInput name="company" labelWidth={78} />}
              >
                <option value="" />
                {this.props.companies
                  ? this.props.companies.map(company => {
                      return (
                        <option key={company.id} value={company.id}>
                          {company.company_name}
                        </option>
                      )
                    })
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              style={{ margin: 0 }}
              label="Office #"
              name="office_number"
              defaultValue={this.props.user.office_number}
              onBlur={this.handleChange}
              error={this.state.emptyFields.has('office_number')}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              style={{ margin: 0 }}
              label="Floor #"
              name="floor_number"
              defaultValue={this.props.user.floor_number}
              onBlur={this.handleChange}
              error={this.state.emptyFields.has('floor_number')}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default firestoreConnect()(ClientRow)
