import React, { Component } from 'react'
import moment from 'moment'

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  IconButton,
  Icon
} from '@material-ui/core'

class Comment extends Component {
  render() {
    const { comment } = this.props
    return (
      <ListItem key={comment.id} alignItems="flex-start" style={{ opacity: comment.disabled ? 0.5 : 1 }}>
        <ListItemAvatar>
          <Avatar src={comment.author.image} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography component="span" color="textSecondary">
              {`${moment.unix(comment.created_on.seconds).fromNow()}`}
            </Typography>
          }
          secondary={
            <Typography variant="body1">
              <b style={{ fontWeight: '500' }}>{comment.author.name} – </b>
              {comment.post_content}
            </Typography>
          }
        />
        <ListItemSecondaryAction>
          <IconButton onClick={e => this.props.openDialog(e, comment)}>
            <Icon>more_vert</Icon>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}

export default Comment
