import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import axios from 'axios'
import { withSnackbar } from 'notistack'
import {
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Avatar,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Chip,
  Menu,
  MenuItem
} from '@material-ui/core'

import AddUser from './AddUser'
import { FunctionsDir } from '../../../config/firebase'

class UserDetail extends Component {
  state = { editUser: false, anchorEl: null }

  componentDidMount() {
    this.mounted = true
  }
  toggleEditUser = () => {
    this.setState({
      editUser: !this.state.editUser
    })
  }
  toggleUserDirectory = user => {
    const confirm = window.confirm(
      user.show_in_directory
        ? 'This will remove the user from the directory. Do you wish to continue?'
        : 'This will add the user to the directory. Do you wish to continue?'
    )

    if (confirm) {
      this.props.firestore
        .collection('profiles')
        .doc(this.props.match.params.id)
        .update({
          show_in_directory: !user.show_in_directory,
          profile_updated: new Date()
        })
        .then(() => {
          this.props.enqueueSnackbar(
            `${user.first_name} ${user.last_name} has been ${
              !user.show_in_directory ? 'added to' : 'removed from'
            } the directory.`,
            {
              variant: 'info'
            }
          )
        })
    }
  }
  toggleUserEnabled = user => {
    const confirm = window.confirm(
      !user.disabled
        ? 'By disabling this user, they will be un-assigned from their locations. Do you wish to continue?'
        : 'By enabling this user, they will be assignable to locations. Do you wish to continue?'
    )

    if (confirm) {
      this.props.firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(token => {
          console.log(token)
          return axios.post(
            `${FunctionsDir}/novel/disableAccount`,
            {
              body: {
                user: { ...user, uid: this.props.match.params.id },
                user_id: this.props.auth.uid,
                location: this.props.match.params.location
              }
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'application/octet-stream'
              }
            }
          )
        })
        .then(res => {
          console.log(res)
          this.props.enqueueSnackbar(`The status of ${user.first_name} ${user.last_name} has been updated.`, {
            variant: 'info'
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  render() {
    if (!isLoaded(this.props.selectedAdmin)) {
      return <CircularProgress />
    }
    if (isEmpty(this.props.selectedAdmin)) {
      return <div>there was an issue loading data</div>
    }
    const user = this.props.selectedAdmin[0]
    if (user === undefined) {
      return <div>there was an issue loading this profile</div>
    }
    return (
      <div className="user-detail">
        <Grid container alignItems="center" direction="row-reverse">
          <Grid item xs={12} sm={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              aria-owns={this.state.anchorEl ? 'edit-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <Icon>more_vert</Icon>
            </IconButton>
            <Menu
              id="edit-menu"
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={!user.disabled} onChange={() => this.toggleUserEnabled(user)} />}
                    label="Account Enabled"
                    labelPlacement="end"
                  />
                </FormGroup>
              </MenuItem>
              <MenuItem>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch checked={user.show_in_directory} onChange={() => this.toggleUserDirectory(user)} />
                    }
                    label="Show in Directory"
                    labelPlacement="end"
                  />
                </FormGroup>
              </MenuItem>
              <MenuItem onClick={this.toggleEditUser}>Edit User</MenuItem>
            </Menu>
          </Grid>
          <Grid xs={12} sm={7} item style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={user.image} style={{ width: 56, height: 56, marginRight: 16, marginLeft: 8 }} />
            <Typography variant="h4">
              {user.first_name} {user.last_name}
            </Typography>
          </Grid>
          <Grid style={{ marginTop: 8 }} item xs={12}>
            <List>
              {user.role !== 'admin' && (
                <React.Fragment>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <Icon>location_city</Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        this.props.locations && user.locations && Object.keys(user.locations).length
                          ? this.props.locations
                              .filter(loc => {
                                return Object.keys(user.locations).indexOf(loc.id) >= 0
                              })
                              .map(loc => (
                                <Chip
                                  clickable
                                  onClick={() => this.props.history.push(`/locations/${loc.id}`)}
                                  key={loc.id}
                                  label={`${loc.name} - ${loc.city}, ${loc.state}`}
                                  style={{ marginRight: 6 }}
                                  variant="outlined"
                                />
                              ))
                          : 'No Assigned Location'
                      }
                      secondary="Assigned Locations"
                    />
                  </ListItem>
                </React.Fragment>
              )}
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <Icon>business_center</Icon>
                </ListItemIcon>
                <ListItemText primary={user.company ? user.company.label : '--'} secondary="Company" />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <Icon>mail</Icon>
                </ListItemIcon>
                <ListItemText primary={user.email_address ? user.email_address : '--'} secondary="Email" />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <Icon>location_on</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={user.default_location ? user.default_location.label : '--'}
                  secondary="Default Expansive Location"
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <Icon>home</Icon>
                </ListItemIcon>
                <ListItemText primary={user.office_number ? user.office_number : '--'} secondary="Office Number" />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <Icon>domain</Icon>
                </ListItemIcon>
                <ListItemText primary={user.floor_number ? user.floor_number : '--'} secondary="Floor Number" />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <Icon>account_circle</Icon>
                </ListItemIcon>
                <ListItemText primary={user.job_title ? user.job_title : '--'} secondary="Job Title" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={user.website ? user.website : '--'} secondary="Website" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={user.twitter ? user.twitter : '--'} secondary="Twitter" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={user.linkedin ? user.linkedin : '--'} secondary="LinkedIn" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={user.yardi_id ? user.yardi_id : '--'} secondary="Yardi ID" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={user.yardi_company_id ? user.yardi_company_id : '--'} secondary="Yardi Company ID" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={user.bio ? user.bio : '--'} secondary="Bio" />
              </ListItem>
              <Divider />
              <ListItem style={{ marginTop: 16 }}>
                <ListItemIcon>
                  <Icon>security</Icon>
                </ListItemIcon>
                <ListItemText primary={<Typography variant="h6">Additional Details</Typography>} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  primary={user.metadata && user.metadata.keycard ? user.metadata.keycard : '--'}
                  secondary="Keycard"
                />
              </ListItem>
              {user.metadata
                ? Object.keys(user.metadata)
                    .filter(m => m !== 'keycard')
                    .map(key => {
                      if (key !== 'keycard') {
                        return (
                          <ListItem key={key}>
                            <ListItemIcon>
                              <Icon />
                            </ListItemIcon>
                            <ListItemText primary={user.metadata[key].value} secondary={user.metadata[key].label} />
                          </ListItem>
                        )
                      } else {
                        return null
                      }
                    })
                : null}
            </List>
          </Grid>
        </Grid>
        {this.state.editUser ? (
          <AddUser
            handleClose={this.toggleEditUser}
            open={this.state.editUser}
            editing={true}
            user={user}
            label={`Update ${user.first_name} ${user.last_name}`}
            id={this.props.match.params.id}
          />
        ) : null}
      </div>
    )
  }
}

export default compose(
  firestoreConnect(props => [{ collection: 'profiles', doc: props.match.params.id, storeAs: 'selectedAdmin' }]),
  connect((state, props) => {
    return {
      selectedAdmin: state.firestore.ordered.selectedAdmin,
      auth: state.firebase.auth,
      locations: state.firestore.ordered.locations
    }
  }),
  withRouter,
  withSnackbar
)(UserDetail)
