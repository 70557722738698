import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Grid, Paper, List, ListItem } from '@material-ui/core'
import Products from './Products'
import Categories from './Categories'
import { firestoreConnect } from 'react-redux-firebase'

class HonorMarket extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 0,
      loading: true,
    }
  }
  setTab = (tab) => {
    this.setState({ tab })
  }

  render() {
    if (this.props.profile === undefined) return null
    // load all data
    if (!this.props.honorMarket) return null
    return (
      <div className="honor-market">
        <Grid container spacing={24}>
          <Grid item xs={12} md={3} lg={2}>
            <Paper>
              <List>
                <ListItem
                  selected={this.state.tab === 0}
                  onClick={() => this.setTab(0)}
                  button
                >
                  Products
                </ListItem>
                <ListItem
                  selected={this.state.tab === 1}
                  onClick={() => this.setTab(1)}
                  button
                >
                  Categories
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            {this.state.tab === 0 && (
              <Products
                firebase={this.props.firebase}
                categories={this.props.honorMarket
                  .filter((el) => el.type === 'category')
                  .sort()}
                products={this.props.honorMarket.filter(
                  (el) => el.type === 'product'
                )}
                firestore={this.props.firestore}
              />
            )}
            {this.state.tab === 1 && (
              <Categories
                firestore={this.props.firestore}
                categories={this.props.honorMarket
                  .filter((el) => el.type === 'category')
                  .sort()}
              />
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(
  withRouter,
  firestoreConnect(() => [
    {
      collection: 'honorMarket',
    },
  ]),
  connect((state) => {
    return {
      profile: state.firebase.profile,
      honorMarket: state.firestore.ordered.honorMarket,
    }
  })
)(HonorMarket)
