import React, { Component } from 'react'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import axios from 'axios'
import Papa from 'papaparse'

import LoadingSplash from '../../../components/LoadingSplash'

import {
  Fab,
  IconButton,
  Typography,
  Icon,
  DialogContent,
  withMobileDialog,
  Divider,
  Button,
  DialogActions
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

import CompanyRow from './CompanyRow'

import { FunctionsDir } from './../../../config/firebase'

class USER_MODEL {
  constructor() {
    this.type = 'company'
    this.primary_contact = null
    this.company_name = ''
    this.office_number = ''
    this.floor_number = ''
    this.role = 'client'
    this.image = ''
    this.bio = ''
    this.disabled = false
    this.show_in_directory = false
    this.linkedin = ''
    this.twitter = ''
    this.website = ''
    this.default_location = { value: '', label: '' }
  }
}

class AddCompany extends Component {
  state = {
    default_location: null,
    companiesToAdd: [],
    submitted: false
  }
  constructor(props) {
    super(props)
    this.state.default_location = {
      value: this.props.selectedLocation.id,
      label: `${this.props.selectedLocation.name} — ${this.props.selectedLocation.city}, ${this.props.selectedLocation.state}`
    }
  }
  componentDidMount() {
    this.addRow()
  }

  handleChange = (key, value, row, error = false) => {
    let companiesToAdd = this.state.companiesToAdd

    companiesToAdd[row][key] = value

    this.setState({
      companiesToAdd
    })
  }
  addRow = () => {
    let newUser = new USER_MODEL()
    newUser.default_location = this.state.default_location
    newUser.created = this.props.firestore.Timestamp
    this.setState({
      companiesToAdd: [...this.state.companiesToAdd, newUser]
    })
  }
  removeRow = index => {
    console.log(index)
    let updatedUsers = [...this.state.companiesToAdd]
    updatedUsers.splice(index, 1)
    console.log(updatedUsers)
    this.setState(
      {
        companiesToAdd: []
      },
      () => {
        this.setState({
          companiesToAdd: updatedUsers
        })
      }
    )
  }
  onSubmit = e => {
    e.preventDefault()
    let body = {
      userData: this.state.companiesToAdd,
      user_id: this.props.auth.uid
    }
    this.setState(
      {
        submitted: true
      },
      () => {
        this.props.firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(token => {
            console.log(token)
            return axios.post(
              `${FunctionsDir}/novel/createCompany`,
              {
                body
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'content-type': 'application/octet-stream'
                }
              }
            )
          })
          .then(res => {
            console.log(res)
            this.props.enqueueSnackbar(`Company Created.`, {
              variant: 'success'
            })
            this.props.handleClose()
          })
          .catch(error => {
            console.log(error)
            this.setState({
              submitted: false
            })
          })
      }
    )
  }
  uploadCSV = e => {
    Papa.parse(e.target.files[0], {
      complete: res => {
        let rows = []
        res.data.forEach((row, i) => {
          if (i === 0) return
          let newUser = new USER_MODEL()
          newUser.default_location = this.state.default_location
          newUser.created = this.props.firestore.Timestamp
          newUser.company_name = row[0]
          newUser.office_number = row[2]
          newUser.floor_number = row[1]
          newUser.show_in_directory = true

          rows.push(newUser)
        })
        console.log(rows)
        this.setState(
          {
            companiesToAdd: []
          },
          () => {
            this.setState({
              companiesToAdd: rows
            })
          }
        )
      }
    })
  }
  render() {
    return (
      <React.Fragment>
        <MuiDialogTitle disableTypography className="booking-modal-title">
          <Typography variant="h5">{this.props.label}</Typography>
          <IconButton
            onClick={this.props.handleClose}
            className="close-button"
            aria-label="Close"
          >
            <Icon>close</Icon>
          </IconButton>
        </MuiDialogTitle>
        <DialogContent style={{ paddingTop: 5, height: 'calc(100% - 95px)' }}>
          <form
            onSubmit={this.onSubmit}
            id="create-users-form"
            autoComplete="off"
          >
            {this.state.companiesToAdd.map((company, i) => (
              <React.Fragment key={i}>
                <div className="count" style={{ display: 'flex' }}>
                  <div
                    className="controls"
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      marginLeft: -20,
                      marginRight: 4,
                      width: 48
                    }}
                  >
                    {i + 1}
                    {this.state.companiesToAdd.length > 1 ? (
                      <IconButton
                        onClick={() => {
                          this.removeRow(i)
                        }}
                        color="secondary"
                      >
                        <Icon>remove</Icon>
                      </IconButton>
                    ) : null}
                  </div>
                  <CompanyRow
                    defaultLocation={this.state.default_location}
                    handleChange={this.handleChange}
                    company={company}
                    row={i}
                  />
                </div>
                {this.state.companiesToAdd.length > 1 &&
                i + 1 !== this.state.companiesToAdd.length ? (
                  <Divider
                    style={{
                      marginTop: 8,
                      marginBottom: 24,
                      marginLeft: -24,
                      marginRight: -24,
                      height: 3
                    }}
                  />
                ) : null}
              </React.Fragment>
            ))}
            <div
              style={{
                position: 'relative',
                textAlign: 'center',
                marginTop: 16,
                marginBottom: 16
              }}
              className="divider"
            >
              <Divider
                style={{ position: 'absolute', left: 0, right: 0, top: '49%' }}
              />
              <Fab onClick={this.addRow} size="small">
                <Icon>add</Icon>
              </Fab>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <div style={{ flex: 1 }}>
            {/* <Button component={'label'} size="large" color="default" style={{ position: 'relative' }}>
              Import CSV
              <input
                onChange={this.uploadCSV}
                type="file"
                style={{ width: 0, height: 0, opacity: 0, zIndex: -1, overflow: 'hidden', position: 'absolute' }}
              />
            </Button> */}
          </div>
          <Button
            disabled={this.state.submitted}
            type="submit"
            size="large"
            form="create-users-form"
            color="primary"
          >
            Create Compan{this.state.companiesToAdd.length > 1 ? 'ies' : 'y'}
          </Button>
        </DialogActions>
        {this.state.submitted && (
          <LoadingSplash
            label={`Creating Compan${
              this.state.companiesToAdd.length > 1 ? 'ies' : 'y'
            }...`}
          />
        )}
      </React.Fragment>
    )
  }
}

export default compose(
  withMobileDialog(),
  withSnackbar,
  firestoreConnect(),
  connect(state => {
    return {
      auth: state.firebase.auth
    }
  })
)(AddCompany)
