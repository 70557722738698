import React, { Component } from 'react'
import {
  TableRow,
  TableCell,
  Avatar,
  Icon,
  Typography,
} from '@material-ui/core'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class Hit extends Component {
  state = { hovering: false }
  hovering = (hovering) => {
    this.setState({ hovering })
  }
  render() {
    const { hit, history, default_location } = this.props
    return (
      <TableRow
        onMouseEnter={() => this.hovering(true)}
        onMouseLeave={() => this.hovering(false)}
        onClick={() => history.push(`/clients/${hit.objectID}`)}
        hover
        key={hit.objectID}
      >
        <TableCell style={{ padding: '4px 8px 4px 20px' }}>
          {hit.type === 'user' && !hit.verified && (
            <div className="pending-bar"></div>
          )}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              style={{
                borderRadius: hit.type === 'company' ? 8 : 16,
                backgroundColor: '#D8D8D8',
                width: 32,
                height: 32,
                borderWidth: 1,
                borderColor: '#d8d8d8',
                borderStyle: 'solid',
                marginRight: 8,
              }}
              src={hit.image}
            >
              {hit.type === 'company' ? (
                <Icon fontSize="small">business</Icon>
              ) : (
                <Icon fontSize="small">person</Icon>
              )}
            </Avatar>
            <div>
              <Typography
                style={{ lineHeight: 1.2, fontSize: 14, fontWeight: '500' }}
              >
                {hit.name ? hit.name.trim() : hit.company_name.trim() || '--'}
              </Typography>
              <Typography style={{ fontSize: 12 }}>
                {hit.email_address || '--'}
              </Typography>
            </div>
            {hit.type === 'company' && hit.access_pass_member ? (
              <div
                className="count-pill pending"
                style={{ backgroundColor: '#2196f3' }}
              >
                <Typography
                  style={{ color: '#ffffff', fontWeight: '500' }}
                  variant={'caption'}
                >
                  Access Pass
                </Typography>
              </div>
            ) : null}
            {hit.type === 'user' && !hit.verified && (
              <div className="count-pill pending">
                <Typography variant={'caption'}>Pending Approval</Typography>
              </div>
            )}
          </div>
        </TableCell>
        {this.props.hideLocation ? null : (
          <TableCell style={{ padding: '4px 8px' }}>
            {default_location}
          </TableCell>
        )}
        <TableCell style={{ padding: '4px 8px' }} align="right">
          {hit.office_number || '--'}
        </TableCell>
        <TableCell style={{ padding: '4px 20px 4px 8px' }} align="right">
          {hit.floor_number || '--'}
        </TableCell>
        {(this.props.appendCells &&
          this.props.appendCells(this.state.hovering)) ||
          null}
      </TableRow>
    )
  }
}

export default compose(
  withRouter,
  connect((state) => ({
    locations: state.firestore.data.locations,
  }))
)(Hit)
