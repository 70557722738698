import React, { Component } from 'react'
import {
  IconButton,
  Typography,
  Icon,
  DialogContent,
  Button,
  DialogActions,
  FormControlLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  Avatar,
  Paper,
  InputBase,
} from '@material-ui/core'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import _ from 'lodash'

import MuiDialogTitle from '@material-ui/core/DialogTitle'

import LoadingSplash from '../../../components/LoadingSplash'

class CreateReport extends Component {
  constructor(props) {
    super(props)
    let location_data = props.locations
    if (props.profile.role !== 'admin') {
      location_data = _.filter(props.locations, loc => {
        return props.profile.locations && props.profile.locations[loc.id]
      })
    }
    this.state = {
      processing: false,
      created_on: new Date(),
      location_data,
      locations: [],
      query: '',
      select_all: false,
      includeDisabledProfiles: false,
    }
  }
  generateReport = _.debounce(() => {
    this.setState(
      {
        processing: true,
      },
      () => {
        console.log(this.state)
        const { created_on } = this.state
        this.props.firestore
          .collection('reports')
          .add({
            type: 'clients',
            created_on,
            user: this.props.user,
            locations: [...this.state.locations],
            generating: true,
            download: null,
            includeDisabledProfiles: this.state.includeDisabledProfiles,
          })
          .then(res => {
            this.props.enqueueSnackbar(`Report Generating.`, {
              variant: 'success'
            })
            this.props.createReport({
              id: res.id,
              created_on,
              user: this.props.user,
              locations: [...this.state.locations],
              generating: true,
              download: null,
              includeDisabledProfiles: this.state.includeDisabledProfiles,
            })
          })
          .catch(error => {
            console.log(error)
            this.setState({
              processing: false
            })
          })
      }
    )
  }, 320)
  onFocusChange = focusedInput => {
    this.setState({
      focusedInput: !focusedInput ? 'startDate' : focusedInput
    })
  }
  handleChange = newLoc => {
    let locations = [...this.state.locations]
    let exists = locations.filter(loc => loc.id === newLoc.id).length > 0
    if (exists) {
      locations = locations.filter(loc => loc.id !== newLoc.id)
    } else {
      locations.push({ name: newLoc.name, id: newLoc.id })
    }
    this.setState({ locations })
  }
  filterResults = query => {
    this.setState({ query })
  }
  selectAll = () => {
    if (!this.state.select_all) {
      this.setState({
        select_all: true,
        locations: this.state.location_data.map(loc => ({ name: loc.name, id: loc.id }))
      })
    } else {
      this.setState({
        select_all: false,
        locations: []
      })
    }
  }
  render() {
    return (
      <React.Fragment>
        <MuiDialogTitle disableTypography className="booking-modal-title">
          <Typography variant="h5">{this.props.title}</Typography>
          <IconButton onClick={() => this.props.createReport()} className="close-button" aria-label="Close">
            <Icon>close</Icon>
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogTitle disableTypography style={{ paddingTop: 0, paddingBottom: 8 }} className="booking-modal-title">
          <Typography variant="h6" gutterBottom>
            Select Locations
          </Typography>
          <Typography variant="caption" gutterBottom>
            All clients from the selected locations will appear in the report (Each client will be identified by their
            location in the data).
          </Typography>
          <div
            className="filter-bar"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 16,
            }}
          >
            <Paper
              elevation={1}
              style={{
                flex: 1,
                display: 'flex',
                padding: '2px 4px',
                alignItems: 'center',
                marginTop: 0,
                maxWidth: 320,
              }}
            >
              <div style={{ padding: 8 }}>
                <Icon>search</Icon>
              </div>
              <InputBase
                value={this.state.query}
                onChange={e => {
                  this.filterResults(e.target.value)
                }}
                placeholder="Search by Location Name"
                style={{ flex: 1 }}
              />
              <IconButton onClick={() => this.filterResults('')} style={{ padding: 8 }}>
                <Icon>clear</Icon>
              </IconButton>
            </Paper>
            <Button
              color={this.state.select_all ? 'secondary' : 'default'}
              onClick={this.selectAll}
              style={{ marginLeft: '5%', whiteSpace: 'nowrap' }}
              size="small"
            >
              Select All
            </Button>
          </div>
        </MuiDialogTitle>
        <DialogContent className="generate-report-modal" style={{ paddingTop: 5, paddingLeft: 8, paddingRight: 8 }}>
          <List>
            {_.orderBy(this.state.location_data, ['state', 'city'], ['asc', 'asc'])
              .filter(loc => {
                if (this.state.query) {
                  return (
                    loc.name.toUpperCase().indexOf(this.state.query.toUpperCase()) > -1 ||
                    loc.state.toUpperCase().indexOf(this.state.query.toUpperCase()) > -1 ||
                    loc.city.toUpperCase().indexOf(this.state.query.toUpperCase()) > -1 ||
                    this.state.locations.filter(l => l.id === loc.id).length > 0
                  )
                } else {
                  return true
                }
              })
              .sort((a, b) => {
                return a.state
              })
              .map(loc => (
                <ListItem disableRipple onClick={() => this.handleChange(loc)} button key={loc.id}>
                  <ListItemAvatar>
                    <Avatar alt="" src={loc.image} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={loc.name}
                    secondary={
                      <React.Fragment>
                        <Typography
                          color="textSecondary"
                          variant="caption"
                          component={'span'}
                          style={{ lineHeight: 1.1 }}
                        >
                          {loc.address} — {loc.city}, {loc.state}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      onChange={() => this.handleChange(loc)}
                      checked={this.state.locations.filter(l => l.id === loc.id).length > 0}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions
          style={{
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <div>
            <Typography
              variant="caption"
              style={{
                paddingBottom: 6,
              }}
            >
              {this.state.locations.length} locations selected
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.includeDisabledProfiles}
                  onChange={(e) => { this.setState({ includeDisabledProfiles: e.target.checked }) }}
                  color="primary"
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingRight: 6,
                  }}
                />
              }
              label={
                <Typography
                  variant="caption"
                >
                  Include disabled accounts
                </Typography>
              }
              labelPlacement="end"
            />
          </div>
          <Button
            onClick={this.generateReport}
            disabled={this.state.processing || !this.state.locations.length}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            style={{
              alignSelf: 'flex-end',
            }}
          >
            {this.props.button}
          </Button>
        </DialogActions>
        {this.state.processing && <LoadingSplash label="Creating Report" />}
      </React.Fragment>
    )
  }
}

CreateReport.defaultProps = {
  title: 'New Report',
  button: 'Generate Report'
}

export default compose(
  firestoreConnect(),
  connect(state => ({
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    locations: state.firestore.ordered.locations
  })),
  withSnackbar
)(CreateReport)
