import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withRouter } from 'react-router-dom'
import { withSnackbar } from 'notistack'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import ChipInput from 'material-ui-chip-input'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import ImageTools from '../../../helpers/resizeImage'
import { Paper, Card, CardActionArea, CardContent } from '@material-ui/core'

class AddRoom extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0,
      height: 0,
      imagePreviewUrl: props.imagePreviewUrl,
      imageBlob: null,
      updateRoom: props.updateRoom,
      updateRoomID: props.updateRoomID,
      modalTitle: props.modalTitle,
      room: props.roomData,
      dayOffice: null,
    }
    if (props.editRoom !== null) {
      this.state.imagePreviewUrl = props.editRoom.image
      this.state.updateRoom = true
      this.state.updateRoomID = props.editRoom.id
      this.state.modalTitle = `Update ${props.editRoom.name}`
      this.state.room = props.editRoom
      this.state.dayOffice = props.editRoom.description === undefined
    } else {
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }
  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  dismiss = () => {
    const confirm = window.confirm(
      'Are you sure you want to close this? your changes will not be saved'
    )
    if (confirm) {
      this.props.toggleOpen()
    }
  }

  handleChange = (e) => {
    console.log(e.target.id)
    let room = Object.assign({}, this.state.room)
    if (e.target.id === 'description') {
      if (e.target.value.length <= 250) {
        room[e.target.id] = e.target.value
      }
    } else {
      room[e.target.id] = e.target.value
    }
    this.setState({
      room,
    })
  }

  handleImageChange = (e) => {
    e.preventDefault()

    ImageTools.resize(
      e.target.files[0],
      {
        width: 600,
        height: 400,
      },
      (blob, success) => {
        console.log(blob, success)
        let reader = new FileReader()
        reader.onloadend = () => {
          this.setState({
            imageBlob: blob,
            imagePreviewUrl: reader.result,
          })
        }
        reader.readAsDataURL(blob)
      }
    )
  }

  // create room flow
  // check if update or add
  // if update check if image
  // if image upload image with uid then return url, update doc
  // if add get id ref then check if image
  // if image upload image with uid then return url, add doc

  createRoom = (e) => {
    e.preventDefault()
    var room = Object.assign({}, this.state.room)
    if (this.state.updateRoom) {
      this.updateRoomDoc(room)
    } else {
      room.created_at = this.props.firebase.firestore.FieldValue.serverTimestamp()
      this.createRoomDoc(room)
    }
  }

  createOffice = (e) => {
    e.preventDefault()
    var room = Object.assign({}, this.state.room)
    delete room.room_number
    delete room.floor_number
    delete room.seating
    delete room.description
    delete room.amenities
    delete room.entry_id
    if (this.state.updateRoom) {
      this.updateRoomDoc(room, 'privateOffices')
    } else {
      room.created_at = this.props.firebase.firestore.FieldValue.serverTimestamp()
      this.createRoomDoc(room, 'privateOffices')
    }
  }

  setImage = (name = this.state.updateRoomID) =>
    new Promise((resolve) => {
      const storage = this.props.firebase
        .storage()
        .ref()
        .child(`room_images/${name}`)
      const upload = storage.put(this.state.imageBlob)
      upload.on(
        this.props.firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {},
        (err) => {},
        () => {
          upload.snapshot.ref
            .getDownloadURL()
            .then((url) => {
              console.log(url)
              return resolve(url)
            })
            .catch((err) => {
              this.props.enqueueSnackbar(
                `There was an error creating the room, ${err.message}`,
                {
                  variant: 'error',
                }
              )
              console.log(err)
            })
        }
      )
    })

  createRoomDoc = async (room, key = 'rooms') => {
    var r = Object.assign({}, room)
    const ref = this.props.firestore
      .collection('locations')
      .doc(this.props.match.params.id)
      .collection(key)
      .doc()
    if (this.state.imageBlob) {
      const url = await this.setImage(ref.id)
      r.image = url
    }
    ref
      .set(r)
      .then((doc) => {
        console.log(doc)
        this.props.enqueueSnackbar('Successfully created new room', {
          variant: 'success',
        })
        this.props.toggleOpen()
      })
      .catch((err) => {
        this.props.enqueueSnackbar(
          `There was an error creating the room, ${err.message}`,
          {
            variant: 'error',
          }
        )
        console.log(err)
      })
  }

  updateRoomDoc = async (room, key = 'rooms') => {
    var r = Object.assign({}, room)
    if (this.state.imageBlob) {
      const url = await this.setImage()
      r.image = url
    }
    this.props.firestore
      .collection('locations')
      .doc(this.props.match.params.id)
      .collection(key)
      .doc(this.state.updateRoomID)
      .set(r, { merge: true })
      .then(() => {
        this.props.enqueueSnackbar('Successfully updated room', {
          variant: 'success',
        })
        this.props.toggleOpen()
      })
      .catch((err) => {
        this.props.enqueueSnackbar(
          `There was an error updating the room, ${err.message}`,
          {
            variant: 'error',
          }
        )
        console.log(err)
      })
  }

  onBeforeAdd = (chip) => {
    return chip.length >= 3
  }

  handleAddChip = (chip) => {
    console.log(chip)
    let room = this.state.room
    room.amenities.push(chip)
    this.setState({
      room,
    })
  }

  handleDeleteChip = (chip, index) => {
    console.log(chip)
    let room = this.state.room
    if (index > -1) {
      room.amenities.splice(index, 1)
    }
    this.setState({
      room,
    })
  }

  deleteRoom = (key = 'rooms') => {
    console.log('delete room')
    const confirm = window.confirm(
      'Are you sure you want to delete this room? All upcoming bookings will be canceled. This will not effect old bookings.'
    )
    if (confirm) {
      this.props.firestore
        .collection('locations')
        .doc(this.props.match.params.id)
        .collection(key)
        .doc(this.state.updateRoomID)
        .delete()
        .then(() => {
          this.props.enqueueSnackbar(`This room has been deleted.`)
          this.props.toggleOpen()
        })
        .catch((err) => {
          this.props.enqueueSnackbar(`There was an error deleting this room.`, {
            variant: 'error',
          })
        })
    }
  }

  render() {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        fullScreen={this.state.width <= 600 ? true : false}
        className="add-room-modal"
        aria-labelledby="Add a Room"
        aria-describedby="Add a new Co-Working space"
        open={this.props.open}
      >
        <MuiDialogTitle disableTypography className="booking-modal-title">
          <Typography variant="h5">{this.state.modalTitle}</Typography>
          <IconButton
            onClick={this.dismiss}
            className="close-button"
            aria-label="Close"
          >
            <Icon>close</Icon>
          </IconButton>
        </MuiDialogTitle>
        <DialogContent style={{ paddingTop: 5 }}>
          {this.state.dayOffice === null ? (
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  What type of bookable room are we adding?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card onClick={() => this.setState({ dayOffice: false })}>
                  <CardActionArea>
                    <CardContent>
                      <Typography align="center" variant="button">
                        Add Conference Room
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card onClick={() => this.setState({ dayOffice: true })}>
                  <CardActionArea>
                    <CardContent>
                      <Typography align="center" variant="button">
                        Add Private Office
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          ) : this.state.dayOffice ? (
            <form onSubmit={this.createOffice}>
              <Grid container spacing={24}>
                <Grid item xs={12} style={{ position: 'relative' }}>
                  <input
                    required={this.state.updateRoom ? false : true}
                    onChange={(e) => this.handleImageChange(e)}
                    accept="image/*"
                    style={{
                      position: 'absolute',
                      zIndex: 0,
                      opaity: 0.1,
                      left: 20,
                      right: 20,
                      bottom: 20,
                      top: 20,
                    }}
                    id="image"
                    name="image"
                    type="file"
                  />
                  <label htmlFor="image">
                    <Button
                      variant="contained"
                      component="span"
                      className="upload-button"
                      style={{
                        width: '100%',
                        height: '50vw',
                        maxHeight: 250,
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        backgroundImage: `url('${this.state.imagePreviewUrl}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={this.handleChange}
                    value={this.state.room.name}
                    variant="outlined"
                    required
                    id="name"
                    name="name"
                    label="Office Name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={this.handleChange}
                    value={this.state.room.yardi_id}
                    variant="outlined"
                    required
                    id="yardi_id"
                    name="yardi_id"
                    label="Office Yardi ID"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {this.state.updateRoom ? (
                    <Button
                      onClick={() => this.deleteOffice('privateOffices')}
                      variant="outlined"
                      color="secondary"
                    >
                      Delete Office
                    </Button>
                  ) : null}
                  <Button type="submit" variant="contained" color="primary">
                    {this.state.updateRoom ? 'Update' : 'Create'} Office
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : (
            <form onSubmit={this.createRoom}>
              <Grid container spacing={24}>
                <Grid item xs={12} style={{ position: 'relative' }}>
                  <input
                    required={this.state.updateRoom ? false : true}
                    onChange={(e) => this.handleImageChange(e)}
                    accept="image/*"
                    style={{
                      position: 'absolute',
                      zIndex: 0,
                      opaity: 0.1,
                      left: 20,
                      right: 20,
                      bottom: 20,
                      top: 20,
                    }}
                    id="image"
                    name="image"
                    type="file"
                  />
                  <label htmlFor="image">
                    <Button
                      variant="contained"
                      component="span"
                      className="upload-button"
                      style={{
                        width: '100%',
                        height: '50vw',
                        maxHeight: 250,
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        backgroundImage: `url('${this.state.imagePreviewUrl}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    onChange={this.handleChange}
                    value={this.state.room.name}
                    variant="outlined"
                    required
                    id="name"
                    name="name"
                    label="Room Name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    onChange={this.handleChange}
                    value={this.state.room.seating}
                    type="int"
                    name="seating"
                    variant="outlined"
                    required
                    id="seating"
                    label="Seating"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                  <TextField
                    onChange={this.handleChange}
                    value={this.state.room.room_number}
                    type="int"
                    name="room_number"
                    variant="outlined"
                    required
                    id="room_number"
                    label="Room Number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                  <TextField
                    onChange={this.handleChange}
                    value={this.state.room.floor_number}
                    type="int"
                    name="floor_number"
                    variant="outlined"
                    required
                    id="floor_number"
                    label="Floor Number"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ marginBottom: 16 }}
                >
                  <ChipInput
                    value={this.state.room.amenities}
                    onBeforeAdd={(chip) => this.onBeforeAdd(chip)}
                    onAdd={(chip) => this.handleAddChip(chip)}
                    onDelete={(chip, index) =>
                      this.handleDeleteChip(chip, index)
                    }
                    onBlur={(event) => {
                      if (this.props.addOnBlur && event.target.value) {
                        this.handleAddChip(event.target.value)
                      }
                    }}
                    blurBehavior={'add'}
                    classes={{
                      chipContainer: 'chip-container',
                    }}
                    name="amenities"
                    variant="outlined"
                    id="amenities"
                    label="Amenities"
                    fullWidth
                    newChipKeyCodes={[188]}
                    placeholder="TV, Projector, Whiteboards, etc..."
                    helperText="Enter a list of amenities, seperate each amenity with a comma"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={this.handleChange}
                    value={this.state.room.description}
                    name="description"
                    variant="outlined"
                    required
                    id="description"
                    label="Description"
                    fullWidth
                    multiline
                  />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingTop: 6 }}
                  >
                    {this.state.room.description.length}/250
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={this.handleChange}
                    value={this.state.room.entry_id}
                    name="entry_id"
                    variant="outlined"
                    id="entry_id"
                    label="Openpath ID"
                    fullWidth
                    placeholder="1234"
                    helperText="To unlock a conference room through the Expansive app. You'll need to add the entry id from OpenPath."
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={this.handleChange}
                    value={this.state.room.yardi_id}
                    name="yardi_id"
                    variant="outlined"
                    id="yardi_id"
                    label="Yardi ID"
                    fullWidth
                    placeholder="1234"
                    helperText="The ID of the corresponding Space in YardiKube. Used for booking"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {this.state.updateRoom ? (
                    <Button
                      onClick={this.deleteRoom}
                      variant="outlined"
                      color="secondary"
                    >
                      Delete Room
                    </Button>
                  ) : null}
                  <Button type="submit" variant="contained" color="primary">
                    {this.state.updateRoom ? 'Update' : 'Create'} Room
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

AddRoom.defaultProps = {
  imagePreviewUrl: null,
  updateRoom: false,
  updateRoomID: null,
  modalTitle: 'Create a Room',
  roomData: {
    image: null,
    name: '',
    room_number: '',
    floor_number: '',
    seating: '',
    description: '',
    amenities: [],
    active: false,
    entry_id: '',
  },
}

export default compose(
  withRouter,
  firestoreConnect(),
  connect(),
  withSnackbar
)(AddRoom)
