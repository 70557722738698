import React, { Component } from 'react'
import { Route } from 'react-router-dom'

import {
  AppBar,
  IconButton,
  Typography,
} from '@material-ui/core'

import IconArrowBack from '@material-ui/icons/ArrowBack'
import LogoLight from '../../../media/logo-light.svg'
import BackgroundGridDark from '../../../media/background-grid-dark.svg'

class PublicResourcesHeaderGoBack extends Component {
  followLink = () => {
    this.props.history.push(`/resources-public/${this.props.goBackRoute.params.locationId}`)
  }

  render() {
    return (
      <IconButton
        onClick={this.followLink}
        style={{
          position: 'absolute',
          left: 8,
          top: 8,
          color: '#ffffff',
        }}
        >
        <IconArrowBack />
      </IconButton>
    )
  }
}

class PublicResourcesHeader extends Component {
  render() {
    return (
      <AppBar
        color="secondary"
        position="relative"
        elevation={0}
        style={{
          overflowY: 'hidden',
          padding: '24px 0 16px 0',
        }}
        >
        {this.props.goBackRoute
          ? (
            <Route render={({ history }) => (
              <PublicResourcesHeaderGoBack
                history={history}
                goBackRoute={this.props.goBackRoute}
                />
              )} />
            )
          : ''}
        <div
          style={{
            backgroundColor: 'transparent',
            backgroundImage: `url('${BackgroundGridDark}')`,
            backgroundPosition: 'top -6px left -4px',
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: -1,
            width: '25%',
            height: '100vh',
            display: 'block',
          }}
          />
        <img
          src={LogoLight}
          alt="Expansive Workspace"
          style={{
            width: 'auto',
            height: 23,
            display: 'block',
            margin: '0 auto',
          }}
          />
        <div
          style={{
            margin: '0 auto',
            width: '100%',
            maxWidth: this.props.maxWidth,
            padding: '40px 16px 0 16px', // TODO Top value just arbitrary
          }}
          >
          <Typography
            variant={this.props.titleFontSize}
            color="inherit"
            display="block"
            noWrap
            style={{
              fontWeight: 700,
            }}
            >
            {this.props.title}
          </Typography>
          {this.props.subtitle && (
            <Typography
              variant="caption"
              color="inherit"
              display="block"
              noWrap
              >
              {this.props.subtitle}
            </Typography>
          )}
        </div>
      </AppBar>
    )
  }
}

export default PublicResourcesHeader
