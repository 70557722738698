import React, { Component } from 'react'
import {
  IconButton,
  Typography,
  Icon,
  DialogContent,
  Button,
  DialogActions,
  TextField,
  Grid,
} from '@material-ui/core'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { DayPickerRangeController } from 'react-dates'

import MuiDialogTitle from '@material-ui/core/DialogTitle'

import LoadingSplash from '../../../components/LoadingSplash'

const moment = extendMoment(Moment)

class CreateReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      created_on: new Date(),
      startDate: moment(new Date()).subtract(1, 'month'),
      endDate: moment(new Date()),
      focusedInput: 'startDate',
    }
  }
  generateReport = () => {
    this.setState(
      {
        processing: false,
      },
      () => {
        const { created_on, startDate, endDate } = this.state

        this.props.firestore
          .collection('reports')
          .add({
            created_on,
            type: 'bookings',
            startDate: startDate.clone().toDate(),
            endDate: endDate.clone().toDate(),
            user: this.props.user,
            location: this.props.location,
            generating: true,
            download: null,
          })
          .then((res) => {
            this.props.enqueueSnackbar(`Report Generating.`, {
              variant: 'success',
            })
            this.props.createReport({
              id: res.id,
              created_on,
              startDate: startDate.clone().toDate(),
              endDate: endDate.clone().toDate(),
              user: this.props.user,
              location: this.props.location,
              generating: true,
              download: null,
            })
          })
          .catch((error) => {
            console.log(error)
            this.setState({
              processing: false,
            })
          })
      }
    )
  }
  onFocusChange = (focusedInput) => {
    this.setState({
      focusedInput: !focusedInput ? 'startDate' : focusedInput,
    })
  }
  updateDates = (newStartDate, newEndDate) => {
    let startDate = newStartDate
    let endDate = newEndDate
    if (startDate !== null && endDate !== null) {
      if (newEndDate.diff(newStartDate, 'years') > 1) {
        startDate = this.state.startDate
      }
      this.setState({ startDate, endDate })
    }
  }
  render() {
    return (
      <React.Fragment>
        <MuiDialogTitle disableTypography className="booking-modal-title">
          <Typography variant="h5">{this.props.title}</Typography>
          <IconButton
            onClick={() => this.props.createReport()}
            className="close-button"
            aria-label="Close"
          >
            <Icon>close</Icon>
          </IconButton>
        </MuiDialogTitle>
        <DialogContent style={{ paddingTop: 5, height: 'calc(100% - 95px)' }}>
          <Typography variant="h6" style={{ marginBottom: 16 }}>
            Date Range
          </Typography>
          <Grid container spacing={16} style={{ marginBottom: 8 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                margin="none"
                label="Start Date"
                value={
                  this.state.startDate
                    ? this.state.startDate.format('YYYY-MM-DD')
                    : ''
                }
                max={moment(new Date()).add(1, 'year').format('YYYY-MM-DD')}
                onChange={(e) => {
                  let val = this.state.startDate.format('YYYY-MM-DD')
                  if (
                    moment(e.target.value, 'YYYY-MM-DD').isBefore(
                      moment(new Date())
                    )
                  ) {
                    val = e.target.value
                  }
                  if (val) {
                    this.updateDates(
                      moment(val, 'YYYY-MM-DD'),
                      this.state.endDate
                    )
                  }
                }}
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                margin="none"
                label="End Date"
                value={
                  this.state.endDate
                    ? this.state.endDate.format('YYYY-MM-DD')
                    : ''
                }
                onChange={(e) => {
                  let val = this.state.endDate.format('YYYY-MM-DD')
                  if (
                    moment(e.target.value, 'YYYY-MM-DD').isBefore(
                      moment(new Date())
                    ) &&
                    moment(e.target.value, 'YYYY-MM-DD').isAfter(
                      this.state.startDate
                    )
                  ) {
                    val = e.target.value
                  }
                  if (val) {
                    this.updateDates(
                      this.state.startDate,
                      moment(val, 'YYYY-MM-DD')
                    )
                  }
                }}
                min={this.state.startDate
                  .clone()
                  .add(1, 'day')
                  .format('YYYY-MM-DD')}
                max={this.state.startDate
                  .clone()
                  .add(1, 'year')
                  .format('YYYY-MM-DD')}
                type="date"
              />
            </Grid>
          </Grid>
          <DayPickerRangeController
            numberOfMonths={2}
            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
            onDatesChange={({ startDate, endDate }) =>
              this.updateDates(startDate, endDate)
            } // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={this.onFocusChange}
            // isOutsideRange={day => {
            //   if (this.state.focusedInput === 'endDate') {
            //     // End date is not after today and not before start date
            //     return day.isAfter(moment(new Date())) || day.isBefore(this.state.startDate.clone().subtract(1, 'day'))
            //   }
            //   if (this.state.focusedInput === 'startDate') {
            //     // Start date is not after today and not after end date and not before 2 years ago.
            //     return day.isAfter(moment(new Date()))
            //   }

            //   return false
            // }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.generateReport}
            disabled={
              this.state.processing ||
              this.state.startDate > this.state.endDate ||
              this.state.endDate.diff(this.state.startDate, 'years') > 1
            }
            type="submit"
            size="large"
            color="primary"
          >
            {this.props.button}
          </Button>
        </DialogActions>
        {this.state.processing && <LoadingSplash label="Creating Report" />}
      </React.Fragment>
    )
  }
}

CreateReport.defaultProps = {
  title: 'New Report',
  button: 'Generate Report',
}

export default compose(
  firestoreConnect(),
  connect((state) => ({
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  })),
  withSnackbar
)(CreateReport)
