import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  Checkbox,
  Paper,
  InputBase,
  List,
  ListItem,
  Divider,
  Typography
} from '@material-ui/core'

import { connectRefinementList } from 'react-instantsearch-dom'

class CustomLocationRefinement extends Component {
  constructor(props) {
    super(props)
    let location_data = props.locations
    if (props.profile.role !== 'admin') {
      location_data = _.filter(props.locations, loc => {
        return props.profile.locations && props.profile.locations[loc.id]
      })
    }
    this.state = {
      location_data,
      locations: [],
      query: '',
      mobile_open: false
    }
  }
  filterLocations = query => {
    this.setState({ query })
  }
  render() {
    const { items, isFromSearch, refine, createURL, label } = this.props

    return (
      <div className="refinement">
        <Typography variant="h6">{label}</Typography>
        <List>
          {items.map(item => (
            <ListItem
              dense
              key={item.label}
              onClick={() => refine(item.value)}
              button
              disableRipple
              disableGutters
            >
              <Checkbox
                value={item.label}
                disableRipple
                checked={item.isRefined}
                style={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 0,
                  paddingRight: 6
                }}
                color="primary"
              />
              <Typography variant={'caption'}>{`${item.label}`}</Typography>
              <div className="count-pill">
                <Typography variant={'caption'}>{item.count}</Typography>
              </div>
            </ListItem>
          ))}
        </List>
        <Divider />
      </div>
    )
  }
}

export default compose(
  connectRefinementList,
  connect(state => ({
    locations: state.firestore.ordered.locations,
    profile: state.firebase.profile
  }))
)(CustomLocationRefinement)
