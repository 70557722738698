import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'

import Authentication from './wrappers/Authentication'
import Navigation from './wrappers/Navigation'

import Login from './views/login'
import Booking from './views/booking'
import Community from './views/community'
import Resources from './views/resources'
import Directory from './views/directory'
import Companies from './views/companies'
import Home from './views/home'
import Office from './views/office'
import Reports from './views/reports'
import Admin from './views/admin'
import ChangeLog from './views/changelog'
import HonorMarket from './views/honorMarket'
import PublicResources from './views/resources-public'

class App extends Component {
  render() {
    return (
      <Authentication>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect from="/" to="/overview" />}
          />
          <Route path="/(login|login/forgot-password)" component={Login} />
          <Route path="/resources-public/:locationId" component={PublicResources} />
          <Route path="/resources-public/:locationId/:resourceId" component={PublicResources} />
          <Navigation>
            <Route path="/overview" component={Home} />
            <Route path="/bookings" component={Booking} />
            <Route path="/bookings-hour" component={Booking} />
            <Route path="/bookings-private" component={Booking} />
            <Route path="/clients" component={Directory} />
            <Route path="/companies" component={Companies} />
            <Route path="/community" component={Community} />
            <Route path="/resources" component={Resources} />
            <Route path="/locations" component={Office} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/reports-clients" component={Reports} />
            <Route path="/reports-bookings" component={Reports} />
            <Route path="/reports-privateoffices" component={Reports} />
            {/* <Route path="/reports" component={Reports} /> */}
            <Route
              path="/(admin|admin/:id)"
              render={() =>
                isLoaded(this.props.profile) &&
                this.props.profile.role === 'admin' ? (
                  <Admin />
                ) : (
                  <Redirect to="/overview" />
                )
              }
            />
            <Route
              path="/honor-market"
              render={() =>
                isLoaded(this.props.profile) &&
                this.props.profile.role === 'admin' ? (
                  <HonorMarket />
                ) : (
                  <Redirect to="/overview" />
                )
              }
            />
            <Route path="/change-log" component={ChangeLog} />
          </Navigation>
        </Switch>
      </Authentication>
    )
  }
}

export default compose(
  withRouter,
  firestoreConnect(),
  connect((state) => {
    return {
      profile: state.firebase.profile,
    }
  })
)(App)
