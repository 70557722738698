import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { ListItem, ListItemText, ListItemSecondaryAction, Icon } from '@material-ui/core'

class CMListItem extends Component {
  render() {
    const { user, id } = this.props
    if (user) {
      return (
        <ListItem onClick={() => this.props.onClick(id)} to={`/admin/${id}`} component={NavLink} button>
          <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.email_address} />
          {user.disabled ? (
            <ListItemSecondaryAction>
              <Icon color="disabled">block</Icon>
            </ListItemSecondaryAction>
          ) : null}
        </ListItem>
      )
    }
    return null
  }
}

export default withRouter(CMListItem)
