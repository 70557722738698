import React, { Component } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'

import { Icon, 
  ListSubheader, 
  Fab, 
  Tooltip, 
  Paper, 
  Switch, 
  Zoom } from'@material-ui/core'

import AddUser from './components/AddUser'
import CMList from './components/CMList'
import UserDetail from './components/UserDetail'

import PanelListDetail from '../../wrappers/PanelListDetail'

class Admin extends Component {
  state = { addUser: false, selectedUser: null, showDisabledAccounts: false }
  componentDidMount() {
    const paths = this.props.location.pathname.replace(/\/\s*$/, '').split('/')
    paths.shift()
    if (paths.length > 1) {
      this.setState({
        selectedUser: paths[1]
      })
    }
  }
  filterHelper = (users) => {
    if (users) {
      return Object.fromEntries(
        Object.entries(users).filter(
          entry => !entry[1].disabled || this.state.showDisabledAccounts)
      );
    } 
    return users;
  }
  toggleAddMember = () => {
    this.setState({
      addUser: !this.state.addUser
    })
  }
  toggleShowDisabledAccounts = () => {
    this.setState({
      showDisabledAccounts: !this.state.showDisabledAccounts
    })
  }
  selectUser = id => {
    this.setState({
      selectedUser: id
    })
  }
  handleMobileClose = () => {
    this.setState({
      selectedUser: null
    })
    this.props.history.push('/admin')
  }
  render() {
    return (
      <div className="admin">
        <Zoom in>
          <Tooltip title="Add CM, RM, or Admin" aria-label="Add CM, RM, or Admin">
            <Fab
              onClick={this.toggleAddMember}
              className="main-action-fab"
              size="large"
              color="secondary"
              style={{ zIndex: 100 }}
            >
              <Icon>person_add</Icon>
            </Fab>
          </Tooltip>
        </Zoom>
        <PanelListDetail
          onClickBack={() => this.handleMobileClose(null)}
          open={this.state.selectedUser !== null}
          list={  
            <React.Fragment>
              <Paper square elevation={1}>
                <ListSubheader style={{ backgroundColor: '#fff' }}>
                  <Switch checked={this.state.showDisabledAccounts} onChange={this.toggleShowDisabledAccounts} />
                    Show disabled accounts
                </ListSubheader>
              </Paper>
              <CMList
                onSelect={this.selectUser}
                label="Admins"
                users={this.filterHelper(this.props.admins)}
              />
              <CMList
                onSelect={this.selectUser}
                label="Center Admins"
                users={this.filterHelper(this.props.regional_managers)}
              />
              <CMList
                onSelect={this.selectUser}
                label="Area Sales Managers"
                users={this.filterHelper(this.props.center_managers)}
              />
              <CMList
                onSelect={this.selectUser}
                label="Community Managers"
                users={this.filterHelper(this.props.community_managers)}                
              />
            </React.Fragment>
          }
          detail={<Route exact path="/admin/:id" component={UserDetail} />}
        />
        {this.state.addUser ? (
          <AddUser
            handleClose={this.toggleAddMember}
            open={this.state.addUser}
          />
        ) : null}
      </div>
    )
  }
}

export default compose(
  firestoreConnect(props => [
    {
      collection: 'profiles',
      where: [['role', '==', 'admin']],
      storeAs: 'admins'
    },
    {
      collection: 'profiles',
      where: [['role', '==', 'center_manager']],
      storeAs: 'center_managers'
    },
    {
      collection: 'profiles',
      where: [['role', '==', 'community_manager']],
      storeAs: 'community_managers'
    },
    {
      collection: 'profiles',
      where: [['role', '==', 'regional_manager']],
      storeAs: 'regional_managers'
    }
  ]),
  connect((state, props) => {
    return {
      admins: state.firestore.data.admins,
      community_managers: state.firestore.data.community_managers,
      center_managers: state.firestore.data.center_managers,
      regional_managers: state.firestore.data.regional_managers,
      auth: state.firebase.auth
    }
  }),
  withRouter
)(Admin)
