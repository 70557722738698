import React, { Component } from 'react'
import { IconButton, Typography, Icon, DialogContent, Button, DialogActions, TextField, Paper } from '@material-ui/core'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'

import MuiDialogTitle from '@material-ui/core/DialogTitle'

import AttachImage from './AttachImage'

import LoadingSplash from '../../../components/LoadingSplash'

class CreateResource extends Component {
  constructor(props) {
    super(props)
    var state = {
      featured_image: null,
      attachment: null,
      post_content: '',
      saving: false,
      edited: false,
      icon: null
    }
    const res = props.editPost
    if (res) {
      state.featured_image = res.featured_image ? res.featured_image : null
      state.post_content = res.post_content ? res.post_content : ''
      state.attachment = res.attachment ? res.attachment : null
    }

    console.log(res)

    this.state = state
  }
  onChange = e => {
    this.setState({
      post_content: e.target.value
    })
  }
  editingPost = () => {
    this.props.firestore
      .collection('community')
      .doc(this.props.editPost.id)
      .update({
        featured_image: this.state.featured_image,
        attachment: this.state.attachment,
        post_content: this.state.post_content
      })
      .then(() => {
        this.props.enqueueSnackbar(`Post updated`, {
          variant: 'success'
        })
        this.props.postUpdated(this.props.post)
      })
  }
  creatingPost = () => {
    this.props.firestore
      .collection('community')
      .add({
        created_on: this.props.firebase.firestore.FieldValue.serverTimestamp(),
        author: {
          uid: this.props.auth.uid,
          name: `${this.props.profile.first_name} ${this.props.profile.last_name}`,
          image: this.props.profile.image,
          role: this.props.profile.role
        },
        featured_image: this.state.featured_image,
        attachment: this.state.attachment,
        post_content: this.state.post_content,
        users_liked: [],
        users_commented: [],
        like_count: 0,
        comment_count: 0,
        disabled: false,
        location: this.props.location
      })
      .then(doc => {
        this.props.postCreated(doc)
      })
  }
  createPost = e => {
    if (this.state.post_content.length === 0) {
      return this.props.enqueueSnackbar(`You need to add content`, {
        variant: 'error'
      })
    }
    e.preventDefault()
    this.setState({ saving: true }, () => {
      if (this.props.editPost) {
        console.log('editing post')
        this.editingPost()
      } else {
        console.log('creating post')
        this.creatingPost()
      }
    })
  }
  updateImage = featured_image => {
    console.log(featured_image)
    this.setState({
      featured_image,
      edited: true
    })
  }
  updateAttachment = attachment => {
    this.setState({
      attachment,
      edited: true
    })
  }
  render() {
    return (
      <React.Fragment>
        <MuiDialogTitle disableTypography className="booking-modal-title">
          <Typography variant="h5">{this.props.editPost ? 'Update Post' : 'Create Post'}</Typography>
          <IconButton onClick={() => this.props.createPost()} className="close-button" aria-label="Close">
            <Icon>close</Icon>
          </IconButton>
        </MuiDialogTitle>
        <DialogContent style={{ paddingTop: 5, height: 'calc(100% - 95px)' }}>
          <form onSubmit={this.createPost} id="create-post-form" autoComplete="off">
            <TextField
              required
              fullWidth
              variant="outlined"
              name="post_content"
              placeholder="Add Your Message"
              label="Message"
              multiline
              value={this.state.post_content}
              onChange={this.onChange}
              rows={5}
              rowsMax={20}
              style={{ minHeight: 120, maxHeight: 500 }}
            />
          </form>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <AttachImage
              selectedImage={this.state.featured_image}
              attachment={this.state.attachment}
              updateImage={this.updateImage}
              updateAttachment={this.updateAttachment}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', flex: 1 }} />
          <Button disabled={this.state.saving} type="submit" size="large" form="create-post-form" color="primary">
            {this.props.editPost ? 'Update Post' : 'Create Post'}
          </Button>
        </DialogActions>
        {this.state.saving && (
          <LoadingSplash label={`${this.props.editPost ? 'Updating Post...' : 'Creating Post...'}`} />
        )}
      </React.Fragment>
    )
  }
}

CreateResource.defaultProps = {
  title: 'New Post',
  button: 'Create Post',
  editPost: null
}

export default compose(
  firestoreConnect(),
  connect(state => ({
    profile: state.firebase.profile,
    auth: state.firebase.auth
  })),
  withSnackbar
)(CreateResource)
