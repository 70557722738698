import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import _ from 'lodash'

import { Fab, Icon, withMobileDialog, Dialog, Zoom, Tooltip } from '@material-ui/core'

import PostFeed from './PostFeed'
// import CreatePost from './CreatePost'
import CreatePost2 from './CreatePost2'

class PostDetail extends Component {
  state = {
    createPost: false,
    post_content: '',
    submitted: false,
    recentlyCreated: [],
    editPost: null
  }
  postCreated = doc => {
    doc.get().then(d => {
      let data = [...this.state.recentlyCreated]
      data.unshift({ ...d.data(), id: d.id })
      this.setState({
        recentlyCreated: data
      })
      console.log(this.state.recentlyCreated)
    })

    this.props.enqueueSnackbar(`Post created`, {
      variant: 'success'
    })
    this.setState({
      submitted: false,
      createPost: false,
      post_content: ''
    })
  }
  editPost = (editPost, callback) => {
    this.createPost(editPost, callback)
  }
  createPost = (editPost = null, callback = null) => {
    this.setState({
      editPost,
      callback,
      createPost: !this.state.createPost
    })
  }
  postUpdated = post => {
    console.log(this.state.callback)
    this.state.callback && this.state.callback(true)
    this.createPost()
  }
  render() {
    const { fullScreen } = this.props
    return (
      <React.Fragment>
        <PostFeed
          editPost={this.editPost}
          community={this.props.match.params.id}
          recentlyCreated={this.state.recentlyCreated}
        />
        <Zoom in>
          <Tooltip title="Add Community Post">
            <Fab onClick={() => this.createPost()} className="main-action-fab" size="large" color="secondary">
              <Icon>add_comment</Icon>
            </Fab>
          </Tooltip>
        </Zoom>
        <Dialog
          maxWidth="sm"
          fullWidth
          fullScreen={fullScreen}
          className="add-user-modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.createPost}
        >
          {/* <CreatePost
            createPost={this.createPost}
            onSubmit={this.onSubmit}
            post_content={this.state.post_content}
            onChange={this.onChange}
            submitted={this.state.submitted}
          /> */}
          <CreatePost2
            postUpdated={this.postUpdated}
            postCreated={this.postCreated}
            createPost={this.createPost}
            location={this.props.match.params.id}
            editPost={this.state.editPost}
          />
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(
  firestoreConnect(),
  connect(state => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile
    }
  }),
  withMobileDialog(),
  withSnackbar
)(PostDetail)
