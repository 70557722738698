import React, { Component } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import Filter from './Filter'
import Detail from './Detail'

class Directory extends Component {
  render() {
    return (
      <Switch>
        <Route path="/clients/:id" component={Detail} />
        <Route exact path="/clients" component={Filter} />
      </Switch>
    )
  }
}

export default Directory
