import React, { Component } from 'react'
import { Typography, Divider } from '@material-ui/core'

import OfficeList from '../../office/components/OfficeList'

class ReportOverview extends Component {
  render() {
    console.log(this.props)
    return (
      <div className="community-overview" style={{ padding: '32px 24px', margin: '0 auto', maxWidth: 1000 }}>
        <Typography variant="h5">Select a Location</Typography>
        <Typography style={{ marginBottom: 16 }} gutterBottom variant="body1" color="textSecondary">
          Select a location to view and generate reports.
        </Typography>
        <Divider style={{ marginBottom: 24 }} />
        <OfficeList rootPath={this.props.location.pathname.split('/')[1]} />
      </div>
    )
  }
}

export default ReportOverview
