import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

class LoginForm extends Component {
  state = {
    email: '',
    password: '',
    rememberMe: false,
    showPassword: false
  }
  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value })
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }))
  }
  handleLogin = e => {
    e.preventDefault()
    const firebase = this.props.firebase
    this.state.rememberMe
      ? firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      : firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    this.props.firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(res => {
        this.props.enqueueSnackbar('Successfully Signed In', { variant: 'success' })
      })
      .catch(err => {
        console.log(err)
        this.props.enqueueSnackbar(err.message, { variant: 'error' })
      })
  }
  render() {
    const { classes } = this.props
    return (
      <form className="form" onSubmit={this.handleLogin}>
        <FormControl margin="normal" fullWidth>
          <TextField
            type="email"
            variant="outlined"
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={this.handleChange('email')}
            label="Email"
            required
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <TextField
            variant="outlined"
            type={this.state.showPassword ? 'text' : 'password'}
            label="Password"
            value={this.state.password}
            onChange={this.handleChange('password')}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </FormControl>
        <FormControlLabel
          margin="normal"
          control={
            <Switch
              checked={this.state.rememberMe}
              onChange={event => {
                this.setState({ rememberMe: event.target.checked })
              }}
              value="rememberMe"
            />
          }
          label="Remember Me"
        />
        <Button size="large" className={classes.submit} type="submit" fullWidth variant="contained" color="primary">
          Sign in
        </Button>
        <Button
          onClick={() => this.props.history.push('/login/forgot-password')}
          size="medium"
          className={classes.submit}
        >
          Forgot your Password?
        </Button>
      </form>
    )
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  withSnackbar,
  firestoreConnect(),
  connect()
)(LoginForm)
