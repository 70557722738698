import React from 'react'
import {
  Grid,
  Divider,
  Button,
  Dialog,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Icon,
  Select,
  MenuItem,
} from '@material-ui/core'
import ProductForm from './components/ProductForm'
import { compose } from 'redux'
import { withSnackbar } from 'notistack'

class Products extends React.Component {
  state = {
    fetching_products: [],
    product_modal_open: false,
    update_product: null,
    filtered_category: 'all',
  }
  toggleModal = (update_product = null) => {
    this.setState((state) => ({
      product_modal_open: !state.product_modal_open,
      update_product,
    }))
  }
  deleteItem = async (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      await this.props.firestore.collection('honorMarket').doc(id).delete()
      this.props.enqueueSnackbar(`Product deleted.`, {
        variant: 'success',
      })
    }
  }
  render() {
    let products = this.props.products
    if (this.state.filtered_category !== 'all') {
      products = products.filter((p) =>
        p.categories.includes(this.state.filtered_category)
      )
    }
    return (
      <React.Fragment>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Select
                  value={this.state.filtered_category}
                  onChange={(e) =>
                    this.setState({ filtered_category: e.target.value })
                  }
                  variant="outlined"
                >
                  <MenuItem value={'all'}>All Items</MenuItem>
                  {this.props.categories.map((cat) => (
                    <MenuItem value={cat.id} key={cat.id}>
                      {cat.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <Button
                  variant="outlined"
                  color="default"
                  onClick={() => this.toggleModal()}
                >
                  Add new product
                </Button>
              </div>
            </div>
            <Divider style={{ marginTop: 8 }} />
          </Grid>
          {products.map((p) => {
            return (
              <Grid key={p.id} item xs={12} sm={6} lg={4} xl={3}>
                <Card className="card-container">
                  <CardMedia
                    image={p.image}
                    style={{
                      height: 180,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                    }}
                  />
                  <CardContent style={{ paddingBottom: 8, flex: 1 }}>
                    <Typography variant="h6">{p.name}</Typography>
                    <Typography variant="body1">
                      {Number(p.price / 100).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </Typography>
                    <div>
                      {p.categories.map((cat) => {
                        const catObject = this.props.categories.filter(
                          (c) => c.id === cat
                        )
                        if (catObject.length) {
                          return (
                            <Typography
                              key={cat}
                              className="product-category"
                              variant="caption"
                            >
                              {
                                this.props.categories.filter(
                                  (c) => c.id === cat
                                )[0].title
                              }
                            </Typography>
                          )
                        } else {
                          return (
                            <Typography variant="caption" color="secondary">
                              Category deleted
                            </Typography>
                          )
                        }
                      })}
                    </div>
                  </CardContent>
                  <CardActions
                    style={{
                      padding: 4,
                      borderTop: '1px solid #ccc',
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Button
                        onClick={() => this.toggleModal(p)}
                        size="small"
                        style={{ minWidth: 0 }}
                      >
                        Edit
                      </Button>
                    </div>
                    <Button
                      onClick={() => this.deleteItem(p.id)}
                      size="small"
                      style={{ minWidth: 0 }}
                    >
                      <Icon>delete</Icon>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )
          })}
        </Grid>
        <Dialog maxWidth={'sm'} fullWidth open={this.state.product_modal_open}>
          <ProductForm
            firestore={this.props.firestore}
            firebase={this.props.firebase}
            categories={this.props.categories}
            onClose={this.toggleModal}
            update_product={this.state.update_product}
          />
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(withSnackbar)(Products)
