import React, { Component } from 'react'
import {
  Grid,
  Typography,
  withMobileDialog,
  CircularProgress,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  Button,
  Dialog
} from '@material-ui/core'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'

import Hit from './Hit'
import AddClient from './AddClient'

class EmployeeList extends Component {
  state = {
    data: [],
    loading: true,
    error: false,
    toggleClientModal: false
  }
  componentDidMount() {
    this.mounted = true
    this.getData()
  }
  componentWillUnmount() {
    this.mounted = false
    if (this.listener) this.listener()
  }
  getData = () => {
    if (this.listener) this.listener()
    this.setState({ data: null })

    this.listener = this.props.firestore
      .collection('profiles')
      .where('company.value', '==', this.props.user.id)
      .onSnapshot(docs => {
        if (this.mounted && !docs.empty) {
          let data = []
          docs.forEach(doc => {
            data.push({
              objectID: doc.id,
              ...doc.data(),
              name: `${doc.data().first_name} ${doc.data().last_name}`
            })
          })
          this.setState({
            data,
            loading: false
          })
        } else {
          this.setState({
            error: true,
            loading: false
          })
        }
      })
  }
  setPrimary = _.debounce(user => {
    console.log(user)
    this.props.firestore
      .collection('profiles')
      .doc(this.props.user.id)
      .update({
        primary_contact: {
          email_address: user.email_address,
          id: user.objectID
        }
      })
  }, 360)
  toggleClientModal = () => {
    this.setState({
      toggleClientModal: !this.state.toggleClientModal
    })
  }
  render() {
    const { fullScreen } = this.props
    return (
      <div className="booking-list-container">
        <Grid container>
          <Grid
            item
            xs={12}
            style={{ flex: 1, alignItems: 'center', display: 'flex' }}
          >
            <Typography variant="h6" style={{ flex: 1, paddingRight: 16 }}>
              Employees for {this.props.label}
            </Typography>
            <Button color="secondary" onClick={this.toggleClientModal}>
              Add Client
            </Button>
          </Grid>
          <Grid item xs={12}>
            {this.state.loading ? (
              <CircularProgress />
            ) : this.state.error ? (
              <Typography>
                There was an error fetching employees for {this.props.label}
              </Typography>
            ) : this.state.data.length ? (
              <Paper
                elevation={0}
                className="overflow-table"
                style={{ width: '100%' }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: '4px 10px 4px 20px' }}>
                        Name
                      </TableCell>
                      <TableCell style={{ padding: '4px 10px' }} align="right">
                        Room
                      </TableCell>
                      <TableCell style={{ padding: '4px 10px' }} align="right">
                        Floor
                      </TableCell>
                      <TableCell
                        style={{ padding: '4px 10px 4px 30px' }}
                        align="right"
                      >
                        Primary Contact
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.data.map(hit => {
                      return (
                        <Hit
                          hideLocation
                          appendCells={hovering => {
                            return (
                              <TableCell
                                align="right"
                                style={{ padding: '4px 10px 4px 30px' }}
                              >
                                {hit.objectID === this.props.primary_contact ? (
                                  <div
                                    style={{
                                      padding: '4px 8px',
                                      minWidth: 64,
                                      fontSize: 0.8125
                                    }}
                                  >
                                    <Icon color="primary">star</Icon>
                                  </div>
                                ) : (
                                  <Button
                                    style={{
                                      visibility: hovering
                                        ? 'visible'
                                        : 'hidden'
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.setPrimary(hit)
                                    }}
                                    size="small"
                                  >
                                    Make Primary
                                  </Button>
                                )}
                              </TableCell>
                            )
                          }}
                          key={hit.objectID}
                          hit={hit}
                          default_location={hit.default_location.label}
                        />
                      )
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Typography>
                There are no users assigned to {this.props.label}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Dialog
          maxWidth={'lg'}
          fullWidth
          fullScreen={fullScreen}
          onBackdropClick={this.toggleClientModal}
          className="add-user-modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.toggleClientModal}
        >
          <AddClient
            location={this.props.user.default_location.value}
            company={this.props.user.id}
            handleClose={this.toggleClientModal}
            label={`Add New Client to ${this.props.user.company_name}`}
          />
        </Dialog>
      </div>
    )
  }
}

export default compose(firestoreConnect(), withMobileDialog())(EmployeeList)
