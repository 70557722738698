import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'
import axios from 'axios'
import { withSnackbar } from 'notistack'
import moment from 'moment'

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  Divider,
  InputBase,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Icon
} from '@material-ui/core'

import { FunctionsDir } from './../../../config/firebase'

import Comment from './Comment'

class Comments extends Component {
  state = {
    disabled: true,
    index: 0,
    lastDoc: null,
    data: [],
    loadMore: true,
    allDataLoaded: false,
    editPost: false,
    submitted: false,
    post_content: '',
    anchorEl: null,
    selectedComment: null
  }
  openDialog = (event, selectedComment) => {
    this.setState({ anchorEl: event.currentTarget, selectedComment })
  }
  closeDialog = () => {
    this.setState({ anchorEl: null, selectedComment: null })
  }
  componentDidMount() {
    this.mounted = true
    this.getData()
  }
  toggleComment = () => {
    let array = [...this.state.data]
    const selectedComment = array.findIndex(arr => arr.id === this.state.selectedComment.id)
    array[selectedComment].disabled = !array[selectedComment].disabled
    this.setState(
      {
        data: array
      },
      () => {
        console.log(this.state.selectedComment)
        this.props.firestore
          .collection('comments')
          .doc(this.state.selectedComment.id)
          .update({
            disabled: this.state.selectedComment.disabled
          })
          .then(() => {
            this.props.enqueueSnackbar(`Comment Disabled.`, {
              variant: 'success'
            })
            this.closeDialog()
          })
      }
    )
  }
  componentWillUnmount() {
    this.mounted = false
  }
  getData = (clear = false) => {
    if (this.state.allDataLoaded && !clear) {
      console.log('all tax items loaded')
      return
    }
    let query = this.props.firestore
      .collection('comments')
      .where('post', '==', this.props.post.id)
      .orderBy('created_on', 'desc')
    if (this.state.lastDoc !== null && !clear) {
      query = query.startAfter(this.state.lastDoc)
    }
    query = query.limit(5)
    query.get().then(docs => {
      let data = clear ? [] : this.state.data
      docs.forEach(doc => {
        data.push({ ...doc.data(), id: doc.id })
      })
      console.log(data)
      if (this.mounted) {
        this.setState({
          data,
          lastDoc: docs.docs[docs.docs.length - 1],
          allDataLoaded: docs.docs[docs.docs.length - 1] === undefined ? true : false,
          loadMore: false
        })
      }
    })
  }
  hitBottom = _.debounce(() => {
    if (this.state.loadMore) return

    this.setState({ loadMore: true }, () => {
      this.getData()
    })
  }, 350)
  submitComment = e => {
    e.preventDefault()
    this.setState(
      {
        submitted: true
      },
      () => {
        this.props.firestore
          .collection('comments')
          .add({
            post_content: this.state.post_content,
            author: {
              uid: this.props.auth.uid,
              image: this.props.profile.image,
              name: `${this.props.profile.first_name} ${this.props.profile.last_name}`,
              role: this.props.profile.role
            },
            disabled: false,
            community: this.props.community,
            post: this.props.post.id,
            created_on: this.props.firebase.firestore.FieldValue.serverTimestamp()
          })
          .then(res => {
            console.log(res)
            this.props.enqueueSnackbar(`Comment Submitted.`, {
              variant: 'success'
            })
            this.setState(
              {
                submitted: false,
                post_content: ''
              },
              () => {
                this.getData(true)
              }
            )
          })
          .catch(error => {
            console.log(error)
            this.setState({
              submitted: false
            })
          })
      }
    )
  }
  render() {
    return (
      <React.Fragment>
        <List
          style={{
            minHeight:
              61 * (this.props.post.comment_count + 1) + 81 > 385 ? 385 : 61 * this.props.post.comment_count + 81
          }}
        >
          {this.state.data.length ? (
            this.state.data.map((comment, i) => {
              return <Comment key={comment.id} row={i} comment={comment} openDialog={this.openDialog} />
            })
          ) : (
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body2" color="textSecondary">
                    There are no comments
                  </Typography>
                }
              />
            </ListItem>
          )}
          {this.state.data.length && !this.state.allDataLoaded ? (
            <ListItem>
              <Button color="primary" onClick={this.hitBottom} disabled={this.state.loadMore}>
                Load More
              </Button>
            </ListItem>
          ) : null}
          <ListItem style={{ flexWrap: 'wrap' }}>
            <Divider style={{ flex: '0 0 100%', marginBottom: 16 }} />
            <form onSubmit={this.submitComment} style={{ display: 'flex', flex: 1 }}>
              <Avatar src={this.props.profile.image} style={{ marginRight: 16 }} />
              <InputBase
                value={this.state.post_content}
                onChange={e => this.setState({ post_content: e.target.value })}
                multiline
                placeholder="Leave a Comment"
                fullWidth
                required
                style={{ marginRight: 16 }}
              />
              <Button disabled={this.state.submitted} variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </form>
          </ListItem>
        </List>
        <Menu
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.closeDialog}
          id="edit-comment"
        >
          <MenuItem
            onClick={() => {
              this.toggleComment()
            }}
          >
            {this.state.selectedComment !== null && this.state.selectedComment.disabled ? 'Enable' : 'Disable'}
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }
}

export default compose(
  firestoreConnect(),
  connect(state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile
  })),
  withSnackbar
)(Comments)
