import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Typography, Divider } from '@material-ui/core'

import OfficeList from '../../office/components/OfficeList'

class Overview extends Component {
  render() {
    return (
      <div className="office-overview" style={{ padding: '32px 24px', margin: '0 auto', maxWidth: 1000 }}>
        <Redirect from="/companies" to="/clients" />
        <Typography variant="h5">Select a Location</Typography>
        <Typography style={{ marginBottom: 16 }} gutterBottom variant="body1" color="textSecondary">
          Select a location to view and edit it's companies
        </Typography>
        <Divider style={{ marginBottom: 24 }} />
        <OfficeList rootPath="companies" />
      </div>
    )
  }
}

export default Overview
