import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { NavLink, withRouter, Redirect } from 'react-router-dom'
import _ from 'lodash'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Zoom from '@material-ui/core/Zoom'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

import AddLocation from './AddLocation'

class OfficeList extends Component {
  state = { createOffice: false }
  toggleCreateOffice = () => {
    this.setState({
      createOffice: !this.state.createOffice
    })
  }
  render() {
    if (isLoaded(this.props.locations) && isLoaded(this.props.profile)) {
      let locations = this.props.locations || []
      if (this.props.profile.role !== 'admin') {
        locations = _.filter(this.props.locations, loc => {
          return this.props.profile.locations && this.props.profile.locations[loc.id]
        })
      }
      if (locations.length === 1) {
        return <Redirect to={`/${this.props.rootPath}/${locations[0].id}`} />
      }
      return (
        <div className="office-list">
          {this.props.profile.role === 'admin' && (
            <Zoom in>
              <Tooltip title="Add New Location">
                <Fab
                  onClick={this.toggleCreateOffice}
                  className="main-action-fab"
                  size="large"
                  color="secondary"
                  style={{ zIndex: 1000 }}
                >
                  <Icon>add_location</Icon>
                </Fab>
              </Tooltip>
            </Zoom>
          )}
          <Grid container spacing={24}>
            {locations.map(location => {
              return (
                <Grid item xs={12} sm={6} md={4} key={location.id}>
                  <Card>
                    <CardActionArea to={`/${this.props.rootPath}/${location.id}`} component={NavLink}>
                      <CardMedia image={location.image} style={{ height: 160 }} />
                      <CardContent>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                          <Typography gutterBottom variant="h5" component="h2">
                            {location.name}
                          </Typography>
                          {location.active ? null : (
                            <Icon style={{ opacity: 0.6 }} color="action">
                              domain_disabled
                            </Icon>
                          )}
                        </div>
                        <Typography component="p" color="textSecondary">
                          {location.address}
                          <br />
                          {location.city}, {location.state} {location.zip}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
          {this.props.profile.role === 'admin' && (
            <AddLocation toggleOpen={this.toggleCreateOffice} open={this.state.createOffice} />
          )}
        </div>
      )
    }
    return (
      <div className="splash-loader">
        <CircularProgress />
      </div>
    )
  }
}

OfficeList.defaultProps = {
  rootPath: 'locations'
}

export default compose(
  withRouter,
  firestoreConnect(),
  connect(state => ({
    profile: state.firebase.profile,
    locations: state.firestore.ordered.locations
  }))
)(OfficeList)
