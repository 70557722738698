import React, { Component } from 'react'
import {
  Paper,
  Button,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  ListItemSecondaryAction,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  Typography
} from '@material-ui/core'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'

import ClientSelect from '../../booking/components/ClientSelect'

const OPTIONS = [
  'Package Notification',
  'Food Delivery',
  'Maintenance Request Update',
  'Guest On-Site',
  'Meeting Request',
  'Payment Past Due',
  'Other'
]

class Notifications extends Component {
  state = {
    client: [],
    title: '',
    message: '',
    submitted: false,
    anchorEl: null,
    isOther: false
  }
  selectClient = c => {
    let client = [...this.state.client]
    client.push(c)
    const filtered = client.filter(
      (s => o => (k => !s.has(k) && s.add(k))(['objectID'].map(k => o[k]).join('|')))(new Set())
    )
    this.setState({
      client: filtered
    })
  }
  removeClient = i => {
    let client = [...this.state.client]
    let filteredClient = client.filter(c => c.objectID !== i)
    this.setState({
      client: filteredClient
    })
  }
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = val => {
    let input = this.input
    let message = this.message
    let title = val
    let isOther = false
    if (val === 'Other') {
      title = ''
      isOther = true
    }
    this.setState({ anchorEl: null, title, isOther }, () => {
      if (isOther) {
        input.focus()
      } else {
        message.focus()
      }
    })
  }
  sendNotification = e => {
    e.preventDefault()
    const confirm = window.confirm(
      'Are you sure you want to send this notification? The user will receive it immediately.'
    )
    if (confirm) {
      let batch = this.props.firestore.batch()
      this.state.client.forEach(c => {
        const ref = this.props.firestore
          .collection('profiles')
          .doc(c.objectID)
          .collection('notifications')
          .doc()
        batch.set(ref, {
          title: this.state.title,
          message: this.state.message,
          dismissed: false,
          type: 1,
          sent: this.props.firebase.firestore.FieldValue.serverTimestamp(),
          sent_by: {
            id: this.props.auth.uid,
            name: `${this.props.profile.first_name} ${this.props.profile.last_name}`
          }
        })
      })
      batch.commit().then(() => {
        this.props.enqueueSnackbar(`Notification Sent.`, {
          variant: 'success'
        })
        this.setState({
          client: [],
          title: '',
          message: '',
          submitted: false,
          isOther: false
        })
      })
    }
  }
  render() {
    const { anchorEl } = this.state
    return (
      <Paper style={{ padding: 16 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.state.client.length ? (
            <List style={{ flex: 3, overflow: 'hidden' }}>
              <ListItem>
                <ListItemText primary="Clients" />
                <ListItemSecondaryAction>
                  <ClientSelect
                    multiSelect={true}
                    activeLocation={this.props.activeLocation}
                    onSelect={this.selectClient}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              {this.state.client.map(c => (
                <ListItem dense key={c.objectID}>
                  <ListItemAvatar src={c.image}>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText primary={c.name} secondary={c.email_address} />
                  <ListItemSecondaryAction onClick={() => this.removeClient(c.objectID)}>
                    <IconButton aria-label="Delete" style={{ width: 36, height: 36, padding: 0 }}>
                      <Icon fontSize={'small'}>delete</Icon>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          ) : (
            <List style={{ flex: 1 }}>
              <ListItem>
                <ListItemText primary="Clients" secondary="No clients selected" />
                <ListItemSecondaryAction>
                  <ClientSelect
                    multiSelect={true}
                    activeLocation={this.props.activeLocation}
                    onSelect={this.selectClient}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          )}
        </div>
        <form onSubmit={this.sendNotification} style={{ marginTop: 16 }}>
          <div style={{ position: 'relative' }}>
            <TextField
              onChange={e => this.setState({ title: e.target.value })}
              fullWidth
              required
              variant="outlined"
              label="Title"
              value={this.state.title}
              style={{ marginBottom: 16 }}
              disabled={!this.state.isOther && !this.state.title.length}
              inputRef={ref => (this.input = ref)}
            />
            {!this.state.title.length && !this.state.isOther ? (
              <div
                style={{
                  position: 'absolute',
                  top: 12,
                  left: 2,
                  right: 2,
                  bottom: 1,
                  overflow: 'scroll',
                  whiteSpace: 'nowrap',
                  zIndex: 10,
                  padding: '0px 8px'
                }}
              >
                {OPTIONS.map(opt => (
                  <Chip key={opt} onClick={() => this.handleClose(opt)} label={opt} style={{ marginRight: 8 }} />
                ))}
              </div>
            ) : null}
            {this.state.title.length || this.state.isOther ? (
              <React.Fragment>
                <IconButton
                  style={{ position: 'absolute', right: 7, top: 6 }}
                  aria-owns={anchorEl ? 'simple-menu' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  <Icon>expand_more</Icon>
                </IconButton>
                <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
                  {OPTIONS.map(opt => (
                    <MenuItem key={opt} onClick={() => this.handleClose(opt)}>
                      {opt}
                    </MenuItem>
                  ))}
                </Menu>
              </React.Fragment>
            ) : null}
          </div>
          <TextField
            onChange={e => {
              this.setState({ message: e.target.value.length > 120 ? this.state.message : e.target.value })
            }}
            fullWidth
            required
            multiline
            variant="outlined"
            label={`Message`}
            value={this.state.message}
            disabled={!this.state.title.length}
            inputRef={ref => (this.message = ref)}
          />
          <Typography variant="caption" color="textSecondary" style={{ paddingTop: 4 }}>
            {this.state.message.length}/120
          </Typography>
          <div style={{ marginTop: 16, marginBottom: 16 }} />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!this.state.client.length || this.state.title.length < 3 || this.state.message.length < 3}
          >
            Send Message
          </Button>
        </form>
      </Paper>
    )
  }
}

export default compose(
  withSnackbar,
  firestoreConnect(),
  connect(state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }))
)(Notifications)
