import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import _ from 'lodash'

import {
  Fab,
  Icon,
  withMobileDialog,
  Dialog,
  Zoom,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core'

import CreateResource from './CreateResource'
import ResourceItem from './ResourceItem'

class PostDetail extends Component {
  state = {
    createPost: false,
    post_content: '',
    submitted: false,
    recentlyCreated: [],
    editPost: null
  }
  componentDidMount() {
    // this.props.firestore
    //   .collection('resources')
    //   .where('location', '==', 'AQwUtmCSnIZzD33j48T8')
    //   .orderBy('order', 'asc')
    //   .orderBy('created_on', 'desc')
    //   .get()
  }
  onChange = e => {
    this.setState({
      post_content: e.target.value
    })
  }
  createPost = (saved = false) => {
    var cont = true
    if (this.state.createPost && saved === false) {
      cont = window.confirm('Are you sure you want to close this window? You have unsaved changes.')
    }
    if (cont) {
      this.setState({
        createPost: !this.state.createPost,
        editPost: null
      })
    }
  }
  edit = resource => {
    this.setState({
      createPost: !this.state.createPost,
      editPost: resource
    })
  }

  render() {
    const { fullScreen } = this.props
    return (
      <React.Fragment>
        <Zoom in>
          <Tooltip title="Create Resource">
            <Fab onClick={() => this.createPost()} className="main-action-fab" size="large" color="secondary">
              <Icon>add_to_photos</Icon>
            </Fab>
          </Tooltip>
        </Zoom>
        <Dialog
          maxWidth="md"
          fullWidth
          fullScreen={fullScreen}
          className="add-user-modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.createPost}
        >
          <CreateResource
            title={this.state.editPost ? 'Update Resource' : 'New Resource'}
            button={this.state.editPost ? 'Update Resource' : 'Create Resource'}
            editResource={this.state.editPost}
            location={this.props.match.params.location}
            createPost={this.createPost}
          />
        </Dialog>
        <div id="resources" className="resources">
          <div className="resources-inner">
            <Paper>
              <Table padding="dense">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 80 }}>Order</TableCell>
                    <TableCell style={{ minWidth: 160 }}>Title</TableCell>
                    <TableCell style={{ minWidth: 120 }}>Created</TableCell>
                    <TableCell style={{ width: 100 }} align="right">
                      Attachments
                    </TableCell>
                    <TableCell style={{ width: 100 }} align="right">
                      Published
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.resources
                    ? _.orderBy(this.props.resources, ['order'], ['asc']).map(res => (
                      <ResourceItem edit={this.edit} key={res.id} resource={res} />
                    ))
                    : null}
                </TableBody>
              </Table>
            </Paper>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default compose(
  firestoreConnect(props => [
    {
      collection: 'resources',
      where: ['location', '==', props.match.params.location],
      orderBy: [['order', 'asc'], ['created_on', 'desc']]
    }
  ]),
  connect(state => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      resources: state.firestore.ordered.resources
    }
  }),
  withMobileDialog(),
  withSnackbar
)(PostDetail)
