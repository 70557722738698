import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

class ResetPassword extends Component {
  state = {
    email: ''
  }
  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value })
  }

  handlePasswordReset = e => {
    e.preventDefault()
    this.props.firebase
      .auth()
      .sendPasswordResetEmail(this.state.email)
      .then(res => {
        // Email sent.
        this.props.enqueueSnackbar('A password reset will be sent to your email shortly.', { variant: 'success' })
      })
      .catch(err => {
        // An error happened.
        console.log(err)
        this.props.enqueueSnackbar(err.message, { variant: 'error' })
      })
  }
  render() {
    const { classes } = this.props
    return (
      <form className="form" onSubmit={this.handlePasswordReset}>
        <FormControl margin="normal" fullWidth>
          <TextField
            type="email"
            variant="outlined"
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={this.handleChange('email')}
            label="Email"
            required
          />
        </FormControl>
        <Button size="large" className={classes.submit} type="submit" fullWidth variant="contained" color="primary">
          Send Password Reset
        </Button>
        <Button onClick={() => this.props.history.push('/login')} size="medium" className={classes.submit}>
          Back to Login
        </Button>
      </form>
    )
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  withSnackbar,
  firestoreConnect(),
  connect()
)(ResetPassword)
