import React from 'react'
import { Fade, Grid } from '@material-ui/core'

import Room from './Room'

export default (props) => {
  if (props.active) {
    return (
      <Fade in={props.active}>
        <div className="step-2">
          <Grid container spacing={16}>
            {props.rooms.map((room) => {
              return (
                <Grid xs={12} sm={6} md={4} key={room.id} item>
                  <Room
                    selectRoom={props.selectRoom}
                    active={props.booking.room.uid === room.id}
                    room={room}
                    isDaily={true}
                  />
                </Grid>
              )
            })}
          </Grid>
        </div>
      </Fade>
    )
  }
  return null
}
