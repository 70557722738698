import React, { Component } from 'react'
import { DateRangePicker } from 'react-dates'
import {
  Grid,
  Typography,
  withMobileDialog,
  Tabs,
  Tab,
  AppBar,
  Paper,
  CircularProgress,
} from '@material-ui/core'
import moment from 'moment'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import BookingList from '../../../components/BookingList'

class BookingData extends Component {
  state = {
    focusedInput: null,
    index: 0,
    lastDoc: null,
    data: [],
    loadMore: true,
    allDataLoaded: false,
    startDate: moment().startOf('week'),
    endDate: moment().endOf('week'),
    bookingType: 0,
    loading: true,
  }
  componentDidMount() {
    this.mounted = true
    this.getData()
  }
  setNewRange = (startDate, endDate) => {
    this.setState({ startDate, endDate, loading: true }, () => {
      if (startDate !== null && endDate !== null) {
        this.getData(true)
      }
    })
  }
  updateData = (bookingType) => {
    this.setState({ bookingType, loading: true }, () => {
      this.getData(true)
    })
  }
  setNewFocus = (focusedInput) => {
    this.setState({
      focusedInput,
    })
  }
  getData = (clear = false) => {
    if (this.state.allDataLoaded && !clear) {
      return
    }
    let query = this.props.firestore
      .collection(this.state.bookingType ? 'dayBookings' : 'bookings')
      .where('date', '>=', Number(this.state.startDate.format('YYYYMMDD')))
      .where('date', '<=', Number(this.state.endDate.format('YYYYMMDD')))

    if (this.props.type === 'user') {
      query = query.where('client.uid', '==', this.props.user)
    } else {
      query = query.where('client.company.id', '==', this.props.user)
    }
    query = query.orderBy('date', 'desc')
    if (this.state.lastDoc !== null && !clear) {
      query = query.startAfter(this.state.lastDoc)
    }
    query = query.limit(40)
    query.get().then((docs) => {
      let data = clear ? [] : this.state.data
      docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      if (this.mounted) {
        this.setState({
          data,
          lastDoc: docs.docs[docs.docs.length - 1],
          allDataLoaded:
            docs.docs[docs.docs.length - 1] === undefined ? true : false,
          loadMore: false,
          loading: false,
        })
      }
    })
  }
  render() {
    const { fullScreen } = this.props
    return (
      <div className="booking-list-container">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={'auto'}
            style={{ flex: 1, alignItems: 'center', display: 'flex' }}
          >
            <Typography variant="h6">
              Bookings for {this.props.label}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={'auto'}
            style={{ position: 'relative', zIndex: 1000 }}
          >
            <DateRangePicker
              startDate={this.state.startDate}
              startDateId="start_date"
              endDate={this.state.endDate}
              endDateId="end_date"
              onDatesChange={({ startDate, endDate }) =>
                this.setNewRange(startDate, endDate)
              }
              focusedInput={this.state.focusedInput}
              onFocusChange={(focusedInput) => this.setNewFocus(focusedInput)}
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel
              // appendToBody
              noBorder
              anchorDirection={'right'}
              orientation={fullScreen ? 'vertical' : 'horizontal'}
              numberOfMonths={fullScreen ? 1 : 2}
              verticalHeight={370}
            />
          </Grid>
          <Grid item xs={12} style={{ paddingRight: 10, paddingBottom: 10 }}>
            <Paper square elevation={1}>
              <Tabs
                value={this.state.bookingType}
                onChange={(e, v) => this.updateData(v)}
                indicatorColor="primary"
              >
                <Tab
                  label={'Conference Rooms'}
                  value={0}
                  style={{ padding: 0 }}
                  size="small"
                />
                <Tab
                  label={'Private Offices'}
                  value={1}
                  style={{ padding: 0 }}
                  size="small"
                />
              </Tabs>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {this.state.loading ? (
              <div style={{ padding: 10, textAlign: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <BookingList
                data={this.state.data}
                isDaily={this.state.bookingType}
                // open={this.openDetail}
              />
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(firestoreConnect(), withMobileDialog())(BookingData)
