import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import { CircularProgress, withMobileDialog } from '@material-ui/core'
import UserDetail from './components/UserDetail'
import CompanyDetail from './components/CompanyDetail'

class Detail extends Component {
  state = {
    user_id: null,
    selectedUser: null,
  }
  componentDidMount() {
    this.mounted = true
    this.getUser()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getUser()
    }
  }
  componentWillUnmount() {
    this.mounted = false
    if (this.listener) this.listener()
  }
  getUser = () => {
    if (this.listener) this.listener()
    this.setState({ selectedUser: null })

    this.listener = this.props.firestore
      .collection('profiles')
      .doc(this.props.match.params.id)
      .onSnapshot((doc) => {
        if (this.mounted && doc.exists) {
          this.setState({
            selectedUser: { id: doc.id, ...doc.data() },
          })
        } else {
          this.setState({
            selectedUser: false,
          })
        }
      })
  }
  render() {
    if (this.state.selectedUser === null) {
      return (
        <div style={{ margin: 40, textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )
    }
    if (this.state.selectedUser === false) {
      return (
        <div style={{ margin: 40, textAlign: 'center' }}>
          there was an issue loading data
        </div>
      )
    }
    if (this.state.selectedUser.type === 'user') {
      return <UserDetail selectedUser={this.state.selectedUser} />
    } else {
      return <CompanyDetail selectedUser={this.state.selectedUser} />
    }
  }
}

export default compose(
  firestoreConnect(),
  connect((state, props) => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
    }
  }),
  withRouter,
  withSnackbar,
  withMobileDialog()
)(Detail)
