import React from 'react'
import Fade from '@material-ui/core/Fade'
import { Typography, List, ListItem, Avatar, ListItemAvatar, ListItemText, Grid, TextField } from '@material-ui/core'

import ClientSelect from './ClientSelect'

export default props => {
  if (props.active) {
    return (
      <Fade in={props.active}>
        <div className="step-1">
          <Grid spacing={16} container>
            <Grid xs={12} item>
              <Typography gutterBottom component="h6" variant="body1" style={{ fontWeight: '500', marginBottom: 16 }}>
                Select a Client
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.booking.client ? (
                  <List style={{ marginRight: 16, borderRight: '1px solid rgba(0,0,0,0.15)' }}>
                    <ListItem>
                      <ListItemAvatar src={props.booking.client.image}>
                        <Avatar />
                      </ListItemAvatar>
                      <ListItemText
                        primary={props.booking.client.display_name}
                        secondary={props.booking.client.email_address}
                      />
                    </ListItem>
                  </List>
                ) : (
                  <Typography style={{ marginRight: 10 }} variant="subtitle1">
                    No Client Selected
                  </Typography>
                )}
                <ClientSelect dontExcludeByLocation={true} activeLocation={props.activeLocation} onSelect={props.selectClient} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom component="h6" variant="body1" style={{ fontWeight: '500', marginBottom: 16 }}>
                Notes
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Notes"
                label="Notes"
                type="text"
                name="notes"
                rows={2}
                fullWidth
                multiline
                onChange={e => {
                  props.leaveNotes(e.target.value)
                }}
                value={props.booking.notes}
              />
            </Grid>
          </Grid>
        </div>
      </Fade>
    )
  }
  return null
}
