import types from '../types'

export const setLocation = location => dispatch => {
  dispatch({
    type: types.SET_LOCATION,
    payload: location
  })
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.SET_LOCATION:
      return action.payload
    default:
      return state
  }
}

export default reducer
