import React, { Component } from 'react'
import { Paper, Button } from '@material-ui/core'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import BookingList from '../../../components/BookingList'

class ListItems extends Component {
  state = {
    index: 0,
    lastDoc: null,
    data: [],
    loadMore: true,
    allDataLoaded: false,
    focusedInput: null,
    startDate: moment().startOf('week'),
    endDate: moment().endOf('week'),
  }
  componentDidMount() {
    this.mounted = true
    this.getData()
  }
  setNewRange = (startDate, endDate) => {
    console.log(startDate, endDate)
    this.setState({ startDate, endDate }, () => {
      if (startDate !== null && endDate !== null) {
        this.getData(true)
      }
    })
  }
  setNewFocus = (focusedInput) => {
    this.setState({
      focusedInput,
    })
  }
  componentWillUnmount() {
    this.mounted = false
  }
  getData = (clear = false) => {
    if (this.state.allDataLoaded && !clear) {
      console.log('all tax items loaded')
      return
    }
    let query = this.props.firestore
      .collection(this.props.isDaily ? 'dayBookings' : 'bookings')
      .where('location.id', '==', this.props.activeLocation.id)
      // .where('canceled', '==', false)
      .where('date', '>=', Number(this.state.startDate.format('YYYYMMDD')))
      .where('date', '<=', Number(this.state.endDate.format('YYYYMMDD')))
      .orderBy('date', 'asc')
    if (this.state.lastDoc !== null && !clear) {
      query = query.startAfter(this.state.lastDoc)
    }
    query = query.limit(40)
    query.get().then((docs) => {
      let data = clear ? [] : this.state.data
      docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      console.log(data)
      if (this.mounted) {
        this.setState({
          data,
          lastDoc: docs.docs[docs.docs.length - 1],
          allDataLoaded: docs.docs.length < 40,
          loadMore: false,
        })
      }
    })
  }
  render() {
    return (
      <React.Fragment>
        <Paper
          elevation={6}
          style={{
            position: 'sticky',
            top: 0,
            left: 0,
            right: 0,
            paddingTop: 8,
            paddingBottom: 8,
            backgroundColor: '#fff',
            zIndex: 3,
          }}
        >
          <DateRangePicker
            startDate={this.state.startDate}
            startDateId="start_date"
            endDate={this.state.endDate}
            endDateId="end_date"
            onDatesChange={({ startDate, endDate }) =>
              this.setNewRange(startDate, endDate)
            }
            focusedInput={this.state.focusedInput}
            onFocusChange={(focusedInput) => this.setNewFocus(focusedInput)}
            isOutsideRange={() => false}
            hideKeyboardShortcutsPanel
            appendToBody
            noBorder
          />
        </Paper>
        <BookingList
          timezone={this.props.activeLocation.timezone.value}
          locationID={this.props.activeLocation.id}
          data={this.state.data}
          open={this.props.openDetail}
          isDaily={this.props.isDaily || false}
        />
        {this.state.allDataLoaded ? null : (
          <div style={{ padding: 20 }}>
            <Button
              fullWidth
              variant={'contained'}
              size={'small'}
              onClick={() => this.getData()}
            >
              Load More
            </Button>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default ListItems
