import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import ReportOverview from './components/ReportOverview'
import ReportDetail from './components/ReportDetail'
import DayReportDetail from './components/DayReportDetail'
import ClientReportDetail from './components/ClientReportDetail'

class Community extends Component {
  render() {
    return (
      <Switch>
        <Route path="/reports-clients" component={ClientReportDetail} />
        <Route path="/reports-bookings/:location" component={ReportDetail} />
        <Route
          path="/reports-privateoffices/:location"
          component={DayReportDetail}
        />
        <Route exact path="/reports-bookings" component={ReportOverview} />
        <Route
          exact
          path="/reports-privateoffices"
          component={ReportOverview}
        />
        <Redirect path="/reports" to="/reports-bookings" />
      </Switch>
    )
  }
}

export default Community
