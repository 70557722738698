import React, { Component } from 'react'
import { TextField } from '@material-ui/core'

const VALID_FIRST = /^[1-9]{1}$/
const VALID_NEXT = /^[0-9]{1}$/
const DELETE_KEY_CODE = 8

class CurrencyInput extends Component {
  handleKeyDown = (e) => {
    const { key, keyCode } = e
    if (
      (this.props.value === 0 && !VALID_FIRST.test(key)) ||
      (this.props.value !== 0 &&
        !VALID_NEXT.test(key) &&
        keyCode !== DELETE_KEY_CODE)
    ) {
      return
    }
    const valueString = this.props.value.toString()
    let nextValue
    if (keyCode !== DELETE_KEY_CODE) {
      const nextValueString =
        this.props.value === 0 ? key : `${valueString}${key}`
      nextValue = Number.parseInt(nextValueString, 10)
    } else {
      const nextValueString = valueString.slice(0, -1)
      nextValue =
        nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10)
    }
    if (nextValue > (this.props.max || Number.MAX_SAFE_INTEGER)) {
      return
    }
    this.props.onValueChange(nextValue)
  }
  render() {
    const valueAbsTrunc = Math.trunc(Math.abs(this.props.value))
    const valueDisplay = (this.props.value / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    if (
      this.props.value !== valueAbsTrunc ||
      !Number.isFinite(this.props.value) ||
      Number.isNaN(this.props.value)
    ) {
      throw new Error(`invalid value property`)
    }
    return (
      <TextField
        required
        label="Price"
        fullWidth
        variant="outlined"
        inputMode="numeric"
        onChange={() => {}}
        onKeyDown={this.handleKeyDown}
        value={valueDisplay}
        margin={'normal'}
      />
    )
  }
}

export default CurrencyInput
