import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withMobileDialog } from '@material-ui/core'

import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'

import PublicResourcesHeader from './PublicResourcesHeader'
import IconNavigateNext from '@material-ui/icons/NavigateNext'

class PublicResourcesOverview extends Component {
  state = {
    maxWidth: 840,
    locationName: '',
    resources: [],
  }

  componentDidMount() {
    this.mounted = true
    this.getData()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  getData = () => {
    const queryLocation = this.props.firestore
      .collection('locations')
      .doc(this.props.match.params.locationId)
      .get()
    const queryResources = this.props.firestore
      .collection('resources')
      .where('location', '==', this.props.match.params.locationId)
      .orderBy('order', 'asc')
      .orderBy('created_on', 'desc')
      .get()

    Promise.all([ queryLocation, queryResources ])
      .then((snapshots) => {
        const snapshotLocation = snapshots[0]
        const snapshotResources = snapshots[1]

        if (this.mounted && !snapshotResources.empty) {
          const data = snapshotResources.docs.map((element) => {
            return {
              id: element.id,
              ...element.data(),
            }
          })
          this.setState({ resources: data, })
        }
        if (this.mounted && !snapshotLocation.empty) {
          const locationName = snapshotLocation.data().name
          this.setState({ locationName })
        }
      })
  }

  render() {
    return (
      <div className="resources-public">
        <PublicResourcesHeader
          title={'Resources'}
          subtitle={this.state.locationName || '\u00a0'}
          maxWidth={this.state.maxWidth}
          titleFontSize="h4"
          />
        <Grid
          container
          alignItems="flex-start"
          direction="row"
          spacing={8}
          style={{
            margin: '0 auto',
            padding: '18px 8px',
            width: '100%',
            maxWidth: this.state.maxWidth,
          }}
          >
          {this.state.resources
            ? this.state.resources.map((element, index) => (
              <Route
                key={index}
                render={({ history }) => (
                  <PublicResourcesOverviewItem
                    icon={element.icon}
                    title={element.title}
                    history={history}
                    link={`/resources-public/${this.props.match.params.locationId}/${element.id}`}
                    />
                  )} />
            ))
          : 'Nothing here =(' /* TODO Conditionally render empty component */}
        </Grid>
      </div>
    )
  }
}

class PublicResourcesOverviewItem extends Component {
  followLink = () => {
    this.props.history.push(this.props.link)
  }

  render() {
    return (
      <Grid
        item
        xs={12}
        sm={6}>
        <Paper
          onClick={this.followLink}
          color="primary"
          elevation={0}
          style={{
            backgroundColor: '#f8f3f3',
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 15,
            paddingRight: 10,
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          >
          <img
            src={this.props.icon}
            alt="Resource icon"
            style={{
              // Icons are base-64-encoded PNGs black on transparent background
              // Only easy way to make them match text color is this CSS filter
              // calculated via https://codepen.io/sosuke/pen/Pjoqqp
              // TODO alternative https://stackoverflow.com/questions/13419101/is-it-possible-to-replace-a-color-in-a-base-64-encoded-image
              // TODO Not 100% precise, pure SVG icons would fix that plus other benefits
              filter: 'invert(34%) sepia(8%) saturate(984%) hue-rotate(291deg) brightness(101%) contrast(92%)',
              width: '1.5rem',
            }}
            />
          <Typography
            variant="caption"
            color="primary"
            style={{
              flexGrow: 1,
              marginLeft: 15,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            >
            {this.props.title || 'N/A'}
          </Typography>
          <IconNavigateNext
            color="primary"
            />
        </Paper>
      </Grid>
    )
  }
}

export default compose(
  withMobileDialog(),
  firestoreConnect(),
  connect((state) => ({
      auth: state.firebase.auth,
      profile: state.firebase.profile,
  })),
)(PublicResourcesOverview)
