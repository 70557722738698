import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import axios from 'axios'
import { withSnackbar } from 'notistack'
import moment from 'moment'
import {
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Avatar,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  withMobileDialog,
  Dialog,
  Menu,
  MenuItem,
  Button,
  AppBar,
  Toolbar,
  Paper,
} from '@material-ui/core'

import { FunctionsDir } from '../../../config/firebase'

import EditClient from './EditClient'
import BookingData from './BookingData'

class UserDetail extends Component {
  state = {
    editUser: false,
    tab: 0,
    metadata: null,
    anchorEl: null,
  }
  toggleEditUser = () => {
    this.setState({
      editUser: !this.state.editUser,
    })
  }
  toggleUserDirectory = (user) => {
    const confirm = window.confirm(
      user.show_in_directory
        ? 'This will remove the user from the directory. Do you wish to continue?'
        : 'This will add the user to the directory. Do you wish to continue?'
    )

    if (confirm) {
      this.props.firestore
        .collection('profiles')
        .doc(this.props.selectedUser.id)
        .update({
          show_in_directory: !user.show_in_directory,
          profile_updated: new Date(),
        })
        .then(() => {
          this.props.enqueueSnackbar(
            `${user.first_name} ${user.last_name} has been ${
              !user.show_in_directory ? 'added to' : 'removed from'
            } the directory.`,
            {
              variant: 'info',
            }
          )
        })
    }
  }
  toggleUserEnabled = (user) => {
    console.log(user, this.props.selectedUser.id)
    const confirm = window.confirm(
      !user.disabled
        ? 'This user will lose access to their acccount on the Expansive App, do you with to continue?'
        : 'Enabling this user will restore their access to the Expansive App, they will be able to book rooms and engage with the community. Do you with to continue?'
    )

    if (confirm) {
      this.props.firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => {
          console.log(token)
          return axios.post(
            `${FunctionsDir}/novel/disableAccount`,
            {
              body: {
                user: { ...user, uid: this.props.selectedUser.id },
                user_id: this.props.auth.uid,
                location: this.props.selectedUser.default_location.value,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'application/octet-stream',
              },
            }
          )
        })
        .then((res) => {
          console.log(res)
          this.props.enqueueSnackbar(
            `The status of ${user.first_name} ${user.last_name} has been updated.`,
            {
              variant: 'info',
            }
          )
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
  resetPassword = (user) => {
    const confirm = window.confirm(
      'Are you sure you want to send a password reset email?'
    )
    if (confirm) {
      this.props.firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => {
          console.log(token)
          return axios.post(
            `${FunctionsDir}/novel/resetPassword`,
            {
              body: {
                user: { ...user, uid: this.props.selectedUser.id },
                user_id: this.props.auth.uid,
                location: this.props.selectedUser.default_location.value,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'application/octet-stream',
              },
            }
          )
        })
        .then((res) => {
          this.setState(
            {
              reset_link: res.data.reset_link,
            },
            () => {
              this.props.enqueueSnackbar(`Password Reset Sent.`, {
                variant: 'info',
              })
            }
          )
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, reset_link: null })
  }

  handleClose = () => {
    this.setState({ anchorEl: null, reset_link: null })
  }
  confirmUser = () => {
    const confirm = window.confirm(
      'Are you sure you want to confirm this account?'
    )
    if (confirm) {
      this.props.firestore
        .collection('profiles')
        .doc(this.props.selectedUser.id)
        .update({ verified: true })
        .then(() => {
          this.props.enqueueSnackbar(`User activated.`, {})
        })
    }
  }
  removeUser = () => {
    const confirm = window.confirm(
      'Are you sure you want to delete this account?'
    )
    if (confirm) {
      let body = {
        user_id: this.props.firebase.auth().currentUser.uid,
        userData: this.props.selectedUser.id,
      }
      this.props.firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => {
          console.log(token)
          return axios.post(
            `${FunctionsDir}/novel/deleteClient`,
            {
              body,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'application/octet-stream',
              },
            }
          )
        })
        .then((res) => {
          console.log(res)
          this.props.enqueueSnackbar(`User deleted.`, {
            variant: 'info',
          })
          window.location.replace('/clients')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
  render() {
    const { fullScreen } = this.props
    if (this.props.selectedUser === null) {
      return <CircularProgress />
    }
    if (this.props.selectedUser === false) {
      return <div>there was an issue loading data</div>
    }
    const user = this.props.selectedUser
    const hasErrors =
      typeof user.company !== 'object' ||
      user.company === null ||
      user.floor_number === undefined ||
      user.floor_number === '' ||
      user.office_number === undefined ||
      user.office_number === ''
    return (
      <React.Fragment>
        {!user.verified && (
          <Paper elevation={0} className="pending-box">
            <AppBar
              elevation={0}
              position="static"
              color="inherit"
              // classes={{
              //   root: 'dark-theme'
              // }}
            >
              <Toolbar variant="dense" style={{ padding: 10 }}>
                <Grid container>
                  <Grid
                    xs={12}
                    sm={'auto'}
                    item
                    style={{ flexGrow: 1, alignSelf: 'center' }}
                  >
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{
                        flexGrow: 1,
                        fontWeight: '500',
                        letterSpacing: 1,
                      }}
                    >
                      Pending Approval
                    </Typography>
                    {hasErrors && (
                      <React.Fragment>
                        <Typography
                          variant="body2"
                          style={{ flexGrow: 1, letterSpacing: 0.5 }}
                        >
                          Please make the following updates to this client
                        </Typography>
                        <ul>
                          {(typeof user.company !== 'object' ||
                            user.company === null) && <li>Assign a company</li>}
                          {(user.floor_number === undefined ||
                            user.floor_number === '') && (
                            <li>Assign a floor number</li>
                          )}
                          {(user.office_number === undefined ||
                            user.office_number === '') && (
                            <li>Assign a room number</li>
                          )}
                        </ul>
                      </React.Fragment>
                    )}
                  </Grid>
                  <Grid xs={12} sm={'auto'} item>
                    <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={this.removeUser}
                      style={{ marginRight: 8 }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      disabled={hasErrors}
                      onClick={this.confirmUser}
                    >
                      Approve
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Paper>
        )}
        <div className="user-detail">
          <Grid container alignItems="center" direction="row-reverse">
            <Grid
              item
              xs={12}
              sm={5}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {this.props.profile.role === 'admin' ||
              (this.props.profile.locations &&
                this.props.profile.locations[user.default_location.value] ===
                  true) ? (
                <React.Fragment>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={user.show_in_directory}
                          onChange={() => this.toggleUserDirectory(user)}
                        />
                      }
                      label="Show in Directory"
                      labelPlacement="end"
                    />
                  </FormGroup>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={this.toggleEditUser}
                  >
                    Edit User
                  </Button>
                  <IconButton
                    aria-owns={this.state.anchorEl ? 'edit-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <Menu
                    id="edit-menu"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                  >
                    <MenuItem>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={!user.disabled}
                              onChange={() => this.toggleUserEnabled(user)}
                            />
                          }
                          label="Account Enabled"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </MenuItem>
                    {this.state.reset_link ? (
                      <div>
                        <textarea
                          style={{
                            width: '100%',
                            overflow: 'hidden',
                            minHeight: 100,
                            border: 0,
                            outline: '1px solid #ccc',
                            fontSize: 10,
                            padding: 8,
                          }}
                          onClick={(e) => e.target.select()}
                          onFocus={(e) => e.target.select()}
                          value={this.state.reset_link}
                          readOnly
                        />
                        <Typography
                          style={{ padding: 8, borderBottom: '1px solid #ccc' }}
                          variant="caption"
                          color="textSecondary"
                        >
                          If your client is haivng troubles receiving the
                          password reset email send this link directly to them
                          to reset their password.
                        </Typography>
                      </div>
                    ) : (
                      <MenuItem onClick={() => this.resetPassword(user)}>
                        Send Password Reset
                      </MenuItem>
                    )}
                  </Menu>
                </React.Fragment>
              ) : null}
            </Grid>
            <Grid
              xs={12}
              sm={7}
              item
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Avatar
                src={user.image}
                style={{
                  width: 42,
                  height: 42,
                  marginRight: 16,
                  marginLeft: 8,
                }}
              />
              <Typography variant="h5">{`${user.first_name} ${user.last_name}`}</Typography>
            </Grid>
            <Grid style={{ marginTop: 8 }} item xs={12}>
              <List>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <Icon>business_center</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={user.company ? user.company.label : '--'}
                    secondary="Company"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <Icon>mail</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={user.email_address ? user.email_address : '--'}
                    secondary="Email"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <Icon>location_on</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      user.default_location ? user.default_location.label : '--'
                    }
                    secondary="Default Expansive Location"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <Icon>home</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={user.office_number ? user.office_number : '--'}
                    secondary="Office Number"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <Icon>domain</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={user.floor_number ? user.floor_number : '--'}
                    secondary="Floor Number"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <Icon>account_circle</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={user.job_title ? user.job_title : '--'}
                    secondary="Job Title"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={user.website ? user.website : '--'}
                    secondary="Website"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={user.twitter ? user.twitter : '--'}
                    secondary="Twitter"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={user.linkedin ? user.linkedin : '--'}
                    secondary="LinkedIn"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={user.yardi_id ? user.yardi_id : '--'}
                    secondary="Yardi ID"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={user.yardi_company_id ? user.yardi_company_id : '--'}
                    secondary="Yardi Company ID"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={user.bio ? user.bio : '--'}
                    secondary="Bio"
                  />
                </ListItem>
                <Divider />
                <ListItem style={{ marginTop: 16 }}>
                  <ListItemIcon>
                    <Icon>security</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6">Additional Details</Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      user.metadata && user.metadata.keycard
                        ? user.metadata.keycard
                        : '--'
                    }
                    secondary="Keycard"
                  />
                </ListItem>

                {20200115 >
                  Number(moment().subtract(30, 'days').format('YYYYMMDD')) &&
                user.metadata &&
                user.metadata.monthly_credits ? (
                  <ListItem>
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography color="error">
                          You can no longer add monthly credits to a user. Add
                          it to their company instead.
                        </Typography>
                      }
                      secondary={`${
                        user.metadata.monthly_credits || 0
                      } Monthly Credits will be applied to this individual until you update his company's credits.`}
                    />
                  </ListItem>
                ) : null}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <BookingData
                user={user.id}
                label={user.first_name}
                type={user.type}
              />
            </Grid>
          </Grid>
          <Dialog
            maxWidth="sm"
            fullScreen={fullScreen}
            fullWidth
            className="add-user-modal"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={this.state.editUser}
          >
            <EditClient
              handleClose={this.toggleEditUser}
              user={user}
              currentLocation={
                (this.props.selectedUser.default_location &&
                  this.props.selectedUser.default_location.value) ||
                null
              }
              profileId={this.props.selectedUser.id}
              label="Edit Client"
            />
          </Dialog>
        </div>
      </React.Fragment>
    )
  }
}

export default compose(
  firestoreConnect(),
  connect((state, props) => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
    }
  }),
  withRouter,
  withSnackbar,
  withMobileDialog()
)(UserDetail)
