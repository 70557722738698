import React, { Component } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { Paper, InputBase, Icon, CircularProgress } from '@material-ui/core'
import _ from 'lodash'

class CustomSearchBar extends Component {
  refine = _.debounce(val => {
    this.props.refine(val)
  }, 360)
  render() {
    return (
      <Paper elevation={0} className="search-bar">
        <Icon fontSize="small" style={{ marginRight: 4 }}>
          search
        </Icon>
        <InputBase
          fullWidth
          type="search"
          placeholder="Filter by Name, Company, or Email"
          defaultValue={this.props.currentRefinement}
          onChange={event => this.refine(event.currentTarget.value)}
          style={{ marginRight: 8 }}
        />
        {this.props.isSearchStalled && (
          <div>
            <CircularProgress size={20} />
          </div>
        )}
      </Paper>
    )
  }
}

export default connectSearchBox(CustomSearchBar)
