import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import PostOverview from './components/PostOverview'
import PostDetail from './components/PostDetail'

class Community extends Component {
  render() {
    return (
      <Switch>
        <Route path="/community/:id" component={PostDetail} />
        <Route exact path="/community" component={PostOverview} />
      </Switch>
    )
  }
}

export default Community
