import React from 'react'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import DirectionsIcon from '@material-ui/icons/Directions'
import Paper from '@material-ui/core/Paper'
import { CircularProgress, Icon, Typography } from '@material-ui/core'

import UserPicker from '../../../components/UserPicker'

class ClientSelect extends React.Component {
  state = {
    open: false,
    disabled: true,
    searchQuery: '',
    results: [],
    noResults: false,
    searching: false
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    return (
      <div className="client-select" style={{ flex: 1, minWidth: 80 }}>
        <Button size="small" color="primary" onClick={this.handleClickOpen}>
          Select a Client
        </Button>
        <Dialog fullWidth maxWidth="xs" open={this.state.open} onClose={this.handleClose}>
          <MuiDialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: 10,
              paddingTop: 10,
              paddingBottom: 6
            }}
          >
            <Typography variant="h6">Select a Client</Typography>
            <IconButton aria-label="Close" onClick={this.handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </MuiDialogTitle>
          <DialogContent id="scrollable-list" style={{ position: 'relative', paddingTop: 8 }}>
            <UserPicker
              dontExcludeByLocation={this.props.dontExcludeByLocation}
              label={''}
              autoFocus
              location={this.props.activeLocation}
              onSelect={item => {
                this.props.onSelect(item)
                if (!this.props.multiSelect) {
                  this.handleClose()
                }
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

export default compose(firestoreConnect())(ClientSelect)
