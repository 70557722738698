import React, { Component } from 'react'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import axios from 'axios'
import { withRouter } from 'react-router-dom'

import LoadingSplash from '../../../components/LoadingSplash'

import {
  Dialog,
  DialogActions,
  DialogContent,
  ListItem,
  ListItemText,
  Button,
  IconButton,
  Typography,
  Icon,
  Grid,
  TextField,
  Avatar,
  Paper,
  InputAdornment,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

import { FunctionsDir } from './../../../config/firebase'
import ImageTools from './../../../helpers/resizeImage'
import UserPicker from '../../../components/UserPicker'

class EditCompany extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      imageBlob: props.imageBlob,
      imagePreviewUrl: props.company.image,
      company: props.company,
      metadata: props.company.metadata,
      editing: true,
      isUploading: false,
      userPicker: false,
    }
  }
  componentDidMount() {
    console.log(this.props)
  }
  updateUser = (e) => {
    e.preventDefault()
    this.setState({
      isUploading: true,
    })
    if (this.state.imageBlob) {
      const storage = this.props.firebase
        .storage()
        .ref()
        .child(
          `profile_images/avatars/${this.props.profileId}.${
            this.state.imageBlob.type.split('/')[
              this.state.imageBlob.type.split('/').length - 1
            ]
          }`
        )
      const upload = storage.put(this.state.imageBlob)
      upload.on(
        this.props.firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {},
        (err) => {},
        () => {
          upload.snapshot.ref
            .getDownloadURL()
            .then((url) => {
              var company = Object.assign({}, this.state.company)
              company.image = url
              let body = {
                user: company,
                profile_id: this.props.profileId,
                user_id: this.props.auth.uid,
              }
              this.pushToFirebase(body)
            })
            .catch((err) => {
              this.props.enqueueSnackbar(
                `There was an error creating the company, ${err.message}`,
                {
                  variant: 'error',
                }
              )
              console.log(err)
            })
        }
      )
    } else {
      let body = {
        user: this.state.company,
        profile_id: this.props.profileId,
        user_id: this.props.auth.uid,
      }
      this.pushToFirebase(body)
    }
  }
  pushToFirebase = (body) => {
    console.log(body)
    this.props.firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        console.log(token)
        return axios.post(
          `${FunctionsDir}/novel/updateClient`,
          {
            body,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'content-type': 'application/octet-stream',
            },
          }
        )
      })
      .then((res) => {
        console.log(res)
        this.props.enqueueSnackbar(`Company updated.`, {
          variant: 'success',
        })
        this.props.handleClose()
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          isUploading: false,
        })
      })
  }

  handleImageChange = (e) => {
    e.preventDefault()

    ImageTools.resize(
      e.target.files[0],
      {
        width: 300,
        height: 300,
      },
      (blob, success) => {
        console.log(blob, success)
        let reader = new FileReader()
        reader.onloadend = () => {
          this.setState({
            imageBlob: blob,
            imagePreviewUrl: reader.result,
          })
        }
        reader.readAsDataURL(blob)
      }
    )
  }
  updateValue = (e) => {
    var company = Object.assign({}, this.state.company)
    company[e.target.name] = e.target.value
    this.setState({
      company,
    })
  }
  updatePrimaryContact = (e) => {
    var company = Object.assign({}, this.state.company)
    company.primary_contact = {
      email_address: e.email_address,
      id: e.objectID,
    }
    this.setState(
      {
        company,
      },
      () => {
        this.toggleUserPicker()
      }
    )
  }
  deleteCompany = () => {
    const confirm = window.confirm(
      'Are you sure you want to delete this company? There is no restoring it once deleted.'
    )

    if (confirm) {
      this.props.firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => {
          console.log(token)
          return axios.post(
            `${FunctionsDir}/novel/deleteCompany`,
            {
              body: {
                profile_id: this.props.profileId,
                user_id: this.props.auth.uid,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'application/octet-stream',
              },
            }
          )
        })
        .then((res) => {
          console.log(res)
          this.props.enqueueSnackbar(`This company has been removed.`, {
            variant: 'info',
          })
          this.props.handleClose()
          this.props.history.push('/clients')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
  toggleUserPicker = () => {
    this.setState({
      userPicker: !this.state.userPicker,
    })
  }
  updateMetadata = (e) => {
    var company = { ...this.state.company }
    let metadata = { ...this.state.metadata }
    if (metadata === undefined) {
      metadata = {}
    }
    metadata[e.target.name] = e.target.value
    company.metadata = metadata
    this.setState({
      company,
      metadata,
    })
  }
  render() {
    return (
      <React.Fragment>
        <MuiDialogTitle disableTypography className="booking-modal-title">
          <Typography variant="h5">{this.props.label}</Typography>
          <IconButton
            onClick={this.props.handleClose}
            className="close-button"
            aria-label="Close"
          >
            <Icon>close</Icon>
          </IconButton>
        </MuiDialogTitle>
        <DialogContent style={{ paddingTop: 5 }}>
          <form onSubmit={this.updateUser} id="update-user-form">
            <Grid container spacing={24}>
              <Grid
                item
                xs={12}
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <input
                  onChange={(e) => this.handleImageChange(e)}
                  accept="image/*"
                  style={{
                    position: 'absolute',
                    zIndex: 0,
                    opaity: 0.1,
                    left: '50%',
                    right: '50%',
                    bottom: '50%',
                    top: '50%',
                    width: 10,
                    height: 10,
                  }}
                  id="image"
                  name="image"
                  type="file"
                />
                <label htmlFor="image" style={{ cursor: 'pointer' }}>
                  <Avatar
                    style={{
                      width: 120,
                      height: 120,
                      backgroundColor: '#f2f2f2',
                      marginRight: 'auto',
                      marginLeft: 'auto',
                      marginBottom: 16,
                    }}
                    alt=""
                    src={this.state.imagePreviewUrl}
                  />
                  <Typography align="center" variant="button">
                    Upload Avatar
                  </Typography>
                </label>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  value={this.state.company.company_name}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Company Name"
                  name="company_name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  variant="caption"
                  color="textSecondary"
                >
                  Primary Contact
                </Typography>
                <div style={{ display: 'flex' }}>
                  {this.state.company.primary_contact !== null ? (
                    <div style={{ paddingRight: 16 }}>
                      <ListItem>
                        <ListItemText
                          primary={
                            this.state.company.primary_contact.email_address
                          }
                        />
                      </ListItem>
                    </div>
                  ) : null}
                  <Button onClick={this.toggleUserPicker}>Select a User</Button>
                </div>
                <Dialog
                  onBackdropClick={this.toggleUserPicker}
                  scroll="paper"
                  maxWidth="xs"
                  fullWidth
                  open={this.state.userPicker}
                >
                  <DialogContent>
                    <UserPicker
                      location={this.state.company.default_location.value}
                      onSelect={(user) => this.updatePrimaryContact(user)}
                    />
                  </DialogContent>
                </Dialog>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.company.office_number}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Office Number"
                  name="office_number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.company.floor_number}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Floor Number"
                  name="floor_number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.company.website}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Website"
                  placeholder="https://website.com"
                  name="website"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} style={{ position: 'relative' }}>
                <TextField
                  value={this.state.company.twitter}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Twitter Handle"
                  placeholder="MyHandle"
                  name="twitter"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="h6" color="textSecondary">
                          @
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.company.linkedin}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="LinkedIn Public Profile"
                  placeholder="mypublicurl"
                  name="linkedin"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="h6" color="textSecondary">
                          /in/
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={this.state.company.bio}
                  onChange={(e) => {
                    if (e.target.value.length <= 300) {
                      this.updateValue(e)
                    }
                  }}
                  variant="outlined"
                  label="Bio"
                  name="bio"
                  fullWidth
                  rows={3}
                  rowsMax={6}
                />
                <Typography
                  style={{ paddingTop: 8 }}
                  variant="caption"
                  color="textSecondary"
                >
                  {this.state.company.bio.length}/300
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Additional Details</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={
                    this.state.metadata && this.state.metadata.monthly_credits
                      ? this.state.metadata.monthly_credits
                      : ''
                  }
                  type="number"
                  onChange={this.updateMetadata}
                  variant="outlined"
                  label="Booking Monthly Credits"
                  name="monthly_credits"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={
                    this.state.metadata &&
                    this.state.metadata.access_pass_credits
                      ? this.state.metadata.access_pass_credits
                      : 0
                  }
                  type="number"
                  onChange={this.updateMetadata}
                  variant="outlined"
                  label="Access Pass Monthly Credits"
                  name="access_pass_credits"
                  fullWidth
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <Paper elevation={3}>
          <DialogActions>
            <div style={{ flex: 1 }}>
              <Button
                disabled={this.state.isUploading}
                onClick={this.deleteCompany}
                type="submit"
                size="large"
                color="secondary"
              >
                Delete
              </Button>
            </div>
            <Button
              disabled={this.state.isUploading}
              type="submit"
              size="large"
              form="update-user-form"
              color="primary"
              variant="contained"
            >
              Update Company
            </Button>
          </DialogActions>
        </Paper>
        {this.state.isUploading && (
          <LoadingSplash label="Updating Company..." />
        )}
      </React.Fragment>
    )
  }
}

export default compose(
  withRouter,
  withSnackbar,
  firestoreConnect(),
  connect((state) => {
    return {
      auth: state.firebase.auth,
    }
  })
)(EditCompany)
