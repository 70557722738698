import React, { useEffect, useState } from 'react'
import {
  CircularProgress,
  Input,
  Paper,
  Button,
  TextField,
  Typography,
} from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { FunctionsDir } from './../../../config/firebase'
import axios from 'axios'
import firebase from 'firebase'

//import './DirectoryMessages.css'

const DirectoryMessages = (props) => {
  const { enqueueSnackbar } = props

  function timestamper() {
    let date = new Date()

    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let dateOfMonth = date.getDate()
    let hour = date.getHours()
    let min = date.getMinutes()

    if (month < 10) month = '0' + month
    if (dateOfMonth < 10) dateOfMonth = '0' + dateOfMonth
    if (hour < 10) hour = '0' + hour
    if (min < 10) min = '0' + min

    const dateNow = `${year}-${month}-${dateOfMonth}`
    const timeNow = `${hour}:${min}`

    return [dateNow, timeNow]
  }

  const now = timestamper()

  /* default from / to to now */
  const [fromDate, setFromDate] = useState(now[0])
  const [fromTime, setFromTime] = useState(now[1])
  const [toDate, setToDate] = useState(now[0])
  const [toTime, setToTime] = useState(now[1])

  const [message, setMessage] = useState(null)
  const [image, setImage] = useState(null)
  const [location, setLocation] = useState(props.location)

  const [fromYear, setFromYear] = useState(fromDate.substr(0, 4))
  const [toYear, setToYear] = useState(toDate.substr(0, 4))
  const [fromMonth, setFromMonth] = useState(fromDate.substr(5, 2))
  const [toMonth, setToMonth] = useState(toDate.substr(5, 2))
  const [fromDay, setFromDay] = useState(fromDate.substr(8, 2))
  const [toDay, setToDay] = useState(toDate.substr(8, 2))
  const [fromHour, setFromHour] = useState(fromTime.substr(0, 2))
  const [toHour, setToHour] = useState(toTime.substr(0, 2))
  const [fromMin, setFromMin] = useState(fromTime.substr(3, 2))
  const [toMin, setToMin] = useState(toTime.substr(3, 2))
  const [fromSec, setFromSec] = useState(0)
  const [toSec, setToSec] = useState(0)

  const [currentAndUpcoming, setCurrentAndUpcoming] = useState(null)
  const [currentAndUpcomingLoaded, setCurrentAndUpcomingLoaded] = useState(null)

  const getMessages = () => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        // console.log(token)
        return axios.get(
          `${FunctionsDir}/novel/readMessages?location=${location}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'content-type': 'application/octet-stream',
            },
          }
        )
      })
      .then((res) => {
        setCurrentAndUpcoming(res.data._materializedDocs)
        setCurrentAndUpcomingLoaded(true)
        console.log(res)
      })
      .catch((error) => {
        setCurrentAndUpcomingLoaded(false)
        console.error(error)
      })
  }

  // get current and upcoming messages
  useEffect(() => {
    getMessages()
  }, [])

  function convertToTimestamp(year, month, day, hour, minute, second) {
    let date = new Date(year, month - 1, day, hour, minute, second)
    date = +date / 1000
    // alert('convertToTimestamp')
    // alert(date)

    return date
  }

  let fromTimestamp = convertToTimestamp(
    fromYear,
    fromMonth,
    fromDay,
    fromHour,
    fromMin,
    fromSec
  )

  let toTimestamp = convertToTimestamp(
    toYear,
    toMonth,
    toDay,
    toHour,
    toMin,
    toSec
  )

  const handleChange = async (e) => {
    switch (e.target.name) {
      case 'fromDate':
        setFromDate(e.target.value)
        break
      case 'fromTime':
        setFromTime(e.target.value)
        break
      case 'toDate':
        setToDate(e.target.value)
        break
      case 'toTime':
        setToTime(e.target.value)
        break
      case 'message':
        setMessage(encodeURIComponent(e.target.value))
        break
      case 'image':
        setImage(e.target.files[0])
        break
    }

    setFromYear(fromDate.substr(0, 4))
    setFromMonth(fromDate.substr(5, 2))
    setFromDay(fromDate.substr(8, 2))
    setFromHour(fromTime.substr(0, 2))
    setFromMin(fromTime.substr(3, 2))
    setToYear(toDate.substr(0, 4))
    setToMonth(toDate.substr(5, 2))
    setToDay(toDate.substr(8, 2))
    setToHour(toTime.substr(0, 2))
    setToMin(toTime.substr(3, 2))

    if (
      document.getElementById('from-time') !== '' &&
      document.getElementById('from-date') !== ''
    ) {
      fromTimestamp = convertToTimestamp(
        fromYear,
        fromMonth,
        fromDay,
        fromHour,
        fromMin,
        fromSec
      )
      const from = new Date(fromTimestamp)
    }

    if (
      document.getElementById('to-time') !== '' &&
      document.getElementById('to-date') !== ''
    ) {
      toTimestamp = convertToTimestamp(
        toYear,
        toMonth,
        toDay,
        toHour,
        toMin,
        toSec
      )
    }
  }

  const handleSubmit = () => {
    if (document.getElementById('from-time').value === '') {
      enqueueSnackbar('Message must include From Time!', {
        variant: 'error',
      })
      return
    }
    if (document.getElementById('to-time').value === '') {
      enqueueSnackbar('Message must include To Time!', {
        variant: 'error',
      })
      return
    }
    if (
      document.getElementById('message').value === '' &&
      document.getElementById('image').value === ''
    ) {
      enqueueSnackbar('Message must include message, image, or both!', {
        variant: 'error',
      })
      return
    }

    let reader = new FileReader()
    let today = new Date() / 1000
    // alert(+today)
    // alert(fromTimestamp)
    // alert(toTimestamp)
    if (fromTimestamp >= toTimestamp) {
      enqueueSnackbar('From Date/Time must be after To Date/Time', {
        variant: 'error',
      })
      return
    } else if (toTimestamp < today) {
      enqueueSnackbar('To Date/Time must be after current Date/Time', {
        variant: 'error',
      })
      return
    }

    if (image !== null) {
      // console.log('image !== null')
      reader.readAsDataURL(image)
      reader.onloadend = () => {
        let blob = reader.result
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            console.log(token)

            return axios.post(
              `${FunctionsDir}/novel/createMessage`,
              {
                fromTimestamp: fromTimestamp,
                toTimestamp: toTimestamp,
                message: message,
                imageName: image.name,
                image: blob,
                location: location,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'content-type': 'application/octet-stream',
                },
              }
            )
          })

          .then((res) => {
            setMessage(null)
            setImage(null)
            document.getElementById('createMessage').reset()
            getMessages()
            console.log(res)
            enqueueSnackbar(
              'Message created. Directory update may take up to two minutes.',
              {
                variant: 'success',
              }
            )
          })
          .catch((error) => {
            console.log(error)
          })
      }
    } else {
      // console.log('image === null')
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => {
          // console.log(token)
          return axios.post(
            `${FunctionsDir}/novel/createMessage`,
            {
              fromTimestamp: fromTimestamp,
              toTimestamp: toTimestamp,
              message: message,
              location: location,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'content-type': 'application/octet-stream',
              },
            }
          )
        })
        .then((res) => {
          setMessage(null)
          setImage(null)
          document.getElementById('createMessage').reset()
          getMessages()
          console.log(res)
          enqueueSnackbar(
            'Message created. Directory update may take up to two minutes.',
            {
              variant: 'success',
            }
          )
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const delMessage = (e) => {
    const messageID = e.target.id

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        console.log(token)
        return axios.delete(`${FunctionsDir}/novel/deleteMessage`, {
          data: {
            messageID: messageID,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/octet-stream',
          },
        })
      })
      .then((res) => {
        getMessages()
        console.log(res)
      })
      .catch((e) => {
        console.log(`Error delMessage: ${e.message}`)
      })
  }

  return (
    <div className="directory-messages">
      <Paper style={{ padding: 16 }}>
        <form id="createMessage">
          <Typography variant="h6">Schedule Message</Typography>
          <br />
          <TextField
            id="from-date"
            name="fromDate"
            label="From Date"
            type="date"
            defaultValue={fromDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          &nbsp;&nbsp;
          <TextField
            id="from-time"
            name="fromTime"
            label="From Time"
            type="time"
            // defaultValue={fromTime}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
          <br />
          <br />
          <TextField
            id="to-date"
            name="toDate"
            label="To Date"
            type="date"
            defaultValue={toDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          &nbsp;&nbsp;
          <TextField
            id="to-time"
            name="toTime"
            label="To Time"
            type="time"
            // defaultValue={toTime}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
          <br />
          <br />
          <TextField
            id="message"
            name="message"
            label="Message (Optional)"
            defaultValue={message}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '60%' }}
          />
          <br />
          <br />
          <label>
            <span style={{ fontSize: '12px', color: '#797979' }}>
              Image (Optional)
            </span>
            <br />
            <Input
              id="image"
              name="image"
              defaultValue={image}
              onChange={handleChange}
              type="file"
            />
          </label>
          <br />
          <br />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Add New Message
          </Button>
          <br />
          <br />
          <Typography variant="h6" style={{ marginTop: 24 }}>
            Upcoming Messages
          </Typography>
          {currentAndUpcomingLoaded === null && <CircularProgress />}
          {currentAndUpcomingLoaded === false && <>No Upcoming Messages</>}
          {currentAndUpcomingLoaded === true && (
            <table>
              <tbody>
                <tr>
                  <th></th>
                  {/* <th>Location ID</th> */}
                  <th>Message</th>
                  <th>Image</th>
                  <th>Display From</th>
                  <th>Display To</th>
                </tr>
                {currentAndUpcoming.map((message) => {
                  const fromDate = new Date(
                    message._fieldsProto.displayFrom.integerValue * 1000
                  )
                  const toDate = new Date(
                    message._fieldsProto.displayTo.integerValue * 1000
                  )

                  return (
                    <tr key={message._ref._path.segments[1]}>
                      <td className="delMsg">
                        <span
                          id={message._ref._path.segments[1]}
                          onClick={delMessage}
                          style={{ fontSize: '10px', color: '#797979' }}
                        >
                          delete
                        </span>
                      </td>
                      {/* <td>{message._fieldsProto.location.stringValue}</td> */}
                      <td>
                        {message._fieldsProto.message.stringValue &&
                          decodeURIComponent(
                            message._fieldsProto.message.stringValue
                          )}
                      </td>
                      <td>
                        {message._fieldsProto.image && (
                          <img
                            src={message._fieldsProto.image.stringValue}
                            width="80px"
                            height="80px"
                          />
                        )}
                      </td>
                      <td>{fromDate.toString()}</td>
                      <td>{toDate.toString()}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
        </form>
      </Paper>
    </div>
  )
}

export default withSnackbar(DirectoryMessages)
