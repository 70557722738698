import React from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment-timezone'
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  ListSubheader,
} from '@material-ui/core'

const renderData = (option, props) => {
  let data = props.data.filter((b) => b.date === option)

  if (!props.isDaily) {
    data = data.sort((a, b) => a.start_time.seconds - b.start_time.seconds)
  }

  return data.map((booking) => {
    if (props.isDaily) {
      return (
        <ListItem
          onClick={props.open}
          className="booking-list"
          key={booking.id}
          to={`${props.isDaily ? '/bookings-private/' : '/bookings-hour/'}${
            booking.location.id
          }/${booking.id}`}
          component={NavLink}
          button
          style={{ paddingRight: 90 }}
        >
          <ListItemText
            primary={booking.room.display_name}
            secondary={booking.client.display_name}
          />
          <ListItemSecondaryAction
            style={{ paddingRight: 8, textAlign: 'right' }}
          >
            {booking.canceled ? (
              <Typography
                style={{ pointerEvents: 'none' }}
                variant="caption"
                color="textSecondary"
              >
                CANCELED
              </Typography>
            ) : null}
          </ListItemSecondaryAction>
        </ListItem>
      )
    }
    let startTime = moment.unix(booking.start_time.seconds)
    let endTime = moment.unix(booking.end_time.seconds)
    if (props.timezone) {
      startTime = startTime.tz(props.timezone)
      endTime = endTime.tz(props.timezone)
    }
    return (
      <ListItem
        onClick={props.open}
        className="booking-list"
        key={booking.id}
        to={`${props.isDaily ? '/bookings-private/' : '/bookings-hour/'}${
          booking.location.id
        }/${booking.id}`}
        component={NavLink}
        button
        style={{ paddingRight: 90 }}
      >
        <ListItemText
          primary={booking.room.display_name}
          secondary={booking.client.display_name}
        />
        <ListItemSecondaryAction
          style={{ paddingRight: 8, textAlign: 'right' }}
        >
          {booking.canceled ? (
            <Typography
              style={{ pointerEvents: 'none' }}
              variant="caption"
              color="textSecondary"
            >
              CANCELED
            </Typography>
          ) : (
            <Typography
              style={{ pointerEvents: 'none' }}
              variant="caption"
              color="textSecondary"
            >
              {`${startTime.format('h:mma z')}`}
              <br />
              {`${endTime.format('h:mma z')}`}
            </Typography>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    )
  })
}

export default (props) => {
  if (props.data.length === 0)
    return (
      <Typography
        variant="caption"
        color="textSecondary"
        style={{ padding: 16 }}
      >
        Found no scheduled bookings.
      </Typography>
    )
  let listOfOptions = [...new Set(props.data.map((booking) => booking.date))]
  listOfOptions.sort((a, b) => a - b)
  return (
    <div style={{ position: 'relative' }}>
      <List style={{ position: 'relative' }} subheader={<li />}>
        {listOfOptions.map((option) => (
          <li key={option}>
            <ul style={{ padding: 0, listStyleType: 'none' }}>
              <ListSubheader
                style={{ top: 62, backgroundColor: '#fafafa', marginRight: 10 }}
              >
                {moment(option, 'YYYYMMDD').format('ddd, MMM Do')}
              </ListSubheader>
              {renderData(option, props)}
            </ul>
          </li>
        ))}
      </List>
    </div>
  )
}
