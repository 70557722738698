import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isLoaded, isEmpty, firestoreConnect } from 'react-redux-firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withRouter } from 'react-router-dom'

class Authentication extends Component {
  state = { loginCheck: false, isAdmin: false }
  componentDidUpdate() {
    const path = this.props.location.pathname
    if (this.state.publicResources) {
      return
    }
    if (path.includes('/resources-public')) {
      this.setState({ publicResources: true, loginCheck: false})
    } else {
      if (isLoaded(this.props.auth) && isEmpty(this.props.auth)) {
        if (!path.includes('/login')) {
          this.props.history.replace('/login')
        }
      } else if (
        isLoaded(this.props.auth) &&
        !isEmpty(this.props.auth) &&
        isLoaded(this.props.profile) &&
        !isEmpty(this.props.profile)
      ) {
        if (this.props.profile.role === 'client') {
          this.props.firebase.logout()
        } else {
          if (path.includes('/login') || path.includes('/forget-password')) {
            this.props.history.replace('/overview')
          }
        }
      }
      if (!this.state.loginCheck && isLoaded(this.props.auth) && isLoaded(this.props.profile)) {
        this.setState({ loginCheck: true })
      }
    }
  }
  render() {
    if (!this.state.loginCheck && !this.state.publicResources) {
      return (
        <div className="splash-loader">
          <CircularProgress />
        </div>
      )
    }
    return this.props.children
  }
}

export default compose(
  withRouter,
  firestoreConnect(),
  connect(state => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile
    }
  })
)(Authentication)
