import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'
import moment from 'moment-timezone'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import {
  Grid,
  Typography,
  Paper,
  Divider,
  Icon,
  IconButton,
  Tabs,
  Tab,
  CircularProgress,
} from '@material-ui/core'

import BookingList from '../../../components/BookingList'
import Notifications from '../components/Notifications'
import Alerts from '../components/Alerts'
import DirectoryMessages from './DirectoryMessages'

class HomeDetail extends Component {
  state = {
    date: moment(),
    focused: false,
    data: [],
    loadMore: false,
    bookingType: 0,
    loading: true,
  }
  componentDidMount() {
    this.mounted = true
    this.getData()
  }
  componentWillUnmount() {
    this.mounted = false
  }
  updateDate = (date) => {
    this.setState({ date, loading: true }, () => {
      this.getData()
    })
  }
  updateData = (bookingType) => {
    this.setState({ bookingType, loading: true }, () => {
      this.getData()
    })
  }
  getData = _.debounce(() => {
    this.setState({ loadMore: true, loading: true })
    let query = this.props.firestore
      .collection(this.state.bookingType ? 'dayBookings' : 'bookings')
      .where('location.id', '==', this.props.match.params.id)
      .where('canceled', '==', false)
      .where('date', '==', Number(this.state.date.format('YYYYMMDD')))
    query
      .get()
      .then((docs) => {
        let data = []
        docs.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id })
        })
        console.log(data)
        if (this.mounted) {
          this.setState({
            data,
            loadMore: false,
            loading: false,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, 1000)
  render() {
    const location = this.props.locations[this.props.match.params.id]
    return (
      <div className="overview">
        <Grid container spacing={32} direction="row-reverse">
          <Grid item xs={12} sm={12} md={6}>
            <Typography gutterBottom variant="h5">
              Notifications
            </Typography>
            <Notifications activeLocation={this.props.match.params.id} />
            <div style={{ margin: '32px 0' }} />
            <Typography gutterBottom variant="h5">
              Community Alerts
            </Typography>
            <Alerts location={this.props.match.params.id} />
            <div style={{ margin: '32px 0' }} />
            <Typography gutterBottom variant="h5">
              Directory Messages
            </Typography>
            <DirectoryMessages location={this.props.match.params.id} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography gutterBottom variant="h5">
              Booked Rooms
            </Typography>
            <Paper style={{ position: 'relative', padding: 8 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <IconButton
                  disabled={this.state.loadMore}
                  onClick={() =>
                    this.updateDate(moment(this.state.date).subtract(1, 'days'))
                  }
                >
                  <Icon fontSize="small">navigate_before</Icon>
                </IconButton>
                <SingleDatePicker
                  date={this.state.date} // momentPropTypes.momentObj or null
                  onDateChange={this.updateDate} // PropTypes.func.isRequired
                  focused={this.state.focused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                  id="singe-day-booking" // PropTypes.string.isRequired,
                  isOutsideRange={() => false}
                  hideKeyboardShortcutsPanel
                  noBorder
                  orientation="vertical"
                  verticalHeight={600}
                />
                <IconButton
                  disabled={this.state.loadMore}
                  onClick={() =>
                    this.updateDate(moment(this.state.date).add(1, 'days'))
                  }
                >
                  <Icon fontSize="small">navigate_next</Icon>
                </IconButton>
              </div>
              <Paper square elevation={1}>
                <Tabs
                  value={this.state.bookingType}
                  onChange={(e, v) => this.updateData(v)}
                  indicatorColor="primary"
                >
                  <Tab
                    label={'Conference Rooms'}
                    value={0}
                    style={{ padding: 0 }}
                    size="small"
                  />
                  <Tab
                    label={'Private Offices'}
                    value={1}
                    style={{ padding: 0 }}
                    size="small"
                  />
                </Tabs>
              </Paper>
              <Divider />
              {location !== undefined ? (
                this.state.loading ? (
                  <div style={{ padding: 10, textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <BookingList
                    timezone={location.timezone.value}
                    locationID={this.props.match.params.id}
                    data={this.state.data}
                    startDate={this.state.date}
                    endDate={this.state.date}
                    open={this.openDetail}
                    isDaily={this.state.bookingType}
                  />
                )
              ) : null}
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(
  firestoreConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    locations: state.firestore.data.locations,
    // rooms: state.firestore.ordered.rooms
  }))
)(HomeDetail)
