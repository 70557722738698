import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import PublicResourcesOverview from './components/PublicResourcesOverview'
import PublicResourcesDetail from './components/PublicResourcesDetail'

class PublicResources extends Component {
  render() {
    const theme = createMuiTheme({
      palette: {
        primary: {
          light: '#f8f3f3',
          main: '#72575f',
        },
        secondary: {
          contrastText: '#ffffff',
          main: '#005a7d',
        },
      },
      typography: {
        fontFamily: 'Lato',
        fontSize: 14,
        useNextVariants: true,
      }
    })

    return (
      <MuiThemeProvider theme={theme}>
        <Switch>
          <Route
            path="/resources-public/:locationId"
            component={PublicResourcesOverview}
            exact
            />
          <Route
            path="/resources-public/:locationId/:resourceId"
            component={PublicResourcesDetail}
            exact
            />
        </Switch>
      </MuiThemeProvider>
    )
  }
}

export default PublicResources
