import React, { Component } from 'react'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import axios from 'axios'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Avatar from '@material-ui/core/Avatar'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Paper from '@material-ui/core/Paper'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'

import LocationPicker from './../../../components/LocationPicker'
import LoadingSplash from './../../../components/LoadingSplash'
import ImageTools from './../../../helpers/resizeImage'

import { FunctionsDir } from './../../../config/firebase'

class USER_MODEL {
  constructor() {
    this.type = 'user'
    this.email_address = ''
    this.first_name = ''
    this.last_name = ''
    this.default_location = null
    this.company = {
      label: 'Expansive Workspace',
      value: 'expansive-workspace'
    }
    this.office_number = ''
    this.floor_number = ''
    this.role = 'community_manager'
    this.locations = null
    this.image = ''
    this.bio = ''
    this.disabled = false
    this.job_title = ''
    this.linkedin = ''
    this.twitter = ''
    this.website = ''
    this.first_time_user = false
    this.show_in_directory = true
    this.verified = true
    this.metadata = {}
    this.yardi_id = null;
    this.yardi_company_id = null;
  }
}

class AddUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageBlob: props.imageBlob,
      imagePreviewUrl: props.user.image,
      first_name: props.first_name,
      last_name: props.last_name,
      email_address: props.email_address,
      role: props.role,
      locations: props.locations,
      editing: props.editing,
      isUploading: false,
      user: { ...props.user },
      metadata: this.props.user.metadata,
      showTooltip: false,
      tooltip: {
        title: 'Community Manager',
        rules: 'Has access to view and make changes to assigned locations'
      },
      anchorEl: null
    }
  }
  createUser = e => {
    e.preventDefault()
    this.setState({
      isUploading: true
    })
    if (this.state.user.default_location === null) {
      this.props.enqueueSnackbar(`Assign this CM to a default location`, {
        variant: 'error'
      })
      return this.setState({
        isUploading: false
      })
    }
    let body = {
      user_id: this.props.auth.uid,
      userData: { ...this.state.user }
    }
    if (this.state.editing) {
      console.log(this.state.user)
      body.uid = this.props.id
      if (this.state.imageBlob) {
        this.updateImage(this.state.user.id, body)
      } else {
        this.updateUser(body)
      }
    } else {
      this.createNewUser(body)
    }
  }
  updateImage = (uid, body) => {
    const storage = this.props.firebase
      .storage()
      .ref()
      .child(
        `profile_images/avatars/${uid}.${
          this.state.imageBlob.type.split('/')[this.state.imageBlob.type.split('/').length - 1]
        }`
      )
    const upload = storage.put(this.state.imageBlob)
    upload.on(
      this.props.firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {},
      err => {},
      () => {
        upload.snapshot.ref
          .getDownloadURL()
          .then(url => {
            let b = body
            b.userData.image = url
            this.updateUser(b)
          })
          .catch(err => {
            this.props.enqueueSnackbar(`There was an error creating the user, ${err.message}`, {
              variant: 'error'
            })
            console.log(err)
          })
      }
    )
  }
  updateUser = body => {
    this.props.firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(token => {
        return axios.post(
          `${FunctionsDir}/novel/updateUser`,
          {
            body
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'content-type': 'application/octet-stream'
            }
          }
        )
      })
      .then(res => {
        console.log(res)
        this.props.enqueueSnackbar(`User updated.`, {
          variant: 'success'
        })
        this.props.handleClose()
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isUploading: false
        })
      })
  }

  createNewUser = body => {
    this.props.firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(token => {
        console.log(token)
        return axios.post(
          `${FunctionsDir}/novel/createUser`,
          {
            body
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'content-type': 'application/octet-stream'
            }
          }
        )
      })
      .then(res => {
        console.log(res.data)
        if (this.state.imageBlob) {
          let b = body
          b.uid = res.data
          this.updateImage(res.data, b)
        } else {
          this.props.enqueueSnackbar(`User created.`, {
            variant: 'success'
          })
          this.props.handleClose()
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isUploading: false
        })
      })
  }
  handleDialogClose = e => {
    if (this.state.snackbarKey) {
      this.props.closeSnackbar(this.state.snackbarKey);
    }
    this.props.handleClose();
  }
  handleImageChange = e => {
    e.preventDefault()

    ImageTools.resize(
      e.target.files[0],
      {
        width: 300,
        height: 300
      },
      (blob, success) => {
        console.log(blob, success)
        let reader = new FileReader()
        reader.onloadend = () => {
          this.setState({
            imageBlob: blob,
            imagePreviewUrl: reader.result
          })
        }
        reader.readAsDataURL(blob)
      }
    )
  }
  updateValueEmail = (e) => {
    if (e.target.name === 'email_address') {
      if (this.state.user.email_address !== e.target.value) {
        const snackbarKey = this.props.enqueueSnackbar(
          'PLEASE NOTE: Changing this email address will also change the email that the client uses to log in.',
          {
            variant: 'info',
            preventDuplicate: true
          }
        );
        if (!this.state.snackbarKey) {
          this.setState({snackbarKey: snackbarKey});
        }
      }
    }
    this.updateValue(e);
  }
  updateValue = e => {
    let user = Object.assign({}, this.state.user)
    user[e.target.name] = e.target.value
    // if (e.target.name === 'role') {
    //   user.locations = null
    // }
    this.setState({
      user
    })
  }
  updateLocation = e => {
    let user = Object.assign({}, this.state.user)
    let locations = {}
    e.forEach(loc => {
      locations[loc.value] = true
    })
    user.locations = locations
    if (user.default_location === null) {
      user.default_location = e[0]
    }
    console.log(user.locations)
    this.setState({
      user
    })
  }
  updateDefaultLocation = e => {
    let user = Object.assign({}, this.state.user)
    user.default_location = e
    this.setState({
      user
    })
  }
  updateMetadata = e => {
    let user = Object.assign({}, this.state.user)
    let metadata = Object.assign({}, this.state.metadata)

    if (metadata === undefined) {
      metadata = {}
    }
    metadata[e.target.name] = e.target.value
    user.metadata = metadata
    this.setState({
      user,
      metadata
    })
  }
  render() {
    const { fullScreen } = this.props
    return (
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={this.props.open}
        className="add-user-modal"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MuiDialogTitle disableTypography className="booking-modal-title">
          <Typography variant="h5">{this.props.label}</Typography>
          <IconButton onClick={this.handleDialogClose} className="close-button" aria-label="Close">
            <Icon>close</Icon>
          </IconButton>
        </MuiDialogTitle>
        <DialogContent style={{ paddingTop: 5 }}>
          <form onSubmit={this.createUser} style={{ filter: `blur(${this.state.isUploading ? 10 : 0}px)` }}>
            <Grid container spacing={24}>
              <Grid
                item
                xs={12}
                style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignContent: 'center' }}
              >
                <input
                  required={this.props.editing ? false : true}
                  onChange={e => this.handleImageChange(e)}
                  accept="image/*"
                  style={{
                    position: 'absolute',
                    zIndex: 0,
                    opaity: 0.1,
                    left: '50%',
                    right: '50%',
                    bottom: '50%',
                    top: '50%',
                    width: 10,
                    height: 10
                  }}
                  id="image"
                  name="image"
                  type="file"
                />
                <label htmlFor="image" style={{ cursor: 'pointer' }}>
                  <Avatar
                    style={{
                      width: 120,
                      height: 120,
                      backgroundColor: '#f2f2f2',
                      marginRight: 'auto',
                      marginLeft: 'auto',
                      marginBottom: 16
                    }}
                    alt=""
                    src={this.state.imagePreviewUrl}
                  />
                  <Typography align="center" variant="button">
                    Upload Avatar
                  </Typography>
                </label>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={this.state.user.first_name}
                  onChange={this.updateValue}
                  required
                  variant="outlined"
                  label="First Name"
                  name="first_name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={this.state.user.last_name}
                  onChange={this.updateValue}
                  required
                  variant="outlined"
                  label="Last Name"
                  name="last_name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextField
                  value={this.state.user.email_address}
                  onChange={this.updateValueEmail}
                  required
                  type="email"
                  variant="outlined"
                  label="Email Address"
                  name="email_address"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Select
                  aria-describedby="tooltip"
                  onLoad={e => this.setState({ anchorEl: e.currentTarget })}
                  onMouseEnter={() => {
                    this.setState({ showTooltip: true })
                  }}
                  onMouseLeave={() => this.setState({ showTooltip: false })}
                  value={this.state.user.role}
                  onChange={this.updateValue}
                  required
                  name="role"
                  fullWidth
                  input={<OutlinedInput labelWidth={0} label="Role" name="role" />}
                >
                  <MenuItem
                    onMouseEnter={() =>
                      this.setState({
                        tooltip: {
                          title: 'Admin',
                          rules:
                            'Has access to view and make changes to any client, Expansive user, or location (This includes making changes to other admins).'
                        }
                      })
                    }
                    value={'admin'}
                  >
                    Admin
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={() =>
                      this.setState({
                        tooltip: {
                          title: 'Community Manager',
                          rules:
                            'Has access to view and make changes to assigned locations and clients from that location.'
                        }
                      })
                    }
                    value={'community_manager'}
                  >
                    Community Manager
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={() =>
                      this.setState({
                        tooltip: {
                          title: 'Center Admin',
                          rules:
                            'Has admin-level access to specific locations granted to them.'
                        }
                      })
                    }
                    value={'regional_manager'}
                  >
                    Center Admin
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={() =>
                      this.setState({
                        tooltip: {
                          title: 'Area Sales Manager',
                          rules:
                            'Has access to view and make changes to assigned locations and clients from that location.'
                        }
                      })
                    }
                    value={'center_manager'}
                  >
                    Area Sales Manager
                  </MenuItem>
                  <MenuItem disabled style={{ padding: 0, height: 'auto' }}>
                    <Paper
                      elevation={6}
                      style={{ padding: 16, backgroundColor: '#fff', maxWidth: 320, borderTop: '1px solid #ccc' }}
                    >
                      <Typography variant="body2" gutterBottom style={{ fontWeight: '500', whiteSpace: 'normal' }}>
                        {this.state.tooltip.title}
                      </Typography>
                      <Typography variant="body2" style={{ whiteSpace: 'normal' }}>
                        {this.state.tooltip.rules}
                      </Typography>
                    </Paper>
                  </MenuItem>
                </Select>
              </Grid>
              {this.state.user.role !== 'admin' ? (
                <Grid item xs={12}>
                  <Tooltip title="locations this user has access to view and update">
                    <div>
                      <LocationPicker
                        label="Assigned Locations"
                        assignedLocations={this.state.user.locations}
                        updateValue={this.updateLocation}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Tooltip title="mobile app will default to this location when opened">
                  <div>
                    <LocationPicker
                      label="Default Location"
                      assignedLocations={
                        this.state.user.default_location ? { [this.state.user.default_location.value]: true } : null
                      }
                      updateValue={this.updateDefaultLocation}
                      isMulti={false}
                    />
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.office_number}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Office Number"
                  name="office_number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.floor_number}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Floor Number"
                  name="floor_number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.job_title}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Job Title"
                  name="job_title"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.website}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Website"
                  placeholder="https://website.com"
                  name="website"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} style={{ position: 'relative' }}>
                <TextField
                  value={this.state.user.twitter}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Twitter Handle"
                  placeholder="MyHandle"
                  name="twitter"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="h6" color="textSecondary">
                          @
                        </Typography>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.linkedin}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="LinkedIn Public Profile"
                  placeholder="mypublicurl"
                  name="linkedin"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="h6" color="textSecondary">
                          /in/
                        </Typography>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={this.state.user.yardi_id}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="User's Yardi ID"
                  name="yardi_id"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={this.state.user.yardi_company_id}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="User's Company's Yardi ID"
                  name="yardi_company_id"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={this.state.user.bio}
                  onChange={e => {
                    if (e.target.value.length <= 300) {
                      this.updateValue(e)
                    }
                  }}
                  variant="outlined"
                  label="Bio"
                  name="bio"
                  fullWidth
                  rows={3}
                  rowsMax={6}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Additional Details</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={this.state.metadata ? this.state.metadata.keycard : ''}
                  onChange={this.updateMetadata}
                  variant="outlined"
                  label="Keycard"
                  name="keycard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button disabled={this.state.isUploading} type="submit" color="primary" variant="contained">
                  {this.props.editing ? 'Update User' : 'Create User'}
                </Button>
              </Grid>
            </Grid>
          </form>
          {this.state.isUploading && (
            <LoadingSplash label={this.props.label === 'Add User' ? 'Creating User...' : 'Updating User...'} />
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

AddUser.defaultProps = {
  imageBlob: null,
  imagePreviewUrl: null,
  user: new USER_MODEL(),
  editing: false,
  label: 'Add User'
}

export default compose(
  withMobileDialog(),
  withSnackbar,
  firestoreConnect(),
  connect(state => {
    return {
      auth: state.firebase.auth
    }
  })
)(AddUser)
