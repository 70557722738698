import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import axios from 'axios'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Avatar from '@material-ui/core/Avatar'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Paper from '@material-ui/core/Paper'
import InputAdornment from '@material-ui/core/InputAdornment'

import { FunctionsDir } from '../../../config/firebase'
import ImageTools from '../../../helpers/resizeImage'

import LoadingSplash from '../../../components/LoadingSplash'

class EditClient extends PureComponent {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      imageBlob: props.imageBlob,
      imagePreviewUrl: props.user.image,
      user: props.user,
      metadata: props.user.metadata,
      editing: true,
      isUploading: false
    }
  }
  updateUser = (e) => {
    e.preventDefault()
    this.setState({
      isUploading: true,
    })
    if (this.state.imageBlob) {
      const storage = this.props.firebase
        .storage()
        .ref()
        .child(
          `profile_images/avatars/${
            this.state.user.id ? this.state.user.id : Date.now()
          }.${
            this.state.imageBlob.type.split('/')[
              this.state.imageBlob.type.split('/').length - 1
            ]
          }`
        )
      const upload = storage.put(this.state.imageBlob)
      upload.on(
        this.props.firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {},
        (err) => {},
        () => {
          upload.snapshot.ref
            .getDownloadURL()
            .then((url) => {
              var user = Object.assign({}, this.state.user)
              user.image = url
              let body = {
                user,
                profile_id: this.props.profileId,
                user_id: this.props.auth.uid,
              }
              this.pushToFirebase(body)
            })
            .catch((err) => {
              this.props.enqueueSnackbar(
                `There was an error creating the user, ${err.message}`,
                {
                  variant: 'error',
                }
              )
              console.log(err)
            })
        }
      )
    } else {
      let body = {
        user: this.state.user,
        profile_id: this.props.profileId,
        user_id: this.props.auth.uid,
      }
      this.pushToFirebase(body)
    }
  }
  pushToFirebase = (body) => {
    this.props.firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        console.log(token)
        return axios.post(
          `${FunctionsDir}/novel/updateClient`,
          {
            body,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'content-type': 'application/octet-stream',
            },
          }
        )
      })
      .then((res) => {
        console.log(res)
        this.props.enqueueSnackbar(`User updated.`, {
          variant: 'success',
        })
        this.props.handleClose()
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          isUploading: false,
        })
      })
  }
  handleDialogClose = e => {
    if (this.state.snackbarKey) {
      this.props.closeSnackbar(this.state.snackbarKey);
    }
    this.props.handleClose();
  }
  handleImageChange = (e) => {
    e.preventDefault()

    ImageTools.resize(
      e.target.files[0],
      {
        width: 300,
        height: 300,
      },
      (blob, success) => {
        console.log(blob, success)
        let reader = new FileReader()
        reader.onloadend = () => {
          this.setState({
            imageBlob: blob,
            imagePreviewUrl: reader.result,
          })
        }
        reader.readAsDataURL(blob)
      }
    )
  }
  updateValueEmail = (e) => {
    if (e.target.name === 'email_address') {
      if (this.state.user.email_address !== e.target.value) {
        const snackbarKey = this.props.enqueueSnackbar(
          'PLEASE NOTE: Changing this email address will also change the email that the client uses to log in.',
          {
            variant: 'info',
            preventDuplicate: true
          }
        );
        if (!this.state.snackbarKey) {
          this.setState({snackbarKey: snackbarKey});
        }
      }
    }
    this.updateValue(e);
  }
  updateValue = (e) => {
    var user = Object.assign({}, this.state.user)
    user[e.target.name] = e.target.value
    this.setState({
      user,
    })
  }
  updateMetadata = (e) => {
    var user = { ...this.state.user }
    let metadata = { ...this.state.metadata }
    if (metadata === undefined) {
      metadata = {}
    }
    metadata[e.target.name] = e.target.value
    user.metadata = metadata
    this.setState({
      user,
      metadata,
    })
  }
  updateCompany = (e) => {
    var user = Object.assign({}, this.state.user)
    const selectedCompany = this.props.companies.findIndex(
      (company) => company.id === e.target.value
    )
    console.log(selectedCompany)
    if (selectedCompany >= 0) {
      user.company = {
        label: this.props.companies[selectedCompany].company_name,
        value: e.target.value,
      }
    } else {
      user.company = null
    }
    this.setState(
      {
        user,
      },
      () => {
        console.log(this.state.user)
      }
    )
  }
  updateLocation = (e) => {
    console.log(e.target.value)
    var user = Object.assign({}, this.state.user)
    const selectedLocation = this.props.locations.findIndex(
      (loc) => loc.id === e.target.value
    )
    console.log(selectedLocation)
    user.default_location = {
      label: `${this.props.locations[selectedLocation].name} - ${this.props.locations[selectedLocation].city}, ${this.props.locations[selectedLocation].state}`,
      value: e.target.value,
    }
    this.setState(
      {
        user,
      },
      () => {
        console.log(this.state.user)
      }
    )
  }
  render() {
    return (
      <React.Fragment>
        <MuiDialogTitle disableTypography className="booking-modal-title">
          <Typography variant="h5">{this.props.label}</Typography>
          <IconButton
            onClick={this.handleDialogClose}
            className="close-button"
            aria-label="Close"
          >
            <Icon>close</Icon>
          </IconButton>
        </MuiDialogTitle>
        <DialogContent style={{ paddingTop: 5 }}>
          <form onSubmit={this.updateUser} id="update-user-form">
            <Grid container spacing={24}>
              <Grid
                item
                xs={12}
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <input
                  onChange={(e) => this.handleImageChange(e)}
                  accept="image/*"
                  style={{
                    position: 'absolute',
                    zIndex: 0,
                    opaity: 0.1,
                    left: '50%',
                    right: '50%',
                    bottom: '50%',
                    top: '50%',
                    width: 10,
                    height: 10,
                  }}
                  id="image"
                  name="image"
                  type="file"
                />
                <label htmlFor="image" style={{ cursor: 'pointer' }}>
                  <Avatar
                    style={{
                      width: 120,
                      height: 120,
                      backgroundColor: '#f2f2f2',
                      marginRight: 'auto',
                      marginLeft: 'auto',
                      marginBottom: 16,
                    }}
                    alt=""
                    src={this.state.imagePreviewUrl}
                  />
                  <Typography align="center" variant="button">
                    Upload Avatar
                  </Typography>
                </label>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={this.state.user.first_name}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="First Name"
                  name="first_name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={this.state.user.last_name}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Last Name"
                  name="last_name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.email_address}
                  onChange={this.updateValueEmail}
                  required
                  type="email"
                  variant="outlined"
                  label="Email Address"
                  name="email_address"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required
                  name="default_location"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  style={{ margin: 0 }}
                >
                  <InputLabel>Location</InputLabel>
                  <Select
                    required
                    native
                    value={
                      this.state.user.default_location &&
                      this.state.user.default_location.value
                        ? this.state.user.default_location.value
                        : ''
                    }
                    onChange={this.updateLocation}
                    name="default_location"
                    input={
                      <OutlinedInput name="default_location" labelWidth={70} />
                    }
                  >
                    <option value="" disabled />
                    {this.props.locations
                      ? this.props.locations.map((location, i) => {
                          return (
                            <option key={i} value={location.id}>
                              {location.name} - {location.city},{' '}
                              {location.state}
                            </option>
                          )
                        })
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  name="company"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  style={{ margin: 0 }}
                >
                  <InputLabel>Company</InputLabel>
                  <Select
                    fullWidth
                    native
                    onChange={this.updateCompany}
                    value={
                      this.state.user.company
                        ? this.state.user.company.value
                        : ''
                    }
                    name="company"
                    input={<OutlinedInput name="company" labelWidth={70} />}
                  >
                    <option value={''}>No Company</option>
                    {this.props.companies
                      ? this.props.companies.map((company) => {
                          return (
                            <option key={company.id} value={company.id}>
                              {company.company_name}
                            </option>
                          )
                        })
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.office_number}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Office Number"
                  name="office_number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.floor_number}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Floor Number"
                  name="floor_number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.job_title}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Job Title"
                  name="job_title"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.website}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Website"
                  placeholder="https://website.com"
                  name="website"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} style={{ position: 'relative' }}>
                <TextField
                  value={this.state.user.twitter}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Twitter Handle"
                  placeholder="MyHandle"
                  name="twitter"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="h6" color="textSecondary">
                          @
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.linkedin}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="LinkedIn Public Profile"
                  placeholder="mypublicurl"
                  name="linkedin"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="h6" color="textSecondary">
                          /in/
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.yardi_id}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Yardi ID"
                  name="yardi_id"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={this.state.user.yardi_company_id}
                  onChange={this.updateValue}
                  variant="outlined"
                  label="Yardi Company ID"
                  name="yardi_company_id"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={this.state.user.bio}
                  onChange={(e) => {
                    if (e.target.value.length <= 300) {
                      this.updateValue(e)
                    }
                  }}
                  variant="outlined"
                  label="Bio"
                  name="bio"
                  fullWidth
                  rows={3}
                  rowsMax={6}
                />
                <Typography
                  style={{ paddingTop: 8 }}
                  variant="caption"
                  color="textSecondary"
                >
                  {this.state.user.bio ? this.state.user.bio.length : 0}/300
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Additional Details</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  value={
                    this.state.metadata && this.state.metadata.keycard
                      ? this.state.metadata.keycard
                      : ''
                  }
                  onChange={this.updateMetadata}
                  variant="outlined"
                  label="Keycard"
                  name="keycard"
                  fullWidth
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <Paper elevation={3}>
          <DialogActions>
            <Button
              disabled={this.state.isUploading}
              type="submit"
              size="large"
              form="update-user-form"
              color="primary"
              variant="contained"
            >
              Update Client
            </Button>
          </DialogActions>
        </Paper>
        {this.state.isUploading && <LoadingSplash label="Updating Client..." />}
      </React.Fragment>
    )
  }
}

export default compose(
  withSnackbar,
  firestoreConnect((props) =>
    props.currentLocation
      ? [
          {
            collection: 'locations',
            type: 'once',
            orderBy: 'state',
          },
          {
            collection: 'profiles',
            where: [
              ['type', '==', 'company'],
              ['default_location.value', '==', props.currentLocation],
            ],
            type: 'once',
            orderBy: 'company_name',
            storeAs: 'companies',
          },
        ]
      : []
  ),
  connect((state) => {
    return {
      locations: state.firestore.ordered.locations,
      companies: state.firestore.ordered.companies,
      auth: state.firebase.auth,
    }
  })
)(EditClient)
