import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'
import ducks from './ducks'
import { Firebase } from './config/firebase'

const firestoreConfig = {
  userProfile: 'profiles',
  useFirestoreForProfile: true
}
const initialState = {}
const enhancers = []
const middleware = []

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}
const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)
const firebaseRootReducer = combineReducers({
  ducks,
  firebase: firebaseReducer,
  firestore: firestoreReducer
})

const store = createStore(firebaseRootReducer, initialState, composedEnhancers)

export const rrfProps = {
  firebase: Firebase,
  config: firestoreConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

export default store
